import { useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TaskIcon from "@mui/icons-material/Task";
import BalanceIcon from "@mui/icons-material/Balance";
import DescriptionIcon from "@mui/icons-material/Description";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useSnackbar } from "notistack";
import customTheme from "../../constants/Theme";
import { getErrorMessage } from "../../utils/errorHelper";
import {
    normaliseString,
    getMonthName,
    formatDate,
    currencyFormatter,
    getEmptyFolderCard,
    overrideTypographyStyle,
} from "../../utils/generalHelper";
import CreditProfileController from "../../controllers/CreditProfileController";
import BasePage from "../BasePage";
import PageBackgroundContainer from "../../components/templates/PageBackgroundContainer";
import AccountsContainer from "./components/AccountsContainer";
import PaymentsContainer from "./components/PaymentsContainer";
import OtherListingsContainer from "./components/OtherListingsContainer";
import BackgroundRedImg from "../../assets/images/background_red.png";
import BackgroundTealImg from "../../assets/images/background_teal.png";

const displayTabs = [
    {
        name: "accounts",
        component: (data) => <AccountsContainer accountGroups={data} />,
        backgroundImg: BackgroundRedImg,
    },
    {
        name: "payment_histories",
        component: (data) => <PaymentsContainer accountGroups={data} />,
        backgroundImg: BackgroundTealImg,
    },
    {
        name: "other_listings",
        component: (data) => <OtherListingsContainer listingGroups={data} />,
        backgroundImg: null,
    },
];

const AccountsAndPaymentsPage = () => {
    const { enqueueSnackbar } = useSnackbar();

    const defaultGroupedAccounts = [
        {
            groupName: "unsecured",
            groupStatusPositive: true,
            groupInstallment: 0,
            accounts: [],
            emptyComp: getEmptyFolderCard("You have no Unsecured accounts"),
        },
        {
            groupName: "vehicles",
            groupStatusPositive: true,
            groupInstallment: 0,
            accounts: [],
            emptyComp: getEmptyFolderCard("You have no Vehicle accounts"),
        },
        {
            groupName: "home-loans",
            groupStatusPositive: true,
            groupInstallment: 0,
            accounts: [],
            emptyComp: getEmptyFolderCard("You have no Home Loan accounts"),
        },
        {
            groupName: "service-providers",
            groupStatusPositive: true,
            groupInstallment: 0,
            accounts: [],
            emptyComp: getEmptyFolderCard("You have no Service Provider accounts"),
        },
    ];

    const defaultGroupedListings = [
        {
            groupName: "debt_reviews",
            groupIcon: <TaskIcon color="brandedTeal" />,
            listings: [],
            emptyComp: getEmptyFolderCard("You are not under debt review"),
        },
        {
            groupName: "debt_collections",
            groupIcon: <MoneyOffIcon color="brandedTeal" />,
            listings: [],
            emptyComp: getEmptyFolderCard("You have no debt collections"),
        },
        {
            groupName: "judgements",
            groupIcon: <BalanceIcon color="brandedTeal" />,
            listings: [],
            emptyComp: getEmptyFolderCard("You have no judgements against your name"),
        },
        {
            groupName: "administrations",
            groupIcon: <DescriptionIcon color="brandedTeal" />,
            listings: [],
            emptyComp: getEmptyFolderCard("You are not under administration"),
        },
        {
            groupName: "adverse_listings",
            groupIcon: <NoteAddIcon color="brandedTeal" />,
            listings: [],
            emptyComp: getEmptyFolderCard("There are no adverse items against your name"),
        },
        {
            groupName: "inquiries",
            groupIcon: <ManageSearchIcon color="brandedTeal" />,
            listings: [],
            emptyComp: getEmptyFolderCard("You have no inquiries"),
        },
    ];

    const [isLoading, setIsLoading] = useState(true);
    const [groupedAccounts, setGroupedAccounts] = useState(defaultGroupedAccounts);
    const [groupedListings, setGroupedListings] = useState(defaultGroupedListings);
    const [selectedTabContent, setSelectedTabContent] = useState(null);
    const [selectedDisplayOption, setSelectedDisplayOption] = useState();

    useEffect(() => {
        let mounted = true;

        Promise.allSettled([getAccounts(), getListings()])
            .then((responses) => {
                if (!mounted) return;

                groupAccounts(responses[0].value);
                groupListings(responses[1].value);
            })
            .finally(() => {
                if (!mounted) return;

                setIsLoading(false);
                setSelectedDisplayOption(displayTabs[0]);
            });

        return () => {
            mounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        switch (selectedDisplayOption?.name) {
            case "accounts":
            case "payment_histories":
                setSelectedTabContent(selectedDisplayOption.component(groupedAccounts));
                break;
            case "other_listings":
                setSelectedTabContent(selectedDisplayOption.component(groupedListings));
                break;
            default:
                setSelectedTabContent(null);
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDisplayOption]);

    const getListings = () => {
        return new Promise(async (res, rej) => {
            try {
                const response = await CreditProfileController.getOtherListings();
                const keys = Object.keys(response || {});

                if (!response || keys.length <= 0) {
                    rej();
                    return;
                }

                let result = [];

                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];

                    result.push({
                        listingGroupName: key,
                        listingGroupItems: response[key],
                    });
                }

                res(result);
            } catch (error) {
                const errMsg = getErrorMessage(error);

                // Check if request was aborted
                if (errMsg === null) return;

                enqueueSnackbar(errMsg, { variant: "error" });
                rej(errMsg);
            }
        });
    };

    const groupListings = (allListingGroups) => {
        if (!allListingGroups || allListingGroups.length <= 0) {
            setGroupedListings(defaultGroupedListings);
            return;
        }

        // Deep copy default so not to overwrite/concatenate its listings field
        let tempGroupedListings = [...defaultGroupedListings];

        // Iterate through each listing and add them to the appropriate group
        for (let i = 0; i < allListingGroups.length; i++) {
            const listingGroup = allListingGroups[i];
            let group = tempGroupedListings.find(
                (gl) => gl.groupName === listingGroup.listingGroupName
            );

            if (!group) {
                console.log("group not found for " + listingGroup.groupName);
                continue;
            }

            switch (listingGroup?.listingGroupName) {
                case "debt_reviews":
                    if (listingGroup.listingGroupItems?.length > 0) {
                        for (let i = 0; i < listingGroup.listingGroupItems.length; i++) {
                            const item = listingGroup.listingGroupItems[i];

                            group.listings.push({
                                headerComp: (
                                    <Box>
                                        <Typography
                                            component="h6"
                                            gutterBottom
                                            sx={{ ...overrideTypographyStyle("body1") }}
                                        >
                                            Counsellor
                                        </Typography>
                                        <Typography
                                            component="p"
                                            sx={{ ...overrideTypographyStyle("body1Bold") }}
                                        >
                                            {normaliseString(
                                                `${item.counsellor_first_name} ${item.counsellor_last_name}`
                                            ).trim()}
                                        </Typography>
                                    </Box>
                                ),
                                contentFields: [
                                    {
                                        registration_nr: {
                                            fullWidth: false,
                                            value: item.counsellor_registration_number,
                                        },
                                        contact_nr: {
                                            fullWidth: false,
                                            value: item.counsellor_contact_number,
                                        },
                                        status_date: {
                                            fullWidth: false,
                                            value: formatDate(item.status_date) || "No date set",
                                        },
                                        status_code: {
                                            fullWidth: false,
                                            value: item.debt_review_status_code,
                                        },
                                        status: {
                                            fullWidth: true,
                                            value: normaliseString(item.debt_review_status).trim(),
                                        },
                                    },
                                ],
                            });
                        }
                    }
                    break;
                case "judgements":
                    if (listingGroup.listingGroupItems?.length > 0) {
                        for (let i = 0; i < listingGroup.listingGroupItems.length; i++) {
                            const item = listingGroup.listingGroupItems[i];

                            group.listings.push({
                                headerComp: (
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography
                                                component="h6"
                                                sx={{ ...overrideTypographyStyle("body1") }}
                                            >
                                                Case Number
                                            </Typography>
                                            <Typography
                                                component="p"
                                                sx={{ ...overrideTypographyStyle("body1Bold") }}
                                            >
                                                {item.case_number}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                component="h6"
                                                sx={{ ...overrideTypographyStyle("body1") }}
                                            >
                                                Case Type
                                            </Typography>
                                            <Typography
                                                component="p"
                                                sx={{ ...overrideTypographyStyle("body1Bold") }}
                                            >
                                                {normaliseString(item.case_type).trim()}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ),
                                contentFields: [
                                    {
                                        plaintiff: {
                                            fullWidth: true,
                                            value: normaliseString(item.plaintiff).trim(),
                                        },
                                        amount: {
                                            fullWidth: false,
                                            value: currencyFormatter.format(item.amount || 0),
                                        },
                                        granted_date: {
                                            fullWidth: false,
                                            value: formatDate(item.granted) || "No date set",
                                        },
                                    },
                                ],
                            });
                        }
                    }
                    break;
                case "administrations":
                    if (listingGroup.listingGroupItems?.length > 0) {
                        for (let i = 0; i < listingGroup.listingGroupItems.length; i++) {
                            const item = listingGroup.listingGroupItems[i];

                            group.listings.push({
                                headerComp: (
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography
                                                component="h6"
                                                sx={{ ...overrideTypographyStyle("body1") }}
                                            >
                                                Case Number
                                            </Typography>
                                            <Typography
                                                component="p"
                                                sx={{ ...overrideTypographyStyle("body1Bold") }}
                                            >
                                                {item.case_number}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                component="h6"
                                                sx={{ ...overrideTypographyStyle("body1") }}
                                            >
                                                Filing Date
                                            </Typography>
                                            <Typography
                                                component="p"
                                                sx={{ ...overrideTypographyStyle("body1Bold") }}
                                            >
                                                {formatDate(item.case_filing_date) || "No date set"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ),
                                contentFields: [
                                    {
                                        court_name: {
                                            fullWidth: false,
                                            value: normaliseString(item.court_name).trim(),
                                        },
                                        plaintiff: {
                                            fullWidth: true,
                                            value: normaliseString(item.plaintiff_name).trim(),
                                        },
                                        attorney_name: {
                                            fullWidth: true,
                                            value: normaliseString(item.attorney_name).trim(),
                                        },
                                        case_reason: {
                                            fullWidth: true,
                                            value: normaliseString(item.case_reason).trim(),
                                        },
                                        amount: {
                                            fullWidth: false,
                                            value: currencyFormatter.format(item.amount || 0),
                                        },
                                    },
                                ],
                            });
                        }
                    }
                    break;
                case "debt_collections":
                    if (listingGroup.listingGroupItems?.length > 0) {
                        for (let i = 0; i < listingGroup.listingGroupItems.length; i++) {
                            const item = listingGroup.listingGroupItems[i];

                            group.listings.push({
                                headerComp: (
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography
                                                component="h6"
                                                sx={{ ...overrideTypographyStyle("body1") }}
                                            >
                                                Account Number
                                            </Typography>
                                            <Typography
                                                component="p"
                                                sx={{ ...overrideTypographyStyle("body1Bold") }}
                                            >
                                                {item.account_no}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                component="h6"
                                                sx={{ ...overrideTypographyStyle("body1") }}
                                            >
                                                Handover Date
                                            </Typography>
                                            <Typography
                                                component="p"
                                                sx={{ ...overrideTypographyStyle("body1Bold") }}
                                            >
                                                {formatDate(item.handover_date) || "No date set"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ),
                                contentFields: [
                                    {
                                        name: {
                                            fullWidth: true,
                                            value: normaliseString(item.name).trim(),
                                        },
                                        current_balance: {
                                            fullWidth: false,
                                            value: currencyFormatter.format(
                                                item.current_balance || 0
                                            ),
                                        },
                                        installment_amount: {
                                            fullWidth: false,
                                            value: currencyFormatter.format(
                                                item.aminstallment_amountount || 0
                                            ),
                                        },
                                    },
                                ],
                            });
                        }
                    }
                    break;
                case "adverse_listings":
                    if (listingGroup.listingGroupItems?.length > 0) {
                        for (let i = 0; i < listingGroup.listingGroupItems.length; i++) {
                            const item = listingGroup.listingGroupItems[i];

                            group.listings.push({
                                headerComp: (
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography
                                                component="h6"
                                                sx={{ ...overrideTypographyStyle("body1") }}
                                            >
                                                Account Number
                                            </Typography>
                                            <Typography
                                                component="p"
                                                sx={{ ...overrideTypographyStyle("body1Bold") }}
                                            >
                                                {item.account_no}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                component="h6"
                                                sx={{ ...overrideTypographyStyle("body1") }}
                                            >
                                                Record Date
                                            </Typography>
                                            <Typography
                                                component="p"
                                                sx={{ ...overrideTypographyStyle("body1Bold") }}
                                            >
                                                {formatDate(item.date_account_opened) ||
                                                    "No date set"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ),
                                contentFields: [
                                    {
                                        name: {
                                            fullWidth: true,
                                            value: normaliseString(item.name).trim(),
                                        },
                                        current_balance: {
                                            fullWidth: false,
                                            value: currencyFormatter.format(
                                                item.current_balance || 0
                                            ),
                                        },
                                        installment_amount: {
                                            fullWidth: false,
                                            value: currencyFormatter.format(
                                                item.aminstallment_amountount || 0
                                            ),
                                        },
                                    },
                                ],
                            });
                        }
                    }
                    break;
                case "inquiries":
                    if (listingGroup.listingGroupItems?.length > 0) {
                        group.listings = [...listingGroup.listingGroupItems];
                    }
                    break;
                default:
                    break;
            }
        }

        setGroupedListings(tempGroupedListings);
    };

    const getAccounts = () => {
        return new Promise(async (res, rej) => {
            try {
                const responseAccounts = await CreditProfileController.getAccounts();

                if (!responseAccounts || responseAccounts.length <= 0) {
                    rej();
                    return;
                }

                res(responseAccounts);
            } catch (error) {
                const errMsg = getErrorMessage(error);

                // Check if request was aborted
                if (errMsg === null) return;

                enqueueSnackbar(errMsg, { variant: "error" });
                rej(errMsg);
            }
        });
    };

    const groupAccounts = (accounts) => {
        if (!accounts || accounts.length <= 0) {
            setGroupedAccounts(defaultGroupedAccounts);
            return;
        }

        let tempGroupedAccounts = [...defaultGroupedAccounts];

        // Iterate through each account and add its details to the appropriate group
        for (let i = 0; i < accounts.length; i++) {
            const account = accounts[i];

            account.isUpToDate =
                !account.payment_history ||
                account.payment_history.length <= 0 ||
                account.payment_history[account.payment_history.length - 1].months_in_arrears <= 0;
            account.statusHeaderComp = getStatusHeaderComp(account);
            account.statusComp = getStatusComp(account);
            account.creditPerc = getCreditPerc(account);
            account.paymentHistoryFull = getPaymentHistoryComp(account);
            account.paymentHistoryShort = getPaymentHistoryComp(account, false);

            let groupedAcc = tempGroupedAccounts.find((ga) => ga.groupName === account.grouping);

            if (!groupedAcc) {
                groupedAcc = {
                    groupName: account.grouping,
                    groupStatusPositive: account.isUpToDate ? true : false,
                    groupInstallment: account.installment_amount,
                    accounts: [account],
                };

                tempGroupedAccounts.push(groupedAcc);
            } else {
                groupedAcc.groupStatusPositive =
                    groupedAcc.groupStatusPositive && account.isUpToDate;
                groupedAcc.groupInstallment += account.installment_amount;
                groupedAcc.accounts.push(account);
            }
        }

        setGroupedAccounts(tempGroupedAccounts);
    };

    const getStatusHeaderComp = (account) => {
        return (
            <Box
                sx={{
                    padding: { xs: "3px 7px", sm: "5px 10px" },
                    borderRadius: "0.5rem",
                    backgroundColor: account.isUpToDate
                        ? customTheme.palette.textGood.background
                        : customTheme.palette.textBad.background,
                    color: account.isUpToDate
                        ? customTheme.palette.textGood.main
                        : customTheme.palette.textBad.main,
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        whiteSpace: "nowrap",
                        fontSize: { xs: "0.6rem !important", sm: "0.875rem !important" },
                        fontWeight: "400 !important",
                    }}
                >
                    {account.isUpToDate ? "Up to Date" : "Missed Payment"}
                </Typography>
            </Box>
        );
    };

    const getStatusComp = (account) => {
        if (!account.payment_history || account.payment_history.length <= 0) {
            return (
                <Typography component="p" variant="body1BoldResp">
                    No status available
                </Typography>
            );
        }

        const lastPayment = account.payment_history[account.payment_history.length - 1];

        return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: { xs: "20px", sm: "26px" },
                        height: { xs: "20px", sm: "26px" },
                        borderRadius: { xs: "10px", sm: "13px" },
                        marginRight: "0.5rem",
                        fontSize: customTheme.typography.pageBodySmall.fontSize,
                        fontWeight: 700,
                        color:
                            lastPayment.months_in_arrears > 0
                                ? customTheme.palette.text.primary
                                : customTheme.palette.background.default,
                        backgroundColor:
                            lastPayment.months_in_arrears > 0
                                ? customTheme.palette.primary.main
                                : customTheme.palette.chartColorVeryGood.main,
                    }}
                >
                    {Math.max(0, lastPayment.months_in_arrears)}
                </Box>
                <Typography component="p" variant="body1BoldResp">
                    Months in arrears
                </Typography>
            </Box>
        );
    };

    const getCreditPerc = (account) => {
        // If the current balance is not a positive number, then return 0
        if (!(account.current_balance > 0)) return 0;

        // If the credit limit is not a positive number, then return 100
        if (!(account.opening_balance_credit_limit > 0)) return 100;

        // Both the balance & limit are positive numbers, calculate usage percentage
        return Math.min(account.current_balance / account.opening_balance_credit_limit, 1) * 100;
    };

    const getPaymentHistoryComp = (account, fullHistory = true) => {
        if (!account.payment_history || account.payment_history.length <= 0) {
            return (
                <Grid item xs={12} container justifyContent="center">
                    <Typography component="p" variant="pageBodyBold">
                        No history available
                    </Typography>
                </Grid>
            );
        }

        let result = [];
        let start = fullHistory ? 0 : Math.max(0, account.payment_history.length - 6);

        for (let i = start; i < account.payment_history.length; i++) {
            const payment = account.payment_history[i];

            payment.monthName = getMonthName(payment.month, false);
            payment.icon =
                payment.months_in_arrears < 0 ? (
                    <DoNotDisturbOnIcon sx={{ color: `${customTheme.palette.secondary.main}80` }} />
                ) : payment.months_in_arrears > 0 ? (
                    <CancelIcon sx={{ color: customTheme.palette.primary.main }} />
                ) : (
                    <CheckCircleIcon sx={{ color: customTheme.palette.chartColorVeryGood.main }} />
                );

            result.push(payment);
        }

        return result.map((payment, i) => (
            <Grid
                key={i}
                item
                xs={fullHistory ? 1 : 2}
                md={fullHistory ? 2 : 4}
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Typography component="p" variant="pageBodyBoldResp">
                    {payment.monthName}
                </Typography>
                {payment.icon}
            </Grid>
        ));
    };

    const handledisplayTabSelection = (e, selectedOption) => {
        setSelectedDisplayOption(selectedOption);
    };

    const hasBackgroundImg = () => {
        return !!selectedDisplayOption?.backgroundImg;
    };

    return (
        <BasePage>
            <PageBackgroundContainer
                backgroundImg={selectedDisplayOption?.backgroundImg}
                partial={hasBackgroundImg()}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: { xs: "5rem", sm: 0 },
                    }}
                >
                    {/* Page title */}
                    <Box width="100%" maxWidth={hasBackgroundImg() ? "800px" : "1100px"}>
                        <Typography component="h1" variant="pageTitle2" marginBottom={5}>
                            My Accounts
                        </Typography>
                    </Box>

                    {isLoading && (
                        <Box
                            sx={{
                                height: "80vh",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Typography component="h1" variant="pageTitle1" marginBottom={5}>
                                Loading Your Information
                            </Typography>
                            <CircularProgress size="5rem" />
                        </Box>
                    )}

                    {!isLoading && (
                        <Box
                            sx={{
                                width: "100%",
                                maxWidth: hasBackgroundImg() ? "800px" : "1100px",
                            }}
                        >
                            {/* Toggle buttons */}
                            <ToggleButtonGroup
                                value={selectedDisplayOption}
                                onChange={handledisplayTabSelection}
                                exclusive
                                sx={{ margin: "1rem 0" }}
                            >
                                {displayTabs.map((option, i) => (
                                    <ToggleButton key={i} value={option}>
                                        {normaliseString(option.name)}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>

                            {selectedTabContent}
                        </Box>
                    )}
                </Box>
            </PageBackgroundContainer>
        </BasePage>
    );
};

export default AccountsAndPaymentsPage;
