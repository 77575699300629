import { Box, Container, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import * as Schemas from "../../../utils/validationHelper";
import { getErrorMessage } from "../../../utils/errorHelper";
import SignUpController from "../../../controllers/SignUpController";
import InputComp from "../../../components/base_components/InputComp";
import ButtonComp from "../../../components/base_components/ButtonComp";
import LinkComp from "../../../components/base_components/LinkComp";
import CheckboxComp from "../../../components/base_components/CheckboxComp";

const InitialForm = ({ initialFormValues, setFormValues, submitCallback, ...props }) => {
    const { enqueueSnackbar } = useSnackbar();

    const initValues = {
        email: initialFormValues?.email || "",
        mobile: initialFormValues?.mobile || "",
    };

    const initDeleteAccValues = {
        deleteAccChecked: false,
        idNumbers: "",
    };

    const validations = Yup.object({
        email: Schemas.getSchemaEmail(true),
        mobile: Schemas.getSchemaForTelNumber(true),
    });

    const handleSubmit = (values, actions) => {
        setFormValues({
            ...initialFormValues,
            email: values.email,
            mobile: values.mobile,
        });

        if (submitCallback) submitCallback();

        actions.setSubmitting(false);
    };

    const handleDeleteAccSubmit = async (values, actions) => {
        try {
            const idNumbers = values.idNumbers
                ?.split(",")
                ?.map((id) => id.trim())
                ?.filter((id) => id !== "");

            if (idNumbers?.length > 0) {
                const formData = {
                    id_numbers: idNumbers,
                };
                const response = await SignUpController.deleteAccounts(formData);

                if (response?.message?.includes("success")) {
                    actions.resetForm();
                    enqueueSnackbar("Accounts successfully deleted", { variant: "info" });
                }
            }
        } catch (error) {
            const errMsg = getErrorMessage(error);

            // Check if request was aborted
            if (errMsg === null) return;

            enqueueSnackbar(errMsg, { variant: "error" });
        }
    };

    return (
        /* Signup form components */
        <Container sx={{ padding: "0 !important" }}>
            <Formik
                initialValues={initValues}
                validationSchema={validations}
                onSubmit={handleSubmit}
                validateOnBlur={false}
            >
                {({ values, isSubmitting, touched, errors, handleChange, handleBlur }) => (
                    <Form noValidate>
                        <Grid item xs={12} container>
                            {/* Email input */}
                            <Grid item xs={12} marginY={1}>
                                <InputComp
                                    labelText="Let's begin with your email"
                                    type="email"
                                    id="email"
                                    required
                                    autoFocus
                                    autoComplete="email"
                                    value={values.email}
                                    disabled={isSubmitting}
                                    touched={touched}
                                    errors={errors}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>

                            {/* Mobile number input */}
                            <Grid item xs={12} marginY={1}>
                                <InputComp
                                    labelText="Your mobile number"
                                    type="tel"
                                    id="mobile"
                                    required
                                    autoComplete="tel"
                                    value={values.mobile}
                                    disabled={isSubmitting}
                                    touched={touched}
                                    errors={errors}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>

                            {/* Submit button */}
                            <Grid item xs={12} marginY={4}>
                                <ButtonComp type="submit" isLoading={isSubmitting}>
                                    Create my account
                                </ButtonComp>
                            </Grid>

                            {/* Login page link */}
                            <Grid item xs={12} marginTop={2}>
                                <Box
                                    component="span"
                                    sx={{ display: "flex", width: "fit-content" }}
                                >
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        sx={{ marginRight: "10px" }}
                                    >
                                        Already have an account?
                                    </Typography>
                                    <LinkComp href="/login" disabled={isSubmitting}>
                                        Login
                                    </LinkComp>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>

            <Box display={"none"}>
                <Formik initialValues={initDeleteAccValues} onSubmit={handleDeleteAccSubmit}>
                    {({ values, isSubmitting, handleChange }) => (
                        <Form noValidate>
                            <Grid item xs={12} container>
                                {/* Delete account checkbox */}
                                <Grid item xs={12} marginY={1}>
                                    <CheckboxComp
                                        label="Delete an account?"
                                        id="deleteAccChecked"
                                        checked={values.deleteAccChecked}
                                        disabled={isSubmitting}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                {values.deleteAccChecked && (
                                    <>
                                        {/* ID numbers */}
                                        <Grid item xs={12} marginY={1}>
                                            <InputComp
                                                labelText="ID numbers"
                                                helperText="Comma seperated list of user ID numbers to delete"
                                                type="text"
                                                id="idNumbers"
                                                value={values.idNumbers}
                                                disabled={isSubmitting}
                                                onChange={handleChange}
                                            />
                                        </Grid>

                                        {/* Submit button */}
                                        <Grid item xs={12} marginY={2}>
                                            <ButtonComp type="submit" isLoading={isSubmitting}>
                                                Delete accounts
                                            </ButtonComp>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Container>
    );
};

export default InitialForm;
