import { useEffect, useState } from "react";
import {
    Backdrop,
    Box,
    CircularProgress,
    Container,
    Grid,
    LinearProgress,
    Paper,
    Typography,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { useSnackbar } from "notistack";
import CustomTheme from "../../../constants/Theme";
import { useAuthContext } from "../../../contexts/AuthContext";
import { getErrorMessage } from "../../../utils/errorHelper";
import ButtonComp from "../../../components/base_components/ButtonComp";
import LogoFullImg from "../../../assets/logos/logo_full.png";

const TermsAndConditionsForm = ({
    formValues,
    submitCallback,
    handleBack,
    progressBarPerc,
    ...props
}) => {
    const { signup, abortApiRequests } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submittingMsg, setSubmittingMsg] = useState("");
    const [submittingDetailsMsg, setSubmittingDetailsMsg] = useState("");

    useEffect(() => {
        return () => {
            abortApiRequests();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAccept = async () => {
        // Submit the user details to the API
        try {
            const formData = {
                ...formValues,
                user_accepted_terms: true,
            };

            setIsSubmitting(true);
            setSubmittingMsg("Creating your account. Please wait");
            setSubmittingDetailsMsg("This might take a minute or two");

            await signup(formData);

            submitCallback(true);
        } catch (error) {
            const errMsg = getErrorMessage(error);

            // Check if request was aborted
            if (errMsg === null) return;

            // Check if the account is already in use
            if (errMsg?.toLowerCase()?.includes("already in use")) {
                enqueueSnackbar("Your details are already in use", { variant: "warning" });
                return;
            }

            enqueueSnackbar(errMsg, { variant: "error" });
        } finally {
            setIsSubmitting(false);
            setSubmittingMsg("");
            setSubmittingDetailsMsg("");
        }
    };

    const handleDecline = () => {
        submitCallback(false);
    };

    return (
        <>
            {/* Terms & Conditions form components */}
            <Container
                id="conatinerTC"
                sx={{
                    position: { xs: "absolute", md: "unset" },
                    top: { xs: 0, md: "unset" },
                    left: { xs: 0, md: "unset" },
                    zIndex: { xs: 1000, md: "unset" },
                    background: { xs: "white", md: "unset" },
                    padding: { xs: "0", md: "0 0 5rem 0 !important" },
                    height: { xs: "100vh", md: "unset" },
                    maxHeight: { xs: "100vh", md: "unset" },
                    overflowY: { xs: "auto", md: "unset" },
                }}
            >
                {/* Sticky top section */}
                <Box
                    sx={{
                        display: { xs: "block", md: "none" },
                        position: "sticky",
                        top: 0,
                        zIndex: 1001,
                        backgroundColor: CustomTheme.palette.background.default,
                        padding: "1rem",
                        borderRadius: "0 0 2rem 2rem",
                    }}
                >
                    {/* Back button */}
                    <Box
                        onClick={handleBack}
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            width: "fit-content",
                            marginBottom: "2rem",
                        }}
                    >
                        <ExpandCircleDownIcon
                            sx={{ transform: "rotate(90deg)", marginRight: "10px" }}
                        />
                        <Typography variant="body1" component="span">
                            back
                        </Typography>
                    </Box>

                    {/* Progress bar */}
                    {progressBarPerc >= 0 && (
                        <LinearProgress variant="determinate" value={progressBarPerc} />
                    )}
                </Box>

                <Grid
                    container
                    justifyContent="center"
                    alignContent="center"
                    sx={{
                        background: {
                            xs: CustomTheme.palette.background.default,
                            md: "transparent",
                        },
                        borderRadius: { xs: "0 0 2rem 2rem", md: "none" },
                        padding: { xs: "1rem", md: 0 },
                        marginTop: { xs: "-2rem", md: 2 },
                        marginBottom: { xs: 0, md: 2 },
                    }}
                >
                    <Grid item xs={12} marginY={2}>
                        <Typography component="h1" variant="pageTitle1" textAlign="center">
                            Hello 👋
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography component="p" variant="body1" textAlign="center">
                            Before you create an account, please read and accept our Terms &
                            Conditions
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    sx={{
                        position: "absolute",
                        top: { xs: "unset", md: 0 },
                        left: { xs: "0", md: "50vw" },
                    }}
                >
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                width: "100%",
                                height: { xs: "unset", md: "100vh" },
                                maxHeight: { xs: "unset", md: "100vh" },
                                overflowY: { xs: "unset", md: "auto" },
                                padding: "1rem",
                                paddingBottom: "150px !important",
                                borderRadius: "0",
                            }}
                        >
                            <img
                                alt="full logo"
                                src={LogoFullImg}
                                style={{ maxWidth: "150px", marginTop: "1rem" }}
                            />
                            <Typography
                                component="h3"
                                variant="paperHeading"
                                textAlign="center"
                                mt={2}
                                mb={4}
                            >
                                Terms & Conditions
                            </Typography>

                            {/* Introduction */}
                            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                                Introduction
                            </Typography>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                These Terms place various obligations on you as a user so that we
                                can provide and protect our website, our services, and our brand. In
                                addition, they provide important information on how we use cookies
                                and collect and use your personal information, including your credit
                                report and credit score. As our services are provided at no
                                financial cost to our users, the Terms also describe how we limit
                                our liability to you with respect to our services and your use of
                                our website.
                            </Typography>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                Please take the time to read and understand the Terms before you
                                make use of our services and platform. If you do not want to be
                                legally bound by these Terms in their entirety, you must not accept
                                these Terms, in which case you cannot use our website, platform, or
                                our services.
                            </Typography>

                            <br />

                            {/* Changes to These Terms */}
                            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                                Changes to These Terms
                            </Typography>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                We may change these Terms at any time. We will notify you of any
                                such changes by sending you an email alert, by posting a notice on
                                our website or through the Services, or in any other manner we deem
                                appropriate. You acknowledge and understand that your continued use
                                of our website or our services will confirm your acceptance of the
                                revised terms.
                            </Typography>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                We may update our website and associated services from time to time
                                and may change the content at any time. Please note that any of the
                                content on our website or platform may be out of date at any given
                                time, and we are under no obligation to update it. Please check this
                                page from time to time to take notice of any changes we have made,
                                as they are binding on you.
                            </Typography>

                            <br />

                            {/* Definitions and Interpretation */}
                            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                                Definitions and Interpretation
                            </Typography>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                For purposes of the Terms:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        <span
                                            style={{
                                                fontWeight: 600,
                                                color: CustomTheme.palette.brandedTeal.main,
                                            }}
                                        >
                                            Terms
                                        </span>{" "}
                                        means these terms, consisting of:
                                    </Typography>
                                    <ol>
                                        <li>
                                            <Typography
                                                component="p"
                                                variant="paperBody"
                                                textAlign="justify"
                                            >
                                                these terms of use; and
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography
                                                component="p"
                                                variant="paperBody"
                                                textAlign="justify"
                                            >
                                                any other relevant specific terms, policies,
                                                disclaimers, rules, and notices agreed between the
                                                parties (including any that may apply to a
                                                particular section or module of this website and
                                                platform).
                                            </Typography>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        A word defined or assigned a meaning in the Terms will start
                                        with a capital letter. All headings are inserted for
                                        reference purposes only and must not affect the
                                        interpretation of the Terms. Whenever ‘including’ or
                                        ‘include’, or ‘excluding’ or ‘exclude’, together with
                                        specific examples or items, follow a term, they will not
                                        limit its ambit. Terms other than those defined within them
                                        will be given their plain English meaning. References to any
                                        enactment will be deemed to include references to the
                                        enactment as re-enacted, amended, or extended from time to
                                        time. A reference to a person includes a natural and
                                        juristic person, and a reference to either party includes
                                        the party’s successors or permitted assigns. Unless
                                        otherwise stated in the Terms, when any number of days is
                                        prescribed, the first day will be excluded and the last day
                                        included. The rule of construction that an agreement must be
                                        interpreted against the party responsible for its drafting
                                        or preparation does not apply.
                                    </Typography>
                                </li>
                            </ul>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                <span
                                    style={{
                                        fontWeight: 600,
                                        color: CustomTheme.palette.brandedTeal.main,
                                    }}
                                >
                                    "ThreeSixty.me"
                                </span>
                                refers to the proprietary financial wellness tool operated by
                                CreditSmart Financial Services (Pty) Ltd. This free and secure
                                platform enables consumers to create personal profiles, through
                                which it accesses and retrieves their credit data from credit
                                bureaus. The platform presents this information in a user-friendly
                                format, allowing consumers to review their credit score, account
                                history, queries, judgments, and more. Furthermore, the platform
                                provides enhanced services through its "SmartHelp" feature, which
                                includes "SmartCoach" and "SmartSolution" modules. These modules
                                offer personalized financial education and tailored financial
                                services solutions, respectively, designed to assist consumers in
                                improving their financial health and making informed financial
                                decisions.
                            </Typography>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                <span
                                    style={{
                                        fontWeight: 600,
                                        color: CustomTheme.palette.brandedTeal.main,
                                    }}
                                >
                                    "User or you"
                                </span>
                                means any individual, entity, or system that engages with the
                                system, software, or platform in order to perform tasks, access
                                information, or utilize resources provided for on the ThreeSixty.me
                                platform.
                            </Typography>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                <span
                                    style={{
                                        fontWeight: 600,
                                        color: CustomTheme.palette.brandedTeal.main,
                                    }}
                                >
                                    "Platform"
                                </span>{" "}
                                refers to the ThreeSixty.me Platform, an online, interactive service
                                operated by CreditSmart Financial Services (Pty) Ltd. This Platform
                                encompasses the software, websites, functionalities, and services
                                provided through ThreeSixty.me, enabling users to access, analyze,
                                and manage their credit information. It integrates with external
                                credit bureaus to retrieve users' credit data, which is then
                                displayed in an easily understandable format. The term "Platform"
                                includes all content, user interfaces, and interaction capabilities
                                offered to the user as part of ThreeSixty.me services.
                            </Typography>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                <span
                                    style={{
                                        fontWeight: 600,
                                        color: CustomTheme.palette.brandedTeal.main,
                                    }}
                                >
                                    "Credit Report"
                                </span>{" "}
                                means a detailed record of an individual’s credit history, held and
                                accessed by credit bureaus, which includes, among others, an
                                individual’s credit history, outstanding debts, and borrowing and
                                repayment activities to assess an individual’s credit worthiness.
                            </Typography>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                <span
                                    style={{
                                        fontWeight: 600,
                                        color: CustomTheme.palette.brandedTeal.main,
                                    }}
                                >
                                    "Consumer Report"
                                </span>{" "}
                                refers to a comprehensive profile presented on the ThreeSixty.me
                                platform, distinct from traditional credit reports. This report is
                                compiled using data derived from the consumer's credit information
                                and additional financial details provided by or about the consumer,
                                such as income, expenses, and insurance policies. The Consumer
                                Report is used to provide a holistic view of the consumer’s
                                financial status, facilitating personalized financial advice and
                                service offerings through the ThreeSixty.me Platform. This report
                                forms the basis for tailored training tips in the "SmartCoach"
                                module and for customized financial solutions in the "SmartSolution"
                                module, aimed at guiding consumers towards a better financial
                                future.
                            </Typography>

                            <br />

                            {/* Conflict */}
                            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                                Conflict
                            </Typography>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                If there is a conflict of meaning between these terms of use and any
                                other relevant specific terms, policies, disclaimers, rules, and
                                notices agreed between the parties, the specific terms will prevail
                                regarding your use of the relevant section or module of the website.
                            </Typography>

                            <br />

                            {/* General */}
                            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                                General
                            </Typography>
                            <ol>
                                <li data-indent-text="1.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        These terms and conditions (“the Terms”) contractually
                                        regulate the way in which the ThreeSixty.me platform may be
                                        used.
                                    </Typography>
                                </li>
                                <li data-indent-text="1.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        You agree to be bound by the Terms which create a legally
                                        binding contract between yourself and ThreeSixty.me.
                                    </Typography>
                                </li>
                                <li data-indent-text="1.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        "ThreeSixty.me", "us", or "we" – refers to the platform
                                        known as ThreeSixty.me, which is utilized by CreditSmart
                                        Financial Services (Pty) Ltd, a company duly registered as a
                                        financial services provider with the Financial Sector
                                        Conduct Authority (FSCA) under registration number 52295, to
                                        facilitate the provision of personalized financial services.
                                        This term includes the involvement of any partners,
                                        affiliates, and other entities that collaborate with
                                        CreditSmart Financial Services (Pty) Ltd in delivering these
                                        services through the ThreeSixty.me platform.
                                    </Typography>
                                </li>
                                <li data-indent-text="1.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        “Personal Information” – means all personal information as
                                        defined in the Protection of Personal Information Act 4 of
                                        2013 which includes names, cell numbers, email addresses,
                                        identification numbers, financial history, etc.
                                    </Typography>
                                </li>
                                <li data-indent-text="1.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        “Your / your / yourself” – means a natural person who has
                                        been registered to use the ThreeSixty.me platform.
                                    </Typography>
                                </li>
                                <li data-indent-text="1.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        By agreeing to these terms and conditions, you give consent
                                        to receive marketing communications and materials via all
                                        channels including, but not limited to, SMS, Email, and
                                        Phone. Communications can include promotional offers,
                                        service updates, and other information related to
                                        ThreeSixty.me products and services.
                                    </Typography>
                                </li>
                                <li data-indent-text="1.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        By agreeing to these terms and conditions, you consent to
                                        receive quotes and other offers from ThreeSixty.me and any
                                        affiliated entities that may offer products and services
                                        deemed relevant to your profile. Quotes and offers may
                                        include, but are not limited to, financial products,
                                        services, and promotions relating to your personal
                                        information and financial details.
                                    </Typography>
                                </li>
                                <li data-indent-text="1.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        By accepting these terms and conditions, you consent to
                                        receiving other service-related marketing material,
                                        including, but not limited to, newsletters, monthly updates
                                        on your credit profile, industry news, tips to improve your
                                        credit score, and any other updates on changes to our
                                        services and products.
                                    </Typography>
                                </li>
                            </ol>

                            <br />

                            {/* Using the Service */}
                            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                                Using the Service
                            </Typography>
                            <ol>
                                <li data-indent-text="2.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        The ThreeSixty.me Service is a free and secure financial
                                        wellness tool that allows you to check your credit rating
                                        and learn how to improve it. You will be registered for the
                                        service when you accept these Terms, authenticate yourself
                                        through security questions, and when your user profile is
                                        created on the ThreeSixty.me platform.
                                    </Typography>
                                </li>
                                <li data-indent-text="2.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        You agree that in order for us to provide the service to
                                        you, we will need to obtain your Personal, Financial, and
                                        Credit Information from the credit bureaus. This may be done
                                        on a continuous basis until you unsubscribe from the
                                        service.
                                    </Typography>
                                </li>
                                <li data-indent-text="2.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        You have the right to unsubscribe from the service at any
                                        time by deleting your profile on the ThreeSixty.me platform.
                                        You can opt-out of the electronic communications you may
                                        receive.
                                    </Typography>
                                </li>
                                <li data-indent-text="2.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        ThreeSixty.me maintains a service level agreement (SLA) with
                                        regards to addressing support tickets and logs. This policy
                                        will be implemented internally, and all users making use of
                                        our products and services consent and agree to these terms
                                        when submitting support tickets to our support services.
                                        More detail on this clause will be provided in the terms and
                                        conditions when logging a support ticket.
                                    </Typography>
                                </li>
                                <li data-indent-text="2.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Under this agreement, registered user access to the user’s
                                        credit profile is provided free of charge while utilizing
                                        the ThreeSixty.me platform and we do not engage in the
                                        resale of credit profile information or data or credit
                                        reports obtained through the credit bureaus.
                                    </Typography>
                                </li>
                                <li data-indent-text="2.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        By agreeing to these terms, the user explicitly consents to
                                        have their credit data pulled, processed, and stored from
                                        all relevant credit bureaus, on a monthly basis, regardless
                                        of whether the user’s account is actively being utilized.
                                    </Typography>
                                </li>
                            </ol>

                            <br />

                            {/* Your Privacy */}
                            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                                Your Privacy
                            </Typography>
                            <ol>
                                <li data-indent-text="3.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Please refer to ThreeSixty.me’s Privacy Policy which can be
                                        found at https://www.ThreeSixty.me/legal/privacy-policy for
                                        more detail on how your information is stored.
                                    </Typography>
                                </li>
                                <li data-indent-text="3.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        In this privacy term, references to “we”, “us” or “our” are
                                        references to the entities in the ThreeSixty.me platform,
                                        and all affiliates, associates, cessionaries, delegates,
                                        successors in title or third parties (authorized agents and
                                        contractors), when such parties are acting as responsible
                                        parties or operators in terms of applicable privacy laws
                                        unless stated otherwise.
                                    </Typography>
                                </li>
                                <li data-indent-text="3.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        By registering for the ThreeSixty.me platform, you
                                        acknowledge that in order to: conclude and fulfil
                                        contractual terms or obligations to you; comply with
                                        obligations imposed by law; or protect or pursue your, our,
                                        or a third party’s legitimate interests, including offering
                                        solutions that best meet your needs; your Personal
                                        Information may be processed through centralized functions
                                        and systems across entities in the ThreeSixty.me platform
                                        and may be used for the purposes, in the manner, and with
                                        the appropriate controls as set out in our Privacy Policy.
                                    </Typography>
                                </li>
                                <li data-indent-text="3.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Where it is necessary to obtain consent for processing
                                        outside of this privacy term, we will explicitly seek your
                                        consent separately.
                                    </Typography>
                                </li>
                                <li data-indent-text="3.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        We want to ensure that you fully understand how your
                                        Personal Information may be used. We have described the
                                        purposes for which your Personal Information may be used in
                                        detail in our Privacy Policy. We have also set out further
                                        information about accessing, correcting, or objecting to the
                                        processing of your Personal Information in our Privacy
                                        Policy, which we strongly advise that you read.
                                    </Typography>
                                </li>
                                <li data-indent-text="3.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        The responsible party is the party with whom you are
                                        contracting as per these Terms, as well as other entities in
                                        the ThreeSixty.me platform, which are listed in our Privacy
                                        Policy as responsible parties. For the contact details of
                                        these responsible parties, please see our Privacy Policy.
                                    </Typography>
                                </li>
                                <li data-indent-text="3.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        By agreeing to these terms and conditions you consent to the
                                        secure storage of your personal credit profile data, the
                                        use, processing, and analysis of this data, on a monthly
                                        basis or as often as necessary, for as long as you are
                                        subscribed to this service, and the providing of
                                        personalized solutions based on this data.
                                    </Typography>
                                </li>
                                <li data-indent-text="3.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        When utilizing these services, you acknowledge and provide
                                        consent to allow the sharing of your information with the
                                        relevant credit bureaus for purposes related to assessing
                                        and managing your credit profile.
                                    </Typography>
                                </li>
                                <li data-indent-text="3.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Our services also require that, when agreeing to the terms
                                        and conditions, you provide consent to be contacted by a
                                        representative of ThreeSixty.me for the purposes of
                                        providing assistance, support or information related to your
                                        credit profile, and any other products and services offered
                                        by any of our affiliates.
                                    </Typography>
                                </li>
                                <li data-indent-text="3.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Your credit profile data will be retained for no longer than
                                        is necessary for the use and analysis of the data as
                                        prescribed in clause 3.7 above. By accepting these terms and
                                        conditions, you agree to the retention of your credit
                                        profile data for the purposes of 3.7 above.
                                    </Typography>
                                </li>
                            </ol>

                            <br />

                            {/* Customer and Transaction Screening */}
                            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                                Customer and Transaction Screening
                            </Typography>
                            <ol>
                                <li data-indent-text="4.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        We will do our best to stop and/or prevent any criminal
                                        activities including money laundering and terrorist
                                        financing. In assisting with this, you acknowledge that we:
                                    </Typography>
                                    <ul>
                                        <li data-indent-text="4.">
                                            <Typography
                                                component="p"
                                                variant="paperBody"
                                                textAlign="justify"
                                            >
                                                may verify (check and confirm) your identity as well
                                                as that of any persons related to or acting on
                                                behalf of or involved with you at the start of the
                                                business relationship and as often as reasonably
                                                necessary thereafter.
                                            </Typography>
                                        </li>
                                        <li data-indent-text="4.">
                                            <Typography
                                                component="p"
                                                variant="paperBody"
                                                textAlign="justify"
                                            >
                                                will not willingly and knowingly do business with
                                                any person that appears on any “sanction” list in
                                                terms of legislation or in the management of its
                                                risk or that is linked to any person that appears on
                                                such sanction list or linked to any restricted
                                                countries or the government agencies of such
                                                restricted countries. Note that these lists are
                                                updated on a regular basis.
                                            </Typography>
                                        </li>
                                        <li data-indent-text="4.">
                                            <Typography
                                                component="p"
                                                variant="paperBody"
                                                textAlign="justify"
                                            >
                                                may monitor any transactions, instructions, and
                                                recipients and can request/verify further
                                                information before acting on an instruction or
                                                transaction. This could result in a delay in
                                                carrying out the instruction.
                                            </Typography>
                                        </li>
                                        <li data-indent-text="4.">
                                            <Typography
                                                component="p"
                                                variant="paperBody"
                                                textAlign="justify"
                                            >
                                                may refuse to carry out any instruction or
                                                transaction and place a hold on any account should
                                                it consider necessary.
                                            </Typography>
                                        </li>
                                    </ul>
                                </li>
                                <li data-indent-text="4.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        You agree to assist ThreeSixty.me to comply with the laws by
                                        providing all the information and documents reasonably
                                        required from time to time. If you fail to do so or provide
                                        false information, ThreeSixty.me can refuse to enter into a
                                        relationship with you, refuse to carry out an instruction or
                                        process an instruction and can also end its relationship
                                        with you.
                                    </Typography>
                                </li>
                                <li data-indent-text="4.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        We will not be responsible to you, or any person, or
                                        customer for any loss or damage, you or they suffer if any
                                        steps mentioned in this clause or anything else necessary to
                                        comply with the laws.
                                    </Typography>
                                </li>
                                <li data-indent-text="4.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Under this agreement, the user acknowledges that the
                                        information presented by ThreeSixty.me is based on data
                                        obtained and provided by the credit bureaus, and while our
                                        services strive for accuracy, we cannot be held liable or
                                        guarantee that data presented on our services is 100%
                                        accurate and correct, the user maintains responsibility to
                                        inform us, and the relevant credit bureaus of any
                                        inaccuracies or discrepancies in the user’s credit data.
                                    </Typography>
                                </li>
                            </ol>

                            <br />

                            {/* Our Intellectual Property rights */}
                            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                                Our Intellectual Property rights
                            </Typography>
                            <ol>
                                <li data-indent-text="5.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        You acknowledge that in rendering the Service, we may be
                                        divulging our Confidential Information to you, including our
                                        Intellectual Property (“IP”). You agree that this
                                        information and its associated goodwill belong to us.
                                    </Typography>
                                </li>
                                <li data-indent-text="5.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        You agree that your right to use the Confidential
                                        Information is limited to your subscription to the Service
                                        and you will not:
                                    </Typography>
                                    <ul>
                                        <li data-indent-text="5.">
                                            <Typography
                                                component="p"
                                                variant="paperBody"
                                                textAlign="justify"
                                            >
                                                publish or otherwise reproduce, repackage, modify,
                                                adapt, disseminate any of the Confidential
                                                Information, in whole or in part to any third party;
                                            </Typography>
                                        </li>
                                        <li data-indent-text="5.">
                                            <Typography
                                                component="p"
                                                variant="paperBody"
                                                textAlign="justify"
                                            >
                                                transmit, transfer, distribute, re-distribute, copy,
                                                sell, re-sell, lease, rent, license, sub-license any
                                                of the Confidential Information, in whole or in part
                                                to any third party;
                                            </Typography>
                                        </li>
                                        <li data-indent-text="5.">
                                            <Typography
                                                component="p"
                                                variant="paperBody"
                                                textAlign="justify"
                                            >
                                                allow any third party to link to the Confidential
                                                Information, in whole or in part in any way
                                                whatsoever; or
                                            </Typography>
                                        </li>
                                        <li data-indent-text="5.">
                                            <Typography
                                                component="p"
                                                variant="paperBody"
                                                textAlign="justify"
                                            >
                                                use the Confidential Information, in whole or in
                                                part, in any form or manner or by any means
                                                whatsoever without our express prior written
                                                consent.
                                            </Typography>
                                        </li>
                                    </ul>
                                </li>
                                <li data-indent-text="5.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        In some instances, data displayed on our services may be
                                        outdated due to delays in the transferring of data between
                                        ThreeSixty.me and the relevant credit bureaus. We reserve
                                        the right to display such outdated data until such time as
                                        the updated data is received from the credit bureaus and
                                        other affiliate groups.
                                    </Typography>
                                </li>
                                <li data-indent-text="5.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        ThreeSixty.me holds the right to utilize analytics to access
                                        user data and provide solutions where necessary. Solutions
                                        presented to the user may not always be relevant and does
                                        not imply user agreement and does not apply any obligation
                                        on the user to comply with solutions presented and should
                                        only be viewed for consideration.
                                    </Typography>
                                </li>
                                <li data-indent-text="5.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        ThreeSixty.me holds the right to safely and confidentially
                                        store data related to consumer reports, which include but
                                        are not limited to the secure storage of credit reports and
                                        data. Such reports are stored under strict adherence to
                                        consumer protection laws using encrypted software protection
                                        and controlled access to prevent the unauthorized use,
                                        access, or disclosure of consumer personal information.
                                        Additionally, ThreeSixty.me holds the right to review and
                                        enhance our security measures and protocols to comply with
                                        applicable data protection laws and regulations. Consumer
                                        reports are distinct from credit profiles as they encompass
                                        a broader range of consumer-related data and information
                                        that will be stored and utilized by ThreeSixty.me for
                                        improved services and products under strict adherence to
                                        confidentiality and protection regulations.
                                    </Typography>
                                </li>
                                <li data-indent-text="5.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        CreditSmart Financial Services (Pty) Ltd, as a registered
                                        financial service provider and owner of the ThreeSixty.me
                                        platform, retains the right to process user financial and
                                        consumer information shared to it by ThreeSixty.me in order
                                        to comply with industry standards related to the reporting
                                        and updating of credit information provided to the relevant
                                        credit bureaus.
                                    </Typography>
                                </li>
                            </ol>

                            <br />

                            {/* Limitation of Liability */}
                            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                                Limitation of Liability
                            </Typography>
                            <ol>
                                <li data-indent-text="6.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        ThreeSixty.me will take all reasonable steps to ensure that
                                        the information provided through the ThreeSixty.me platform
                                        is correct. You acknowledge that if you rely on the
                                        information for any reason, such action is taken at your own
                                        risk.
                                    </Typography>
                                </li>
                                <li data-indent-text="6.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Information, ideas, and opinions expressed as part of the
                                        ThreeSixty.me platform should not be regarded as
                                        professional advice. You must seek professional advice
                                        before taking any decisions based on the information
                                        provided through the ThreeSixty.me platform.
                                    </Typography>
                                </li>
                                <li data-indent-text="6.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        As part of the registration process, we will provide you
                                        with login details to use the service. You accept
                                        responsibility to keep your login details secure. We will
                                        assume that the person using the service is in fact you.
                                    </Typography>
                                </li>
                                <li data-indent-text="6.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Accordingly, as permitted in law, you hereby indemnify
                                        ThreeSixty.me and its members, directors, employees, or
                                        business partners against all claims, loss, demands,
                                        actions, damages, and causes of action whatsoever that you
                                        or anyone related to you in any way may suffer arising
                                        directly or indirectly as a result of your use of the
                                        service.
                                    </Typography>
                                </li>
                                <li data-indent-text="6.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Under this agreement, users are hereby informed that
                                        ThreeSixty.me, and any affiliated organizations, cannot be
                                        held liable for any damages resulting from the sharing of
                                        login information with any third party. The user holds the
                                        responsibility to ensure that their login details remain
                                        secure and confidential.
                                    </Typography>
                                </li>
                                <li data-indent-text="6.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        In the circumstances whereby a scamster is able to bypass
                                        any security measures, ThreeSixty.me cannot be held liable
                                        for any damages incurred thereafter. ThreeSixty.me has
                                        instituted reasonable security measures to protect against
                                        the loss or threat to personal information, but cannot
                                        guarantee the absolute protection of personal data against
                                        all potential threats.
                                    </Typography>
                                </li>
                            </ol>

                            <br />

                            {/* Termination */}
                            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                                Termination
                            </Typography>
                            <ol>
                                <li data-indent-text="7.">
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        You will only receive monthly updates to your credit score
                                        and credit report while you remain an active user of our
                                        services. You acknowledge and agree that you will cease to
                                        be an active user if you do not log in to your account for
                                        more than 364 days. We will be entitled to stop providing
                                        you with monthly updates and close and delete your account
                                        if you do not log in to your account in any 364-day period.
                                        Should we decide to end the provision of our Services and
                                        close and delete your account, we will send you an email to
                                        alert you of our intention to do so. To resume the service,
                                        simply log in to your account within one month of receipt of
                                        the email alert.
                                    </Typography>
                                </li>
                            </ol>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            {/* Floating action buttons */}
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    zIndex: 1001,
                    width: { xs: "100%", md: "50vw" },
                    left: { xs: "0", md: "50vw" },
                    display: isSubmitting ? "none" : "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        width: { xs: "100%", md: "calc(100% - 40px)" },
                        marginRight: { xs: "0", md: "20px" },
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        padding: "4rem 1rem 1rem 1rem",
                        backdropFilter: "blur(3px)",
                        background:
                            "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
                    }}
                >
                    <ButtonComp
                        color="secondaryOnPaper"
                        onClick={handleDecline}
                        disabled={isSubmitting}
                        sx={{ maxWidth: "48%" }}
                    >
                        Decline
                    </ButtonComp>

                    <ButtonComp
                        onClick={handleAccept}
                        disabled={isSubmitting}
                        sx={{ maxWidth: "48%" }}
                    >
                        Accept
                    </ButtonComp>
                </Box>
            </Box>

            {/* Loading backdrop */}
            <Backdrop open={isSubmitting}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="PageTitle2"
                            textAlign="center"
                            gutterBottom
                        >
                            {submittingMsg}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="p" variant="body1">
                            {submittingDetailsMsg}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} marginTop={5}>
                        <CircularProgress size="5rem" />
                    </Grid>
                </Grid>
            </Backdrop>
        </>
    );
};

export default TermsAndConditionsForm;
