import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import BasePage from "../BasePage";
import ButtonLinkComp from "../../components/base_components/ButtonLinkComp";
import BackgroundImg from "../../assets/images/background_4_grouped.png";

const NavLinks = [
    {
        title: "Personal Information",
        destination: "/profile/pi-details",
    },
    {
        title: "Address Information",
        destination: "/profile/address-details",
    },
    {
        title: "Change Password",
        destination: "/profile/change-password",
    },
];

const ProfilePage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const goToPage = (destination) => {
        navigate(destination);
    };

    useEffect(() => {
        if (!isMobile) {
            navigate(NavLinks[0].destination, { replace: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BasePage>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column-reverse", sm: "column" },
                    alignItems: "center",
                    justifyContent: { xs: "flex-start", sm: "center" },
                    width: "100%",
                    height: { xs: "unset", sm: "calc(100vh - 4rem)" },
                    marginTop: { xs: "-1.5rem", sm: "0" },
                }}
            >
                {/* Screens nav buttons */}
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: "600px",
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: { xs: 0, sm: "2rem" },
                        paddingBottom: "2rem",
                    }}
                >
                    <Typography component="h1" variant="pageTitle1" marginY={2}>
                        My Profile
                    </Typography>

                    {NavLinks.map((link, i) => (
                        <ButtonLinkComp
                            key={i}
                            onClick={() => goToPage(link.destination)}
                            sx={{ marginY: "1rem" }}
                        >
                            {link.title}
                        </ButtonLinkComp>
                    ))}
                </Box>

                {/* Background image */}
                <Box width="100%">
                    <Box
                        sx={{
                            width: { xs: "calc(100% + 3rem)", sm: "fit-content" },
                            maxWidth: "600px",
                            transform: {
                                xs: "translatex(-1.9rem)",
                                md: "translate(-1.9rem, 4rem)",
                            },
                        }}
                    >
                        <img src={BackgroundImg} alt="background" width="100%" />
                    </Box>
                </Box>
            </Box>
        </BasePage>
    );
};

export default ProfilePage;
