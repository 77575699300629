import AuthController from "../controllers/AuthController";
import SignUpController from "../controllers/SignUpController";
import { safeParseJSON } from "./generalHelper";

export const DefaultErrorMessage = "An unexpected error occurred";
export const DefaultNetworkErrorMessage = "Network request failed";

export function getAuthenticationFailedMsg(error) {
    // Check if this is a "not-authenticated" server-response for the login
    const requestUrl = error.request?.responseURL;
    const responseStatus = error.response?.status;
    const responseData = error.response?.data;

    if (requestUrl?.endsWith(AuthController.LoginURL) && responseStatus === 401) {
        return responseData?.Message || "Invalid login credentials";
    }

    if (requestUrl?.includes(SignUpController.ValidateOtpURL) && responseStatus === 400) {
        return responseData?.Message || "Invalid or expired OTP";
    }

    return "";
}

export function getErrorMessage(error) {
    if (!error) {
        // Error object is empty, return default error message
        return DefaultErrorMessage;
    }

    if (error.code === "ERR_CANCELED") {
        // Request was cancelled, no error message necessary
        return null;
    }

    const errorMsg = error.message;
    const responseStatus = error.response?.status;
    const responseData = error.response?.data;
    const responseMsg = responseData?.message || responseData?.Message;

    if (responseStatus > 0) {
        // Return either the default message or the message received from the API
        return `${DefaultNetworkErrorMessage}. ${responseMsg || errorMsg}`;
    } else {
        // This is a general error
        return `${DefaultErrorMessage}. ${errorMsg}`;
    }
}
