import { Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import LegalDocWrapper from "../../components/wrappers/LegalDocWrapper";

const CreditCoverTC = ({ backToCreditCover, ...props }) => {
    const theme = useTheme();

    return (
        <LegalDocWrapper backHandler={backToCreditCover}>
            {/* Title */}
            <Typography component="h3" variant="paperHeading" mt={4} mb={2}>
                CreditCover<sup>TM</sup>
            </Typography>

            {/* Sub-Title */}
            <Typography
                component="h5"
                variant="paperSubHeading"
                mb={4}
                sx={{ color: theme.palette.brandedTeal.main }}
            >
                SmartSolutions Terms and Conditions
            </Typography>

            {/* Intro paragraph */}
            <Typography component="p" variant="paperBody" textAlign="justify">
                These Product Specific Terms and Conditions ("Terms") govern the use of the SmartSolutions
                CreditCover service provided by ThreeSixty.me operated by CreditSmart
                Financial Services (Pty) Ltd. By lodging this inquiry, you agree to comply with
                these Terms.
            </Typography>

            <br />

            {/* Numbered list terms */}
            <ol>
                {/* Point #1 */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        By clicking “Call Me” you accept these terms and conditions and confirm that
                        you have read through them and understand them. If you do not agree with any
                        part of these Terms, do not use the service.
                    </Typography>
                </li>
                {/* Point #2 */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        You understand that this is only an inquiry and not an acceptance of a final
                        CreditCover policy.
                    </Typography>
                </li>
                {/* Point #3 */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        You agree to indemnify and hold ThreeSixty.me harmless from and against any
                        claims, damages, losses, liabilities, and expenses arising out of or in
                        connection with your inquiry and or acceptance of the service or product,
                        including but not limited to any breach of these Terms.
                    </Typography>
                </li>
                {/* Point #4 */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        You agree that even though we based the quote on the available information
                        on your credit profile, in line with industry regulations, we cannot
                        guarantee the correctness thereof.
                    </Typography>
                </li>
                {/* Point #5 */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        As per clause 4 above, you understand and accept that:
                    </Typography>
                    <ol>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                The estimated instalment amount is an estimated amount based on the
                                information and accounts received from your most recent credit
                                report pulled from various credit bureaux as displayed on
                                ThreeSixty.me and that certain information may be incorrect which
                                will affect the estimated quote.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                Even if you are eligible for the service as per the relevant
                                legislative authorities, the service provider is not required nor
                                obliged to accept you as a client.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                By clicking on this quote, it does not mean that you will qualify
                                for the service automatically.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                ThreeSixty.me reserves the right to update, modify, or discontinue
                                the service or product, or any part thereof, at any time without
                                prior notice.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                The acceptance of the preliminary quote places no legal obligation
                                on yourself or ThreeSixty.me.
                            </Typography>
                        </li>
                    </ol>
                </li>
                {/* Point #6 - Data Privacy */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Data Privacy
                    </Typography>
                    <ol>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                By using this service, you consent to the collection and processing
                                of your personal data in accordance with ThreeSixty.me's Privacy
                                Policy.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                ThreeSixty.me will handle your personal data in a manner consistent
                                with applicable data protection laws.
                            </Typography>
                        </li>
                    </ol>
                </li>
                {/* Point #7 - Governing Law */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Governing Law
                    </Typography>

                    <br />

                    <Typography component="p" variant="paperBody" textAlign="justify">
                        These Terms shall be governed by and construed in accordance with the laws
                        of the Republic of South Africa.
                    </Typography>
                </li>
            </ol>
        </LegalDocWrapper>
    );
};

export default CreditCoverTC;
