import { Box, Divider, Grid, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { normaliseString } from "../../../utils/generalHelper";

const PaymentsContainer = ({ accountGroups, ...props }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            {accountGroups?.length > 0 &&
                accountGroups.map((group, i) => (
                    <Box key={i} sx={{ width: "100%", marginY: 2 }}>
                        <Box sx={{ width: "100%" }}>
                            {/* Group header */}
                            <Typography component="h2" variant="body1BoldResp" marginY={2}>
                                {normaliseString(group.groupName)}
                            </Typography>

                            {!(group.accounts?.length > 0)
                                ? // Default empty state
                                  group.emptyComp
                                : // Grouped account payment histories
                                  group.accounts.map((account, j) => (
                                      <Box key={j}>
                                          {j === 0 ? null : <Divider sx={{ marginY: "1rem" }} />}
                                          <Grid container spacing={2} direction="column">
                                              {/* Account name */}
                                              <Grid item xs={12}>
                                                  <Typography component="h6" variant="body1Resp">
                                                      {normaliseString(account.name)}
                                                  </Typography>
                                              </Grid>

                                              {/* Account full payment history */}
                                              <Grid item xs={12} container>
                                                  {isMobile
                                                      ? account.paymentHistoryShort
                                                      : account.paymentHistoryFull}
                                              </Grid>
                                          </Grid>
                                      </Box>
                                  ))}
                        </Box>
                    </Box>
                ))}
        </Box>
    );
};

export default PaymentsContainer;
