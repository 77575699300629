import { Container, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as Schemas from "../../../utils/validationHelper";
import InputComp from "../../../components/base_components/InputComp";
import ButtonComp from "../../../components/base_components/ButtonComp";

const CreditProfileForm = ({ initialFormValues, setFormValues, submitCallback, ...props }) => {
    const initValues = {
        first_name: initialFormValues?.first_name || "",
        surname: initialFormValues?.surname || "",
        id_number: initialFormValues?.id_number || "",
        password: initialFormValues?.password || "",
        passwordConfirmation: initialFormValues?.password || "",
    };

    const validations = Yup.object({
        first_name: Schemas.getSchemaForRequiredText(),
        surname: Schemas.getSchemaForRequiredText(),
        id_number: Schemas.getSchemaForID(true, true),
        password: Schemas.getSchemaForPassword(true),
        passwordConfirmation: Schemas.getSchemaForPasswordConfirmation("password"),
    });

    const handleSubmit = (values, actions) => {
        setFormValues({
            ...initialFormValues,
            first_name: values.first_name,
            surname: values.surname,
            id_number: values.id_number.trim(),
            password: values.password,
        });

        actions.setSubmitting(false);

        if (submitCallback) submitCallback();
    };

    return (
        /* Credit profile form components */
        <Container sx={{ padding: "0 !important" }}>
            <Formik
                initialValues={initValues}
                validationSchema={validations}
                onSubmit={handleSubmit}
                validateOnBlur={false}
            >
                {({ values, isSubmitting, touched, errors, handleChange, handleBlur }) => (
                    <Form noValidate>
                        <Grid container spacing={2}>
                            {/* First name input */}
                            <Grid item xs={12}>
                                <InputComp
                                    labelText="First name"
                                    type="text"
                                    id="first_name"
                                    required
                                    autoFocus
                                    autoComplete="first name"
                                    value={values.first_name}
                                    disabled={isSubmitting}
                                    touched={touched}
                                    errors={errors}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>

                            {/* Last name input */}
                            <Grid item xs={12}>
                                <InputComp
                                    labelText="Last name"
                                    type="text"
                                    id="surname"
                                    required
                                    autoComplete="last name"
                                    value={values.surname}
                                    disabled={isSubmitting}
                                    touched={touched}
                                    errors={errors}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>

                            {/* ID number input */}
                            <Grid item xs={12}>
                                <InputComp
                                    labelText="ID number"
                                    helperText="ID is used to securely find your credit score and report"
                                    type="text"
                                    inputProps={{ inputMode: "numeric" }}
                                    id="id_number"
                                    required
                                    autoComplete="id_number"
                                    value={values.id_number}
                                    disabled={isSubmitting}
                                    touched={touched}
                                    errors={errors}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>

                            {/* Password input */}
                            <Grid item xs={12}>
                                <InputComp
                                    labelText="Password"
                                    type="password"
                                    id="password"
                                    required
                                    showPasswordHint
                                    autoComplete="current-password"
                                    value={values.password}
                                    disabled={isSubmitting}
                                    touched={touched}
                                    errors={errors}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>

                            {/* Password confirmation input */}
                            <Grid item xs={12}>
                                <InputComp
                                    labelText="Confirm password"
                                    type="password"
                                    id="passwordConfirmation"
                                    required
                                    autoComplete="current-password"
                                    value={values.passwordConfirmation}
                                    disabled={isSubmitting}
                                    touched={touched}
                                    errors={errors}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>

                            {/* Submit button */}
                            <Grid item xs={12} marginY={4}>
                                <ButtonComp type="submit" isLoading={isSubmitting}>
                                    Continue
                                </ButtonComp>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default CreditProfileForm;
