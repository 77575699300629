import { Box, CircularProgress, Divider, LinearProgress, Typography } from "@mui/material";
import customTheme from "../../../../constants/Theme";
import { getModuleStatusBadge, renderModuleContent } from "../../../../utils/smartCoachHelper";
import { getEmbeddedVimeoURL } from "../../../../utils/generalHelper";
import ButtonComp from "../../../../components/base_components/ButtonComp";

const Module = ({
    topic,
    module,
    nextModule,
    isLoading,
    saveModule,
    continueModule,
    hideNextModule,
    stickyVideo,
    ...props
}) => {
    return (
        <>
            {/* Loading indicator */}
            {isLoading && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80%",
                    }}
                >
                    <Typography
                        component="h1"
                        variant="pageTitle1Resp"
                        textAlign="center"
                        gutterBottom
                    >
                        Loading The Module Details
                    </Typography>
                    <CircularProgress size="5rem" />
                </Box>
            )}

            {/* No content message */}
            {!isLoading && !module && (
                <Typography
                    component="p"
                    variant="body1BoldResp"
                    color={customTheme.palette.warning.main}
                    textAlign="center"
                    marginY={4}
                >
                    The coaching module and related content were not received from the server.
                    Please contact support if this issue persists.
                </Typography>
            )}

            {/* Module content */}
            {!isLoading && Object.keys(module ?? {}).length > 0 && (
                <>
                    {/* Sticky video and module info */}
                    <Box
                        sx={
                            !stickyVideo
                                ? {
                                      padding: "1rem",
                                  }
                                : {
                                      position: "sticky",
                                      zIndex: 1000,
                                      top: 0,
                                  }
                        }
                    >
                        {/* Video */}
                        {module.video_url?.length > 0 && (
                            <Box sx={{ aspectRatio: 16 / 9 }}>
                                {/* Video container */}
                                <iframe
                                    title="Module video"
                                    width="100%"
                                    height="100%"
                                    style={{
                                        border: "none",
                                        borderRadius: !stickyVideo ? "2rem" : "0 0 2rem 2rem",
                                    }}
                                    src={getEmbeddedVimeoURL(module.video_url)}
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                ></iframe>
                            </Box>
                        )}

                        {/* Module number & status badge */}
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0.5rem 1rem",
                                backdropFilter: "blur(3px)",
                            }}
                        >
                            <Typography component="span" variant="body1Resp">
                                Module {module.module_number}
                            </Typography>
                            {getModuleStatusBadge(module.status)}
                        </Box>
                    </Box>

                    {/* Scrollable module content */}
                    <Box sx={{ padding: "1rem" }}>
                        {/* Title */}
                        <Typography component="h1" variant="pageTitle1Resp" my={2}>
                            {module.title}
                        </Typography>

                        {/* Content */}
                        {renderModuleContent(module.content)}

                        {/* Conditional "Next up" section */}
                        {!hideNextModule && (
                            <Box width="100%" mt={4} pb={4}>
                                <Divider />
                                <Box marginY={2}>
                                    {Object.keys(nextModule || {}).length > 0 && (
                                        <>
                                            <Typography
                                                component="h6"
                                                variant="pageTitle2SmallResp"
                                                sx={{
                                                    color: customTheme.palette.textVeryGood.main,
                                                }}
                                                gutterBottom
                                            >
                                                Next up:
                                            </Typography>
                                            <Typography
                                                component="p"
                                                variant="body1Resp"
                                                gutterBottom
                                            >
                                                Module {nextModule.module_number}:{" "}
                                                {nextModule.title}
                                            </Typography>
                                        </>
                                    )}
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginTop: "2rem",
                                            gap: "1rem",
                                        }}
                                    >
                                        <ButtonComp
                                            color="secondary"
                                            sx={{ flex: "1 1 125px" }}
                                            onClick={saveModule}
                                        >
                                            Save
                                        </ButtonComp>
                                        <ButtonComp
                                            sx={{ flex: "1 1 125px" }}
                                            onClick={continueModule}
                                        >
                                            Continue
                                        </ButtonComp>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </>
            )}
        </>
    );
};

export default Module;
