import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Backdrop, Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import ButtonComp from "../../../components/base_components/ButtonComp";

const SignupCompleted = ({ loginHandler, ...props }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [isLogginIn, setIsLogginIn] = useState(false);

    const handleLogin = () => {
        let mounted = true;
        setIsLogginIn(true);

        Promise.allSettled([loginHandler()])
            .then((responses) => {
                if (!mounted) return;

                if (responses[0].status === "fulfilled") {
                    enqueueSnackbar("You have successfully logged in", { variant: "info" });
                    navigate("/overview");
                } else {
                    enqueueSnackbar("Something went wrong. Couldn't log you in", {
                        variant: "error",
                    });
                    navigate("/login");
                }
            })
            .finally(() => {
                if (!mounted) return;

                setIsLogginIn(false);
            });
    };

    return (
        <>
            <Box
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: "translateY(-40px)",
                }}
            >
                <Typography component="h1" variant="pageTitle1" textAlign="center">
                    Congratulations! 🎉
                </Typography>

                <Typography component="p" variant="body1" textAlign="center" marginY={4}>
                    You have successfully registered a profile with ThreeSixty.me
                </Typography>

                <ButtonComp onClick={handleLogin}>Continue to credit profile</ButtonComp>
            </Box>

            {/* Loading backdrop */}
            <Backdrop open={isLogginIn}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="PageTitle2"
                            textAlign="center"
                            gutterBottom
                        >
                            Loggin In
                        </Typography>
                    </Grid>
                    <Grid item xs={12} marginTop={5}>
                        <CircularProgress size="5rem" />
                    </Grid>
                </Grid>
            </Backdrop>
        </>
    );
};

export default SignupCompleted;
