import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { getMonthName } from "../../../utils/generalHelper";
import customTheme from "../../../constants/Theme";

const filterOptions = ["All", "Good", "Bad"];

const MonthSummaryAccordion = ({ scoreEffects, ...props }) => {
    const [filter, setFilter] = useState(filterOptions[0]);
    const [groupedScoreEffects, setGroupedScoreEffects] = useState([]);

    useEffect(() => {
        if (scoreEffects?.length > 0) {
            let grouped = [];

            for (let i = 0; i < scoreEffects.length; i++) {
                const effect = scoreEffects[i];
                const effectDateStr = `${effect.year}${effect.month}`;

                let group = grouped?.find((g) => g.dateStr === effectDateStr);

                if (!group) {
                    grouped.push({
                        dateStr: effectDateStr,
                        monthName: getMonthName(effect.month),
                        effects: [effect],
                    });
                } else {
                    group.effects.push(effect);
                }
            }

            setGroupedScoreEffects(grouped.sort((a, b) => (a.dateStr > b.dateStr ? 1 : -1)));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterSelection = (e, selectedFilter) => {
        setFilter(selectedFilter);
    };

    const getFilteredEffects = (effects) => {
        return effects.filter(
            (effect) =>
                filter === "All" ||
                (filter === "Good" && effect.positive) ||
                (filter === "Bad" && !effect.positive)
        );
    };

    return (
        <Box sx={props.sx}>
            <Typography component="h2" variant="pageTitle2Small" textAlign="left">
                What Affected Your Score
            </Typography>

            <ToggleButtonGroup
                value={filter}
                onChange={handleFilterSelection}
                exclusive
                sx={{ margin: "1rem 0" }}
            >
                {filterOptions.map((option, i) => (
                    <ToggleButton key={i} value={option}>
                        {option}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>

            <Grid container spacing={2} justifyContent="space-evenly">
                {groupedScoreEffects.map((group, i) => (
                    <Grid item xs={12} md={6} lg={4} key={i}>
                        <Accordion defaultExpanded sx={{ width: "98%" }}>
                            <AccordionSummary expandIcon={<ExpandCircleDownIcon />}>
                                <Typography>{group.monthName}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {getFilteredEffects(group.effects).map((item, i) => (
                                        <React.Fragment key={i}>
                                            {i !== 0 && <ListItem divider />}
                                            <ListItem>
                                                <ListItemIcon>
                                                    <ArrowDropDownCircleIcon
                                                        sx={{
                                                            color: item.positive
                                                                ? customTheme.palette.brandedTeal
                                                                      .main
                                                                : customTheme.palette.primary.main,
                                                            transform: item.positive
                                                                ? "rotate(180deg)"
                                                                : "rotate(0deg)",
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText style={{ minWidth: "50px" }}>
                                                    {item.name}
                                                </ListItemText>
                                                <ListItemText
                                                    sx={{ textAlign: "right", color: "white" }}
                                                    primaryTypographyProps={{
                                                        variant: "body1Bold",
                                                    }}
                                                >
                                                    {item.description}
                                                </ListItemText>
                                            </ListItem>
                                        </React.Fragment>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default MonthSummaryAccordion;
