import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { normaliseString } from "../../../../utils/generalHelper";
import { getModuleStatusIcon } from "../../../../utils/smartCoachHelper";
import ButtonComp from "../../../../components/base_components/ButtonComp";

const TopicButton = ({
    title,
    duration,
    status,
    progress,
    moduleNum,
    onClickTopic,
    onClickModule,
    ...props
}) => {
    return (
        <Paper className="card" onClick={onClickTopic} sx={{ ...props.sx }}>
            {/* Title, duration & icons */}
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                }}
            >
                {/* Status icon */}
                <Box pr={1}>{getModuleStatusIcon(status)}</Box>

                {/* Title & duration */}
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography component="h6" variant="pageTitle2SmallResp">
                        {normaliseString(title)}
                    </Typography>
                    <Box
                        sx={{
                            minWidth: "50px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <AccessTimeIcon sx={{ fontSize: "1.2rem", opacity: 0.5 }} />
                        <Typography component="p" variant="pageBodySmall" fontWeight={300} ml={1}>
                            {duration} min
                        </Typography>
                    </Box>
                </Box>

                {/* Navigation icon */}
                <Box pl={1}>
                    <ExpandCircleDownIcon
                        sx={{
                            transform: "rotate(-90deg)",
                            fontSize: { xs: "1.25rem", sm: "1.5rem", xl: "2.1875rem" },
                        }}
                    />
                </Box>
            </Box>

            {/* Progress bar */}
            <Box width="100%">
                <LinearProgress variant="determinate" value={progress} sx={{ width: "100%" }} />
                <Typography component="p" variant="pageBodySmall" fontWeight={300} mt={0.5}>
                    {Math.round(progress)}% complete
                </Typography>
            </Box>

            {/* Module button */}
            {moduleNum > 0 && (
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1rem",
                    }}
                >
                    <ButtonComp
                        sx={{
                            alignSelf: "flex-end",
                            width: { xs: "100%", sm: "unset" },
                            minWidth: "120px",
                            padding: { xs: "0.5rem 0.75rem", sm: "0.75rem 1rem" },
                        }}
                        onClick={onClickModule}
                    >
                        {moduleNum === 1 ? "Start" : "Continue"} Module {moduleNum}
                    </ButtonComp>
                </Box>
            )}
        </Paper>
    );
};

export default TopicButton;
