import React, { useEffect, useState, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { debounce } from "@mui/material";

const AreaChartComponent = ({ chartConfig, includeDataLabels, hideEndPoints, ...props }) => {
    const chartRef = useRef(null);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const resizeHandler = () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener("resize", resizeHandler);

        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    useEffect(() => {
        let myChart = null;

        const data = {
            labels: chartConfig.data.map((d) => d.label),
            datasets: [
                {
                    data: chartConfig.data.map((d) => d.dataPoint),
                    borderColor: "#27D6D2",
                    backgroundColor: (context) => {
                        const gradient = context.chart.ctx.createLinearGradient(
                            0,
                            0,
                            0,
                            context.chart.height
                        );
                        gradient.addColorStop(0, "rgba(39, 214, 210, 0.7)"); // 90% opaque
                        gradient.addColorStop(0.9, "rgba(39, 214, 210, 0)"); // completely transparent
                        gradient.addColorStop(1, "rgba(39, 214, 210, 0)"); // completely transparent
                        return gradient;
                    },
                    borderWidth: 2,
                    pointBackgroundColor: "white",
                    pointRadius: !hideEndPoints ? 5 : displayDataPoint,
                    pointHoverRadius: 7,
                    tension: 0.5,
                    shadowOffsetX: 2,
                    shadowOffsetY: 2,
                    shadowBlur: 5,
                    shadowColor: "rgba(0, 0, 0, 0.3)",
                    fill: "origin",
                },
            ],
        };

        const plugins = includeDataLabels
            ? [ChartDataLabels, ...chartConfig.plugins]
            : chartConfig.plugins;

        const config = {
            type: "line",
            data: data,
            options: {
                ...chartConfig.options,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    datalabels: {
                        color: "white",
                        font: {
                            weight: "bold",
                        },
                        formatter: (value, context) => {
                            return context.chart.data.labels[context.dataIndex];
                        },
                        display: !hideEndPoints ? true : displayDataLabel,
                        align: "top",
                        ...chartConfig.options?.plugins?.datalabels,
                    },
                },
                scales: {
                    x: {
                        display: false, // Hides x-axis
                    },
                    y: {
                        beginAtZero: true,
                        display: false, // Hides y-axis
                        ...chartConfig.options?.plugins?.scales?.y,
                    },
                },
            },
            plugins: plugins,
        };

        const delayedCreateChart = debounce(() => {
            if (chartRef && chartRef.current) {
                if (window.myChart !== undefined) window.myChart.destroy();
                myChart = new Chart(chartRef.current, config);
            }
        }, 0);

        delayedCreateChart();

        return () => {
            if (myChart) {
                myChart.destroy();
            }
            delayedCreateChart.clear();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowSize.width]);

    const hideDataPointDetails = (context) => {
        const index = context.dataIndex;
        const label = context.chart?.config?.data?.labels[index];
        return (
            label?.toString().toLowerCase().includes("first") ||
            label?.toString().toLowerCase().includes("last")
        );
    };

    const displayDataPoint = (context) => {
        return hideDataPointDetails(context) ? 0 : 5;
    };

    const displayDataLabel = (context) => {
        return !hideDataPointDetails(context);
    };

    return (
        <div
            className="chart-container"
            style={{ position: "relative", width: "100%", height: "100%" }}
        >
            <canvas ref={chartRef} />
        </div>
    );
};

export default AreaChartComponent;
