import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import customTheme from "../../../constants/Theme";
import { normaliseString, formatDate, currencyFormatter } from "../../../utils/generalHelper";
import AccordionGroupComp from "../../../components/base_components/AccordionGroupComp";

const AccountsContainer = ({ accountGroups, ...props }) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            {accountGroups?.length > 0 &&
                accountGroups.map((group, i) => (
                    <Box key={i} sx={{ width: "100%", marginY: 2 }}>
                        <Box sx={{ width: "100%" }}>
                            {/* Group header */}
                            <Box
                                sx={{
                                    width: "100%",
                                    paddingY: "1rem",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    {group.groupStatusPositive ? (
                                        <VerifiedUserIcon
                                            fontSize="large"
                                            sx={{
                                                color: customTheme.palette.textGood.main,
                                                marginRight: { xs: "0.5rem", sm: "1rem" },
                                            }}
                                        />
                                    ) : (
                                        <ErrorIcon
                                            fontSize="large"
                                            sx={{
                                                color: customTheme.palette.textBad.main,
                                                marginRight: { xs: "0.5rem", sm: "1rem" },
                                            }}
                                        />
                                    )}
                                    <Typography component="span" variant="body1BoldResp">
                                        {normaliseString(group.groupName)}
                                    </Typography>
                                </Box>
                                <Typography component="span" variant="body1Resp">
                                    {currencyFormatter.format(group.groupInstallment || 0)} p/m
                                </Typography>
                            </Box>

                            {!(group.accounts?.length > 0) ? (
                                // Default empty state
                                group.emptyComp
                            ) : (
                                // Grouped account accordions
                                <AccordionGroupComp
                                    accordions={group.accounts.map((account, i) => {
                                        return {
                                            headerOverride: (
                                                <Box
                                                    sx={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography
                                                        component="span"
                                                        sx={{
                                                            fontSize: {
                                                                xs: "0.875rem !important",
                                                                sm: "1rem !important",
                                                            },
                                                            fontWeight: {
                                                                xs: "400 !important",
                                                                sm: "600 !important",
                                                            },
                                                        }}
                                                    >
                                                        {normaliseString(account.name)}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            marginRight: {
                                                                xs: "0.5rem",
                                                                lg: "1rem",
                                                            },
                                                            display: "flex",
                                                            flexDirection: {
                                                                xs: "column-reverse",
                                                                lg: "row",
                                                            },
                                                            alignItems: {
                                                                xs: "flex-end",
                                                                lg: "center",
                                                            },
                                                            justifyContent: {
                                                                xs: "center",
                                                                lg: "space-between",
                                                            },
                                                            minWidth: {
                                                                xs: "100px",
                                                                sm: "140px",
                                                                lg: "unset",
                                                            },
                                                        }}
                                                    >
                                                        {account.statusHeaderComp}

                                                        <Typography
                                                            component="span"
                                                            textAlign="right"
                                                            sx={{
                                                                whiteSpace: "nowrap",
                                                                fontSize: {
                                                                    xs: "0.8rem !important",
                                                                    sm: "1rem !important",
                                                                },
                                                                fontWeight: {
                                                                    xs: "400 !important",
                                                                    sm: "600 !important",
                                                                },
                                                                minWidth: {
                                                                    xs: "unset",
                                                                    lg: "140px",
                                                                },
                                                                marginBottom: {
                                                                    xs: "0.5rem",
                                                                    lg: 0,
                                                                },
                                                            }}
                                                        >
                                                            {currencyFormatter.format(
                                                                account.installment_amount || 0
                                                            )}{" "}
                                                            p/m
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            ),
                                            contentOverride: (
                                                <Grid
                                                    container
                                                    padding={{ xs: "0.5rem", sm: "1rem" }}
                                                >
                                                    {/* Account details */}
                                                    <Grid item xs={12} lg={7} container>
                                                        {/* Date opened */}
                                                        <Grid item xs={6} marginY={1}>
                                                            <Typography
                                                                component="h6"
                                                                variant="body1Resp"
                                                            >
                                                                Opened
                                                            </Typography>
                                                            <Typography
                                                                component="p"
                                                                variant="body1BoldResp"
                                                            >
                                                                {formatDate(
                                                                    account.date_account_opened
                                                                ) || "No date set"}
                                                            </Typography>
                                                        </Grid>

                                                        {/* Account number */}
                                                        <Grid item xs={6} marginY={1}>
                                                            <Typography
                                                                component="h6"
                                                                variant="body1Resp"
                                                            >
                                                                Account Number
                                                            </Typography>
                                                            <Typography
                                                                component="p"
                                                                variant="body1BoldResp"
                                                            >
                                                                {account.account_no}
                                                            </Typography>
                                                        </Grid>

                                                        {/* Date last paid */}
                                                        <Grid item xs={6} marginY={1}>
                                                            <Typography
                                                                component="h6"
                                                                variant="body1Resp"
                                                            >
                                                                Last Paid
                                                            </Typography>
                                                            <Typography
                                                                component="p"
                                                                variant="body1BoldResp"
                                                            >
                                                                {formatDate(account.last_payment) ||
                                                                    "No date set"}
                                                            </Typography>
                                                        </Grid>

                                                        {/* Account status */}
                                                        <Grid item xs={6} marginY={1}>
                                                            <Typography
                                                                component="h6"
                                                                variant="body1Resp"
                                                            >
                                                                Account Status
                                                            </Typography>
                                                            {account.statusComp}
                                                        </Grid>

                                                        {/* Amount in arrears */}
                                                        <Grid item xs={6} marginY={1}>
                                                            <Typography
                                                                component="h6"
                                                                variant="body1Resp"
                                                            >
                                                                Arrears
                                                            </Typography>
                                                            <Typography
                                                                component="p"
                                                                variant="body1BoldResp"
                                                            >
                                                                {currencyFormatter.format(
                                                                    account.amount_overdue || 0
                                                                )}
                                                            </Typography>
                                                        </Grid>

                                                        {/* Current installment */}
                                                        <Grid item xs={6} marginY={1}>
                                                            <Typography
                                                                component="h6"
                                                                variant="body1Resp"
                                                            >
                                                                Current Installment
                                                            </Typography>
                                                            <Typography
                                                                component="p"
                                                                variant="body1BoldResp"
                                                            >
                                                                {currencyFormatter.format(
                                                                    account.installment_amount || 0
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    {/* Payments details */}
                                                    <Grid item xs={12} lg={5} container>
                                                        {/* Credit balance & limit */}
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            container
                                                            justifyContent="space-between"
                                                            marginY={1}
                                                        >
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    component="h6"
                                                                    variant="body1Resp"
                                                                >
                                                                    Current Balance
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    component="h6"
                                                                    variant="body1Resp"
                                                                    textAlign="right"
                                                                >
                                                                    Credit Limit
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    color="brandedBlue"
                                                                    value={account.creditPerc}
                                                                    sx={{ height: "0.5625rem" }}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    component="p"
                                                                    variant="body1BoldResp"
                                                                >
                                                                    {currencyFormatter.format(
                                                                        account.current_balance || 0
                                                                    )}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    component="p"
                                                                    variant="body1BoldResp"
                                                                    textAlign="right"
                                                                >
                                                                    {currencyFormatter.format(
                                                                        account.opening_balance_credit_limit ||
                                                                            0
                                                                    )}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        {/* History for 6months */}
                                                        <Grid item xs={12} container marginY={1}>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                container
                                                                alignItems="end"
                                                            >
                                                                <Typography
                                                                    component="h6"
                                                                    variant="body1Resp"
                                                                >
                                                                    Payment History (6 months)
                                                                </Typography>
                                                            </Grid>

                                                            {account.paymentHistoryShort}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ),
                                        };
                                    })}
                                />
                            )}
                        </Box>
                    </Box>
                ))}
        </Box>
    );
};

export default AccountsContainer;
