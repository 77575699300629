import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginSignupContainer from "../../components/templates/LoginSignupContainer";
import ResetRequestForm from "./reset_forms/ResetRequestForm";
import ResetOtpForm from "./reset_forms/ResetOtpForm";
import NewPasswordForm from "./reset_forms/NewPasswordForm";

const ForgotPasswordPage = () => {
    const navigate = useNavigate();

    const [otp, setOtp] = useState("");
    const [formIndex, setFormIndex] = useState(0);

    const forms = [
        {
            title: null,
            heading: "Forgot Password",
            handleBack: () => {
                navigate(-1);
            },
            component: <ResetRequestForm submitCallback={() => setFormIndex(formIndex + 1)} />,
        },
        {
            title: null,
            heading: "OTP Verification",
            handleBack: () => {
                setFormIndex(formIndex - 1);
            },
            component: (
                <ResetOtpForm
                    setCompleteOTP={setOtp}
                    submitCallback={() => setFormIndex(formIndex + 1)}
                />
            ),
        },
        {
            title: null,
            heading: "New Password",
            handleBack: () => {
                setFormIndex(formIndex - 1);
            },
            component: <NewPasswordForm otp={otp} />,
        },
    ];

    return (
        <LoginSignupContainer
            pageTitle={forms[formIndex].title}
            pageHeading={forms[formIndex].heading}
            overwriteHandleBack={forms[formIndex].handleBack}
        >
            {forms[formIndex].component}
        </LoginSignupContainer>
    );
};

export default ForgotPasswordPage;
