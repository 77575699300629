import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import LegalDocWrapper from "../../components/wrappers/LegalDocWrapper";

const SupportTC = ({ backToContactUs, ...props }) => {
    const theme = useTheme();

    return (
        <LegalDocWrapper backHandler={backToContactUs}>
            {/* Title */}
            <Typography component="h3" variant="paperHeading" mt={4} mb={2}>
                Support Ticket
            </Typography>

            {/* Sub-Title */}
            <Typography
                component="h5"
                variant="paperSubHeading"
                mb={4}
                sx={{ color: theme.palette.brandedTeal.main }}
            >
                Terms and Conditions
            </Typography>

            {/* Intro paragraph */}
            <Typography component="p" variant="paperBody" textAlign="justify">
                These Product Specific Terms and Conditions ("Terms") govern the use of the Smart
                Solutions Debt Review service provided by ThreeSixty.me operated by CreditSmart
                Financial Services (Pty) Ltd. By lodging this inquiry, you agree to comply with
                these Terms.
            </Typography>

            <br />

            {/* Numbered list terms */}
            <ol>
                {/* Introduction */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Introduction
                    </Typography>
                    <ol>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                These Support Ticket Terms and Conditions ("Terms") govern the use
                                of support services provided by ThreeSixty.me ("Platform") in
                                relation to the services provided by the platform.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                By submitting a support ticket, you agree to comply with these
                                Terms.
                            </Typography>
                        </li>
                    </ol>
                </li>
                {/* Scope */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Scope of Support
                    </Typography>
                    <ol>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                ThreeSixty.me agrees to provide technical support to address issues
                                related to the functionality and usage of the services provided by
                                the platform.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                Support will be provided during regular business hours, excluding
                                public holidays.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                Support hours are as follows:
                            </Typography>
                            <Box
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: "auto auto",
                                    gap: 1,
                                    mt: 1,
                                    "@media (max-width: 350px)": {
                                        "& span": {
                                            fontSize: "11pt !important",
                                            fontWeight: 300,
                                        },
                                    },
                                }}
                            >
                                <Typography component="span" variant="paperBody">
                                    Monday–Thursday:
                                </Typography>
                                <Typography component="span" variant="paperBody" textAlign="right">
                                    07:30–16:00
                                </Typography>
                                <Typography component="span" variant="paperBody">
                                    Friday:
                                </Typography>
                                <Typography component="span" variant="paperBody" textAlign="right">
                                    07:30–15:00
                                </Typography>
                            </Box>
                        </li>
                    </ol>
                </li>
                {/* Submission */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Submission of Support Tickets
                    </Typography>
                    <ol>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                Support requests must be submitted through the Help Centre which can
                                be accessed on the ThreeSixty.me platform.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                Each support ticket must include relevant information such as the
                                nature of the issue, any error messages received, and steps to
                                reproduce the problem.
                            </Typography>
                        </li>
                    </ol>
                </li>
                {/* Response Time */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Response Time
                    </Typography>
                    <ol>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                ThreeSixty.me will make reasonable efforts to respond to support
                                tickets in a timely manner.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                Response times may vary depending on the severity and complexity of
                                the issue.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                ThreeSixty.me will provide an estimated resolution time where
                                possible.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                The general response time per ticket will be within 48 hours of the
                                ticket being generated.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                Should you not receive a response within the designated time,
                                another ticket should be logged within 5 days after the initial
                                ticket was logged.
                            </Typography>
                        </li>
                    </ol>
                </li>
                {/* Limitations */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Support Limitations
                    </Typography>
                    <ol>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                Support services do not include assistance with issues caused by:
                            </Typography>
                            <ol>
                                <li>
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Modifications or alterations to the services not performed
                                        by ThreeSixty.me;
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Use of the services in a manner inconsistent with
                                        documentation or instructions provided by ThreeSixty.me;
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Third-party software or hardware not provided or approved by
                                        ThreeSixty.me; and
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        component="p"
                                        variant="paperBody"
                                        textAlign="justify"
                                    >
                                        Force majeure events or other circumstances beyond
                                        ThreeSixty.me control.
                                    </Typography>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                ThreeSixty.me is not a credit bureau and is therefore not authorised
                                to resolve disputes raised by user's regarding any discrepancies in
                                their credit reports amongst others.
                            </Typography>
                        </li>
                        <li>
                            <Typography component="p" variant="paperBody" textAlign="justify">
                                Any issues in relation to the scope and responsibilities of a credit
                                bureau as per clause 5.2 can, however, be logged with the relevant
                                credit bureau by ThreeSixty.me.
                            </Typography>
                        </li>
                    </ol>
                </li>
                {/* Resolution of Issues */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Resolution of Issues
                    </Typography>

                    <br />

                    <Typography component="p" variant="paperBody" textAlign="justify">
                        ThreeSixty.me will use reasonable efforts to resolve support issues in
                        accordance with industry standards. However, ThreeSixty.me does not
                        guarantee that all issues will be resolved or that the services will be
                        error-free.
                    </Typography>
                </li>
                {/* Confidentiality */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Confidentiality
                    </Typography>

                    <br />

                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Any information disclosed in connection with support services, including but
                        not limited to technical information, user data, and communications, shall
                        be treated as confidential by both parties and shall not be disclosed to
                        third parties without the express consent of the disclosing party.
                    </Typography>
                </li>
                {/* Modification of Terms */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Modification of Terms
                    </Typography>

                    <br />

                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Platform reserves the right to modify or update these Terms at any time,
                        with or without notice. It is your responsibility to review these Terms
                        periodically for changes. Continued use of support services after any
                        modifications indicates acceptance of the updated Terms.
                    </Typography>
                </li>
                {/* Governing Law */}
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify">
                        Governing Law
                    </Typography>

                    <br />

                    <Typography component="p" variant="paperBody" textAlign="justify">
                        These Terms shall be governed by and construed in accordance with the laws
                        of the Republic of South Africa.
                    </Typography>
                </li>
            </ol>

            <br />

            {/* Closing paragraph */}
            <Typography component="p" variant="paperBody" textAlign="justify">
                By submitting a support ticket, you acknowledge that you have read, understood, and
                agreed to these Terms. If you do not agree with any part of these Terms, do not
                submit a support ticket.
            </Typography>
        </LegalDocWrapper>
    );
};

export default SupportTC;
