import BaseController from "./BaseController";

class Controller extends BaseController {
    constructor() {
        super();
        this.ProfileURL = "/profile";
        this.UpdateMobileURL = "/profile/update-mobile";
        this.UpdateMobileOtpURL = "/profile/update-mobile/otp";
        this.ChangePassword = "/profile/change-password";
    }

    getProfile = () => {
        return this.sendRequest("GET", this.ProfileURL);
    };

    updateProfile = (body) => {
        return this.sendRequest("POST", this.ProfileURL, null, body);
    };

    updateMobile = (body) => {
        return this.sendRequest("POST", this.UpdateMobileURL, null, body);
    };

    verifyMobileOTP = (body) => {
        return this.sendRequest("POST", this.UpdateMobileOtpURL, null, body);
    };

    changePassword = (body) => {
        return this.sendRequest("POST", this.ChangePassword, null, body);
    };
}

const UserProfileController = new Controller();

export default UserProfileController;
