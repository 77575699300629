import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Backdrop, Box, CircularProgress, Grid, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as Schemas from "../../../utils/validationHelper";
import { getErrorMessage } from "../../../utils/errorHelper";
import CustomTheme from "../../../constants/Theme";
import UserProfileController from "../../../controllers/UserProfileController";
import BasePage from "../../BasePage";
import InputComp from "../../../components/base_components/InputComp";
import ButtonComp from "../../../components/base_components/ButtonComp";
import ErrorMsgComp from "../../../components/base_components/ErrorMsgComp";
import PageBackgroundContainer from "../../../components/templates/PageBackgroundContainer";
import BackgroundImg from "../../../assets/images/background_5_grouped.png";
import SimplePopup from "../../../components/templates/SimplePopup";

const emptyFormValues = {
    name: "",
    surname: "",
    id_number: "",
    mobile: "",
    email: "",
};

const PersonalInformationForm = ({ ...props }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(true);
    const [initValues, setInitValues] = useState(emptyFormValues);
    const [errorMsg, setErrorMsg] = useState("");

    const otpLength = 4;
    const otpSchema = Schemas.getSchemaForOTP(otpLength);

    const [otp, setOtp] = useState("");
    const [otpToken, setOtpToken] = useState("");
    const [isValidOTP, setIsValidOTP] = useState(false);
    const [isUpdatingMobile, setIsUpdatingMobile] = useState(false);
    const [isSendingOTP, setIsSendingOTP] = useState(false);
    const [isSubmittingOTP, setIsSubmittingOTP] = useState(false);
    const [errorMsgOTP, setErrorMsgOTP] = useState("");

    const [showResultPopup, setShowResultPopup] = useState(false);
    const [hasErredOTP, setHasErredOTP] = useState(false);

    const validations = Yup.object({
        name: Schemas.getSchemaForRequiredText(),
        surname: Schemas.getSchemaForRequiredText(),
        mobile: Schemas.getSchemaForTelNumber(),
        email: Schemas.getSchemaEmail(),
    });

    useEffect(() => {
        let mounted = true;

        Promise.allSettled([getUserDetails(mounted)]).then(() => {
            setIsLoading(false);
        });

        return () => {
            mounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserDetails = (mounted) => {
        return new Promise(async (res, rej) => {
            try {
                const profileResponse = await UserProfileController.getProfile();

                if (!profileResponse || Object.keys(profileResponse).length <= 0) {
                    setErrorMsg(
                        "No user details were received from the server. Please try again later"
                    );
                    rej();
                    return;
                }

                if (mounted) {
                    setInitValues(profileResponse);
                }

                res(profileResponse);
            } catch (error) {
                const errMsg = getErrorMessage(error);

                // Check if request was aborted
                if (errMsg === null) return;

                enqueueSnackbar(errMsg, { variant: "error" });
                rej(errMsg);
            }
        });
    };

    const updateMobile = async (values, actions) => {
        try {
            const requestBody = {
                new_mobile: values.mobile,
            };

            // Update mobile and send OTP to new number
            setIsSendingOTP(true);
            setIsUpdatingMobile(true);

            const updateResponse = await UserProfileController.updateMobile(requestBody);

            if (updateResponse?.message?.toLowerCase() !== "success") {
                // Mobile update failed, reset to initial value
                values.mobile = initValues.mobile;

                setErrorMsg("New mobile number couldn't be updated. Please try again");
                setIsUpdatingMobile(false);
            } else {
                setOtpToken(updateResponse.otp_token);
            }

            setInitValues(values);
            actions.resetForm();
            actions.setSubmitting(false);

            setIsSendingOTP(false);
        } catch (error) {
            const errMsg = getErrorMessage(error);

            // Check if request was aborted
            if (errMsg === null) return;

            enqueueSnackbar(errMsg, { variant: "error" });
        }
    };

    const handleSubmit = async (values, actions) => {
        try {
            if (!values.income) values.income = 0;

            await UserProfileController.updateProfile(values);

            if (values.mobile !== initValues.mobile) {
                updateMobile(values, actions);
            } else {
                setInitValues(values);
                actions.resetForm();
                actions.setSubmitting(false);
            }
        } catch (error) {
            const errMsg = getErrorMessage(error);

            // Check if request was aborted
            if (errMsg === null) return;

            enqueueSnackbar(errMsg, { variant: "error" });
        }
    };

    const handleSubmitOTP = async (event) => {
        if (event.preventDefault) event.preventDefault();

        // Submit the OTP for verification
        try {
            setHasErredOTP(false);
            setErrorMsgOTP("");
            setIsSubmittingOTP(true);

            const requestBody = {
                otp: otp,
                otp_token: otpToken,
            };

            const otpResponse = await UserProfileController.verifyMobileOTP(requestBody);

            if (otpResponse?.message?.toLowerCase() !== "success") {
                setHasErredOTP(true);
                setErrorMsgOTP(
                    otpResponse?.message || "No response received from server. Please try again."
                );
            }

            setShowResultPopup(true);
        } catch (error) {
            const errMsg = getErrorMessage(error);

            // Check if request was aborted
            if (errMsg === null) return;

            enqueueSnackbar(errMsg, { variant: "error" });
        } finally {
            setOtp("");
            setIsUpdatingMobile(false);
            setIsSubmittingOTP(false);
        }
    };

    const handleOTPChange = (newValue) => {
        setOtp(newValue);
    };

    const handleOTPComplete = (value) => {
        otpSchema
            .isValid(value)
            .then((valid) => setIsValidOTP(valid))
            .catch((err) => setIsValidOTP(false));
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleResultPopupClose = () => {
        setShowResultPopup(false);
    };

    const getDisabledState = (formIsSubmmitting) => {
        return formIsSubmmitting || isLoading || errorMsg?.length > 0;
    };

    return (
        <>
            <BasePage>
                <PageBackgroundContainer handleBack={handleBack} backgroundImg={BackgroundImg}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingBottom: { xs: "5rem", sm: 0 },
                        }}
                    >
                        {isLoading && (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography component="h1" variant="pageTitle1" marginBottom={5}>
                                    Loading Your Information
                                </Typography>
                                <CircularProgress size="5rem" />
                            </Box>
                        )}

                        {!isLoading && (
                            <Box
                                sx={{
                                    width: "100%",
                                    maxWidth: "800px",
                                }}
                            >
                                {/* Page title */}
                                <Typography component="h1" variant="pageTitle2" marginBottom={5}>
                                    Personal Information
                                </Typography>

                                {/* Form contents */}
                                <Formik
                                    initialValues={initValues}
                                    enableReinitialize
                                    validationSchema={validations}
                                    onSubmit={handleSubmit}
                                    validateOnBlur={false}
                                >
                                    {({
                                        values,
                                        isSubmitting,
                                        dirty,
                                        touched,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                    }) => (
                                        <Form noValidate>
                                            <Grid container spacing={2}>
                                                {/* First name input */}
                                                <Grid item xs={12}>
                                                    <InputComp
                                                        labelText="First name"
                                                        type="text"
                                                        id="name"
                                                        required
                                                        autoComplete="first name"
                                                        value={values.name}
                                                        disabled={getDisabledState(isSubmitting)}
                                                        touched={touched}
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>

                                                {/* Last name input */}
                                                <Grid item xs={12}>
                                                    <InputComp
                                                        labelText="Surname"
                                                        type="text"
                                                        id="surname"
                                                        required
                                                        autoComplete="last name"
                                                        value={values.surname}
                                                        disabled={getDisabledState(isSubmitting)}
                                                        touched={touched}
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>

                                                {/* ID number readonly field */}
                                                <Grid item xs={12}>
                                                    <InputComp
                                                        labelText="ID Number"
                                                        type="text"
                                                        id="id_number"
                                                        readOnly
                                                        disabled
                                                        value={values.id_number}
                                                    />
                                                </Grid>

                                                {/* Mobile number input */}
                                                <Grid item xs={12}>
                                                    <InputComp
                                                        labelText="Mobile number"
                                                        type="tel"
                                                        id="mobile"
                                                        required
                                                        autoComplete="tel"
                                                        value={values.mobile}
                                                        disabled={getDisabledState(isSubmitting)}
                                                        touched={touched}
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        toolTip={{
                                                            show: true,
                                                            title: "Verification Required",
                                                            message:
                                                                "To edit your mobile number a verification process needs to be completed",
                                                        }}
                                                    />
                                                </Grid>

                                                {/* Email input */}
                                                <Grid item xs={12}>
                                                    <InputComp
                                                        labelText="Email Address"
                                                        type="email"
                                                        id="email"
                                                        required
                                                        autoComplete="email"
                                                        value={values.email}
                                                        disabled={getDisabledState(isSubmitting)}
                                                        touched={touched}
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        // toolTip={{
                                                        //     show: true,
                                                        //     title: "Verification Required",
                                                        //     message:
                                                        //         "To edit your email address a verification process needs to be completed",
                                                        // }}
                                                    />
                                                </Grid>

                                                {/* Submit button */}
                                                {dirty && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        container
                                                        spacing={2}
                                                        marginY={4}
                                                    >
                                                        <Grid item xs={6}>
                                                            <ButtonComp
                                                                type="reset"
                                                                color="secondary"
                                                                isLoading={getDisabledState(
                                                                    isSubmitting
                                                                )}
                                                            >
                                                                Cancel
                                                            </ButtonComp>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <ButtonComp
                                                                type="submit"
                                                                isLoading={getDisabledState(
                                                                    isSubmitting
                                                                )}
                                                            >
                                                                Save
                                                            </ButtonComp>
                                                        </Grid>
                                                    </Grid>
                                                )}

                                                {/* Error message */}
                                                {errorMsg && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        container
                                                        justifyContent="center"
                                                    >
                                                        <ErrorMsgComp errorMessage={errorMsg} />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        )}
                    </Box>
                </PageBackgroundContainer>
            </BasePage>

            {/* OTP backdrop */}
            <Backdrop open={isUpdatingMobile}>
                {isSendingOTP && (
                    <Grid container direction="column" alignItems="center">
                        <Grid item xs={12}>
                            <Typography component="h2" variant="PageTitle1">
                                Sending OTP
                            </Typography>
                        </Grid>
                        <Grid item xs={12} marginTop={5}>
                            <CircularProgress size="5rem" />
                        </Grid>
                    </Grid>
                )}
                {!isSendingOTP && (
                    <Grid
                        container
                        spacing={2}
                        component="form"
                        onSubmit={handleSubmitOTP}
                        noValidate
                    >
                        <Grid item xs={12}>
                            <Typography
                                component="h2"
                                variant="PageTitle1"
                                textAlign="center"
                                marginY={2}
                            >
                                Verify OTP
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="p" variant="body1" textAlign="center">
                                Please enter the 4 digit code we just sent to your updated mobile
                                number
                            </Typography>
                        </Grid>

                        <Grid item xs={12} marginY={3} container justifyContent="center">
                            <MuiOtpInput
                                id="otp"
                                autoFocus
                                length={otpLength}
                                value={otp}
                                disabled={isSubmittingOTP}
                                validateChar={Schemas.validateOtpChars}
                                onChange={handleOTPChange}
                                onComplete={handleOTPComplete}
                                sx={{ maxWidth: "284px" }}
                                TextFieldsProps={{ inputProps: { inputMode: "numeric" } }}
                            />
                        </Grid>

                        <Grid item xs={12} container justifyContent="center">
                            <ButtonComp
                                type="submit"
                                disabled={isSubmittingOTP || !isValidOTP}
                                isLoading={isSubmittingOTP}
                            >
                                Verify
                            </ButtonComp>
                        </Grid>

                        {/* Error message */}
                        {errorMsgOTP && (
                            <Grid item xs={12} container justifyContent="center">
                                <ErrorMsgComp errorMessage={errorMsgOTP} />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Backdrop>

            {/* OTP result popup */}
            <SimplePopup
                showPopup={showResultPopup}
                handleClose={handleResultPopupClose}
                titleComp={
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "4.6875rem",
                            background: hasErredOTP
                                ? CustomTheme.palette.textBad.background
                                : CustomTheme.palette.textVeryGood.background,
                        }}
                    >
                        {hasErredOTP ? (
                            <ErrorIcon
                                fontSize="large"
                                sx={{
                                    color: CustomTheme.palette.textBad.main,
                                    margin: "0.75363rem",
                                }}
                            />
                        ) : (
                            <VerifiedUserIcon
                                fontSize="large"
                                sx={{
                                    color: CustomTheme.palette.textVeryGood.main,
                                    margin: "0.75363rem",
                                }}
                            />
                        )}
                        <Typography
                            component="h5"
                            variant="popupTitle"
                            sx={{
                                color: hasErredOTP
                                    ? CustomTheme.palette.textBad.main
                                    : CustomTheme.palette.textVeryGood.main,
                            }}
                        >
                            {hasErredOTP ? "Validation Error" : "Success"}
                        </Typography>
                    </Box>
                }
                contentComp={
                    <Typography component="h6" variant="popupContent">
                        {hasErredOTP
                            ? "Sorry, we could not validate your mobile number."
                            : "Your mobile number has successfully been updated."}
                    </Typography>
                }
                actionText="Okay"
            />
        </>
    );
};

export default PersonalInformationForm;
