import { Typography } from "@mui/material";

const ErrorMsgComp = ({ errorMessage, ...props }) => {
    return (
        <Typography {...props} variant="errorMessage">
            {errorMessage}
        </Typography>
    );
};

export default ErrorMsgComp;
