import * as React from "react";
import { Box, Paper } from "@mui/material";
import Footer from "../footer/Footer";
import CustomAppBar from "../custom_app_bar/CustomAppBar";

function LegalDocWrapper({ backHandler, ...props }) {
    return (
        <Box>
            {/* Login app bar */}
            <CustomAppBar />

            {/* Legal doc content */}
            <Paper
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "0",
                    padding: "5rem 1rem",
                }}
            >
                <Paper elevation={0} sx={{ alignSelf: "center", maxWidth: "800px" }}>
                    {props.children}
                </Paper>
            </Paper>

            {/* Standard footer */}
            <Footer />
        </Box>
    );
}

export default LegalDocWrapper;
