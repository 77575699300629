import { Box, Card, CardContent, Divider, Grid, Paper, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import customTheme from "../../../../constants/Theme";
import { currencyFormatter, roundNum } from "../../../../utils/generalHelper";
import ButtonComp from "../../../../components/base_components/ButtonComp";
import LinkComp from "../../../../components/base_components/LinkComp";

const DebtReviewTab = ({ callToAction, details, ...props }) => {
    return (
        <Box>
            {/* Heading & call to action */}
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "2rem 0",
                }}
            >
                <Typography component="h2" variant="pageTitle2" mr={1}>
                    Debt Review
                </Typography>
                <ButtonComp
                    onClick={() => {
                        callToAction("debt_review");
                    }}
                    sx={{
                        minWidth: "120px",
                        width: "40%",
                        display: { xs: "none", md: "inline-flex" },
                    }}
                >
                    Call me
                </ButtonComp>
            </Box>

            {/* Tab content conditionally split into two columns */}
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "flex-start",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: { xs: "center", md: "space-between" },
                    gap: "1rem",
                }}
            >
                {/* Video & reduction percentage */}
                <Box
                    sx={{
                        flex: "1 1 60%",
                        maxWidth: { xs: "100%", md: "1024px" },
                    }}
                >
                    <Box sx={{ aspectRatio: 16 / 9 }}>
                        {/* Video container */}
                        <iframe
                            title="Debt review video"
                            width="100%"
                            height="100%"
                            style={{ border: "none", borderRadius: "2rem" }}
                            src="https://player.vimeo.com/video/982428147"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        ></iframe>
                    </Box>

                    <Typography component="h6" variant="body1BoldResp" mt={2}>
                        Want to drastically reduce your monthly debt instalment?
                    </Typography>

                    <Card
                        sx={{
                            marginY: "1rem",
                            borderRadius: "1rem",
                            backgroundColor: `${customTheme.palette.brandedTeal.main}0d`,
                            padding: "0.5rem 0",
                            width: "100%",
                        }}
                    >
                        <CardContent
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            {/* Reduction description */}
                            <Box
                                sx={{
                                    height: "100%",
                                    marginRight: "1rem",
                                }}
                            >
                                <Typography component="span" variant="body1Resp">
                                    Based on your current credit data, your current debt installment
                                    can be reduced by
                                </Typography>
                            </Box>

                            {/* Percentage box */}
                            <Box
                                sx={{
                                    width: "150px",
                                    minWidth: { xs: "80px", md: "100px" },
                                    aspectRatio: 1,
                                    borderRadius: "50%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: `${customTheme.palette.brandedTeal.main}1a`,
                                }}
                            >
                                <Typography
                                    component="span"
                                    variant="pageTitleLarge"
                                    textAlign="center"
                                    color={customTheme.palette.brandedBlue.main}
                                >
                                    {roundNum(details?.percentage_reduction, 1) || "XX"}%
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>

                {/* Reduction table & info bubble */}
                <Box
                    sx={{
                        flex: "1 1",
                        minWidth: { xs: "unset", md: "400px" },
                        maxWidth: "600px",
                    }}
                >
                    {/* Reduced table */}
                    <Card
                        sx={{
                            marginY: "1rem",
                            borderRadius: "1rem",
                            backgroundColor: `${customTheme.palette.brandedTeal.main}0d`,
                            padding: "1rem 0.5rem",
                            width: "100%",
                        }}
                    >
                        <Grid container spacing={1} alignItems="center">
                            {/* Header row */}
                            <Grid item xs={8} container justifyContent="end">
                                <Typography
                                    component="p"
                                    variant="body1SmallBoldResp"
                                    textAlign="end"
                                >
                                    Current
                                </Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="end">
                                <Typography
                                    component="p"
                                    variant="body1SmallBoldResp"
                                    textAlign="end"
                                >
                                    Adjusted
                                </Typography>
                            </Grid>

                            {/* Unsecured instalments row */}
                            <Grid item xs={5}>
                                <Typography
                                    component="p"
                                    variant="body1SmallBoldResp"
                                    textAlign="start"
                                >
                                    Unsecured
                                </Typography>
                            </Grid>
                            <Grid item xs={3} container justifyContent="end">
                                <Typography component="p" variant="body1SmallResp" textAlign="end">
                                    {currencyFormatter.format(
                                        details?.current_installment?.unsecured || 0
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="end">
                                <Typography
                                    component="p"
                                    variant="body1SmallBoldResp"
                                    textAlign="end"
                                >
                                    {currencyFormatter.format(
                                        details?.adjusted_installment?.unsecured || 0
                                    )}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider sx={{ margin: "1rem 0" }} />
                            </Grid>

                            {/* Vehicles instalments row */}
                            <Grid item xs={5}>
                                <Typography
                                    component="p"
                                    variant="body1SmallBoldResp"
                                    textAlign="start"
                                >
                                    Vehicles
                                </Typography>
                            </Grid>
                            <Grid item xs={3} container justifyContent="end">
                                <Typography component="p" variant="body1SmallResp" textAlign="end">
                                    {currencyFormatter.format(
                                        details?.current_installment?.vehicles || 0
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="end">
                                <Typography
                                    component="p"
                                    variant="body1SmallBoldResp"
                                    textAlign="end"
                                >
                                    {currencyFormatter.format(
                                        details?.adjusted_installment?.vehicles || 0
                                    )}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider sx={{ margin: "1rem 0" }} />
                            </Grid>

                            {/* Bond instalments row */}
                            <Grid item xs={5}>
                                <Typography
                                    component="p"
                                    variant="body1SmallBoldResp"
                                    textAlign="start"
                                >
                                    Bond
                                </Typography>
                            </Grid>
                            <Grid item xs={3} container justifyContent="end">
                                <Typography component="p" variant="body1SmallResp" textAlign="end">
                                    {currencyFormatter.format(
                                        details?.current_installment?.bond || 0
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="end">
                                <Typography
                                    component="p"
                                    variant="body1SmallBoldResp"
                                    textAlign="end"
                                >
                                    {currencyFormatter.format(
                                        details?.adjusted_installment?.bond || 0
                                    )}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider
                                    sx={{
                                        margin: "1rem 0",
                                        borderColor: customTheme.palette.brandedBlue.main,
                                    }}
                                />
                            </Grid>

                            {/* Totals row */}
                            <Grid item xs={9}>
                                <Typography
                                    component="p"
                                    variant="body1SmallResp"
                                    textAlign="start"
                                >
                                    Monthly cash flow relief:
                                </Typography>
                            </Grid>
                            <Grid item xs={3} container justifyContent="end">
                                <Typography
                                    component="p"
                                    variant="body1SmallBoldResp"
                                    textAlign="end"
                                >
                                    {currencyFormatter.format(
                                        details?.monthly_cashflow_relief || 0
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>

                    {/* Information bubble */}
                    <Box width="100%" marginY={2}>
                        <Paper className="infoBubble">
                            <StarIcon color="brandedBlue" />
                            <Typography component="span" variant="body1Resp" ml={1}>
                                You'll pay {roundNum(details?.percentage_reduction)}% less on your
                                current debt instalments!
                            </Typography>
                        </Paper>
                    </Box>
                </Box>
            </Box>

            {/* Call to action for mobile screens */}
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <ButtonComp
                    onClick={() => {
                        callToAction("debt_review");
                    }}
                    sx={{
                        width: "200px",
                        display: { xs: "inline-flex", md: "none" },
                    }}
                >
                    Call me
                </ButtonComp>
            </Box>

            <Divider sx={{ margin: "1rem 0" }} />

            {/* Tab T&C's */}
            <Box mt={4} component="span" sx={{ display: "flex", width: "fit-content" }}>
                <Typography variant="body2" component="span">
                    By clicking the Call Me button you agree to the
                    <LinkComp
                        href="/legal/debt-review"
                        sx={{ marginLeft: "5px" }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms and Conditions
                    </LinkComp>
                </Typography>
            </Box>
        </Box>
    );
};

export default DebtReviewTab;
