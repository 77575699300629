import { Card, CardContent, Typography } from "@mui/material";
import customTheme from "../../constants/Theme";
import ButtonComp from "../base_components/ButtonComp";

const ImageCardComp = ({
    cardTitle,
    cardText,
    actionText,
    actionIcon,
    handleAction,
    backgroundImg,
    darkenBackground,
    ...props
}) => {
    return (
        <Card
            {...props}
            sx={{
                maxWidth: "400px",
                borderRadius: "2rem",
                backgroundImage: `linear-gradient(${customTheme.palette.background.default}${
                    darkenBackground ? "80" : "66"
                }, ${customTheme.palette.background.default}${
                    darkenBackground ? "b3" : "99"
                }), url("${backgroundImg}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                ...props.sx,
            }}
        >
            <CardContent
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Typography component="h6" variant="pageTitle2SmallResp">
                    {cardTitle}
                </Typography>
                <Typography component="p" variant="body1Resp" paddingY={2}>
                    {cardText}
                </Typography>
                {actionText?.length > 0 && (
                    <ButtonComp
                        startIcon={actionIcon}
                        onClick={handleAction}
                        sx={{ alignSelf: "flex-end" }}
                    >
                        {actionText}
                    </ButtonComp>
                )}
            </CardContent>
        </Card>
    );
};

export default ImageCardComp;
