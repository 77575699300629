import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    CircularProgress,
    Divider,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { useSnackbar } from "notistack";
import customTheme from "../../../constants/Theme";
import { getErrorMessage } from "../../../utils/errorHelper";
import SmartCoachController from "../../../controllers/SmartCoachController";
import BasePage from "../../BasePage";
import TopicButton from "./components/TopicButton";

const filterOptions = [
    {
        key: "not_started",
        displayValue: "New",
    },
    {
        key: "in_progress",
        displayValue: "In Progress",
    },
    {
        key: "completed",
        displayValue: "Done",
    },
    {
        key: null,
        displayValue: "All",
    },
];

const SmartCoachPage = ({ ...props }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState(filterOptions[3]);
    const [topics, setTopics] = useState([]);

    useEffect(() => {
        let mounted = true;

        Promise.allSettled([getTopics()])
            .then((responses) => {
                if (mounted) {
                    setTopics(responses[0].value);
                }
            })
            .finally(() => {
                if (mounted) {
                    setIsLoading(false);
                }
            });

        return () => {
            mounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!(topics?.length > 0)) return;

        /*/
            Auto-select the proper default tab (filter) according to the following business rules:
            -In progress    => if there are any items in progress.
            -New            => if there are no items in progress, but there are new items.
            -All            => if there are no in progress or new items
        /*/

        let defaultFilter = filterOptions.find((f) => f.key === null);
        const someInProgress = topics.some((t) => t.status === "in_progress");
        const someNew = topics.some((t) => t.status === "not_started");

        if (someInProgress) {
            defaultFilter = filterOptions.find((f) => f.key === "in_progress");
        } else if (someNew) {
            defaultFilter = filterOptions.find((f) => f.key === "not_started");
        }

        setFilter(defaultFilter);
    }, [topics]);

    const getTopics = () => {
        return new Promise(async (res, rej) => {
            try {
                const topicsResponse = await SmartCoachController.getTopics();

                if (!topicsResponse || Object.keys(topicsResponse).length <= 0) {
                    enqueueSnackbar(
                        "No coaching topics were received from the server. Please try again later",
                        { variant: "error" }
                    );
                    rej();
                    return;
                }

                res(topicsResponse);
            } catch (error) {
                const errMsg = getErrorMessage(error);

                // Check if request was aborted
                if (errMsg === null) return;

                enqueueSnackbar(errMsg, { variant: "error" });
                rej(errMsg);
            }
        });
    };

    const handleFilterSelection = (e, selectedFilter) => {
        setFilter(selectedFilter);
    };

    const getFilteredTopics = () => {
        return topics.filter((t) => filter.key === null || filter.key === t.status);
    };

    const getNextModuleNum = (topic) => {
        if (isNaN(topic?.number_of_modules_completed) || isNaN(topic?.number_of_modules)) return 0;

        if (topic.number_of_modules_completed >= topic.number_of_modules) return 0;

        return topic.number_of_modules_completed + 1;
    };

    const goToTopic = (topic) => {
        navigate("topic", { state: { topicId: topic.topic_id } });
    };

    const goToModule = (topic, moduleNum) => {
        navigate("topic/module", {
            state: { topicId: topic.topic_id, moduleNum: moduleNum },
        });
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <BasePage>
            {/* Top section - back button, title and tab buttons */}
            <Box width="100%" maxWidth="600px">
                {/* Back button */}
                <Box
                    onClick={handleBack}
                    sx={{
                        cursor: "pointer",
                        display: "flex",
                        width: "fit-content",
                        marginBottom: "2rem",
                    }}
                >
                    <ExpandCircleDownIcon
                        sx={{ transform: "rotate(90deg)", marginRight: "10px" }}
                    />
                    <Typography variant="body1" component="span">
                        back
                    </Typography>
                </Box>

                {/* Page title */}
                <Typography component="h1" variant="pageTitle1Resp" marginY={4}>
                    What areas do you need help with?
                </Typography>

                {/* Page descriptive text */}
                <Typography component="p" variant="body1Resp" marginBottom={2}>
                    Pick a coaching topic of your choice. Each topic includes a few modules that you
                    can complete to improve your financial knowledge.
                </Typography>

                {/* Tab buttons */}
                <ToggleButtonGroup
                    value={filter}
                    onChange={handleFilterSelection}
                    exclusive
                    sx={{ margin: "1rem 0" }}
                >
                    {filterOptions.map((option, i) => (
                        <ToggleButton key={i} value={option}>
                            {option.displayValue}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>

            <Divider sx={{ margin: "1rem 0" }} />

            {/* Loading indicator */}
            {isLoading && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80%",
                    }}
                >
                    <Typography component="h1" variant="pageTitle1Resp" gutterBottom>
                        Loading Your Coaching Topics
                    </Typography>
                    <CircularProgress size="5rem" />
                </Box>
            )}

            {/* No topics message */}
            {!isLoading && topics.length <= 0 && (
                <Typography
                    component="p"
                    variant="body1BoldResp"
                    color={customTheme.palette.warning.main}
                    textAlign="center"
                    marginY={4}
                >
                    No coaching topics received from the server. Please contact support if this
                    issue persists.
                </Typography>
            )}

            {/* Filtered topics */}
            {!isLoading && topics.length > 0 && (
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: { xs: "column", md: "row" },
                        alignItems: "stretch",
                        justifyContent: "space-evenly",
                        gap: "1rem",
                    }}
                >
                    {getFilteredTopics().map((topic, i) => (
                        <Box key={i} flex="1" minWidth="250px">
                            <TopicButton
                                title={topic?.title}
                                duration={topic?.duration}
                                status={topic?.status}
                                progress={
                                    (topic?.number_of_modules_completed /
                                        topic?.number_of_modules) *
                                    100
                                }
                                moduleNum={getNextModuleNum(topic)}
                                onClickTopic={() => {
                                    goToTopic(topic);
                                }}
                                onClickModule={(e) => {
                                    e.stopPropagation();
                                    goToModule(topic, getNextModuleNum(topic));
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </BasePage>
    );
};

export default SmartCoachPage;
