import { Box } from "@mui/material";
import customTheme from "../../constants/Theme";
import AccordionComp from "./AccordionComp";

const AccordionGroupComp = ({ accordions, ...props }) => {
    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            {accordions?.map((accordion, i) => (
                <AccordionComp
                    key={i}
                    sx={{
                        width: "100%",
                        borderRadius:
                            i === 0 && i === accordions.length - 1
                                ? "1rem !important"
                                : i === 0
                                ? "1rem 1rem 0 0 !important"
                                : i === accordions.length - 1
                                ? "0 0 1rem 1rem !important"
                                : "0 !important",
                        boxShadow: `0 2px 1px -1px #0003, 0 1px 1px 0 ${customTheme.palette.secondary.main}40, 0 1px 3px 0 #0003`,
                    }}
                    header={accordion.header}
                    headerOverride={accordion.headerOverride}
                    contentItems={accordion.contentItems}
                    contentOverride={accordion.contentOverride}
                />
            ))}
        </Box>
    );
};

export default AccordionGroupComp;
