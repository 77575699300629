import { Card, CardContent, Typography } from "@mui/material";
import customTheme from "../../../constants/Theme";

const AccountAnalysisCard = ({ icon, description, value, variant, ...props }) => {
    return (
        <Card
            sx={{
                borderRadius: "1rem",
                backgroundColor: `${customTheme.palette.brandedTeal.main}0d`,
                padding: "0.5rem 0",
                height: "95%",
                width: "95%",
                maxWidth: "300px",
            }}
            {...props}
        >
            <CardContent
                style={{
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {icon}
                <Typography component="p" variant="body1Resp" textAlign="center" marginY={2}>
                    {description}
                </Typography>
                <Typography component="p" variant={variant}>
                    {value}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default AccountAnalysisCard;
