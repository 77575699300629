import { useEffect, useState } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import NavigationBar from "../components/navigation_bar/NavigationBar";

const BasePage = ({ ignoreAuthentication, ...props }) => {
    const { isAuthenticated, logout, abortApiRequests } = useAuthContext();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Ensure user is logged in
        if (!ignoreAuthentication && !isAuthenticated) {
            logout(true, true);
        } else {
            setIsLoading(false);
        }

        return () => {
            abortApiRequests();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return isLoading ? (
        <></>
    ) : isAuthenticated ? (
        <NavigationBar>{props.children}</NavigationBar>
    ) : (
        props.children
    );
};

export default BasePage;
