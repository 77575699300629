import { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import LoginSignupContainer from "../../components/templates/LoginSignupContainer";
import InitialForm from "./StepComponents/InitialForm";
import CreditProfileForm from "./StepComponents/CreditProfileForm";
import TermsAndConditionsForm from "./StepComponents/TermsAndConditionsForm";
import OtpVerificationForm from "./StepComponents/OtpVerificationForm";
import OtpFailed from "./StepComponents/OtpFailed";
import SignupCompleted from "./StepComponents/SignupCompleted";
import SecurityQuestionForm from "./StepComponents/SecurityQuestionForm";

function SignUpPage() {
    const maxSteps = 7;
    const { index } = useParams();
    const { state } = useLocation();
    const { login, logout } = useAuthContext();

    const [step, setStep] = useState(
        state?.verifyMobile ? 4 : state?.verifyAccount ? 6 : Math.max(Math.min(index ?? 0, 7), 1)
    );
    const [signupFormValues, setSignupFormValues] = useState({});

    const handleTCs = (proceed) => {
        if (proceed === true) {
            handleForward();
        } else {
            handleBackward();
        }
    };

    const handleOtpVerification = (requiresSecurityQuestions, noReport) => {
        if (requiresSecurityQuestions === true) {
            handleForward();
        } else if (noReport === true) {
            // No credit report was pulled, navigate back to login screen
            logout(true);
        } else {
            // Signup is completed
            setStep(maxSteps);
        }
    };

    const handleSecurityQuestion = (proceed) => {
        if (proceed) {
            handleForward();
        } else {
            logout(true);
        }
    };

    const loginPromise = () => {
        return new Promise(async (res, rej) => {
            try {
                const credentials = {
                    id_number: signupFormValues.id_number,
                    password: signupFormValues.password,
                };

                const responseMsg = await login(credentials);

                if (!responseMsg || responseMsg === "success") {
                    res(true);
                } else {
                    rej(false);
                }
            } catch (error) {
                rej(error);
            }
        });
    };

    const handleForward = () => {
        setStep(step + 1);
    };

    const handleBackward = () => {
        setStep(step - 1);
    };

    const getStepContents = () => {
        switch (step) {
            case maxSteps:
                return <SignupCompleted loginHandler={loginPromise} />;
            case 6:
                return <SecurityQuestionForm submitCallback={handleSecurityQuestion} />;
            case 5:
                return <OtpFailed submitCallback={handleForward} />;
            case 4:
                return (
                    <OtpVerificationForm
                        submitCallback={handleOtpVerification}
                        verifyMobile={state?.verifyMobile}
                    />
                );
            case 3:
                return (
                    <TermsAndConditionsForm
                        formValues={signupFormValues}
                        submitCallback={handleTCs}
                        handleBack={getHandleBack()}
                        progressBarPerc={getProgressPerc()}
                    />
                );
            case 2:
                return (
                    <CreditProfileForm
                        initialFormValues={signupFormValues}
                        setFormValues={setSignupFormValues}
                        submitCallback={handleForward}
                    />
                );
            case 1:
            default:
                return (
                    <InitialForm
                        initialFormValues={signupFormValues}
                        setFormValues={setSignupFormValues}
                        submitCallback={handleForward}
                    />
                );
        }
    };

    const getPageTitle = () => {
        switch (step) {
            case maxSteps:
            case 6:
            case 5:
            case 4:
            case 3:
            case 2:
                return null;
            case 1:
            default:
                return "Sign up";
        }
    };

    const getPageHeading = () => {
        switch (step) {
            case 4:
                return "OTP Verification";
            case 2:
                return "Help us find your credit profile";
            case 1:
            case 3:
            case 5:
            case 6:
            case maxSteps:
            default:
                return null;
        }
    };

    const getProgressPerc = () => {
        if (step <= 1) return undefined;

        return (step / maxSteps) * 100;
    };

    const getHandleBack = () => {
        if (step > 3)
            return () => {
                logout(true);
            };

        if (step > 1) return handleBackward;
        else return null;
    };

    return (
        <LoginSignupContainer
            pageTitle={getPageTitle()}
            pageHeading={getPageHeading()}
            progressBarPerc={getProgressPerc()}
            overwriteHandleBack={getHandleBack()}
        >
            {getStepContents()}
        </LoginSignupContainer>
    );
}

export default SignUpPage;
