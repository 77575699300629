import { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import customTheme from "../../../constants/Theme";
import AreaChartComponent from "../../../components/charts/AreaChart";

const CreditScoreAnalytics = ({ history, setCompHeight, ...props }) => {
    const [chartDetails, setChartDetails] = useState({});
    const screenWidth = window.innerWidth;

    useEffect(() => {
        if (!history?.length) return;

        let firstScore = history[0].score;
        let lastScore = history[history.length - 1].score;
        let prevScore = history[history.length - 2 >= 0 ? history.length - 2 : 0].score;
        const scoreDiff = lastScore - prevScore;

        // Extrapolate the first and last point non-linearly to create whip effect
        let secondScore = history.length > 1 ? history[1].score : firstScore;
        let extrapolatedFirst = firstScore - (secondScore - firstScore);
        let extrapolatedLast = lastScore + scoreDiff;

        const extrapolatedData = [
            {
                label: "Extrapolated first",
                dataPoint: extrapolatedFirst * 0.85,
            },
            {
                label: "Extrapolated last",
                dataPoint: extrapolatedLast * 1.15,
            },
        ];

        const historyData = history.map((h, i) => {
            return {
                label:
                    i === history.length - 1
                        ? `${new Date(h.date).toLocaleString("default", { month: "short" })}\n${
                              h.score
                          }`
                        : h.score,
                dataPoint: h.score,
            };
        });

        // Need additional data points before and after the single data to enhance the whip effect
        const additionalData =
            history.length > 1
                ? null
                : [
                      {
                          label: "Additional first",
                          dataPoint: history[0].score,
                      },
                      {
                          label: "Additional last",
                          dataPoint: history[0].score,
                      },
                  ];

        const data =
            additionalData == null
                ? [extrapolatedData[0], ...historyData, extrapolatedData[1]]
                : [
                      extrapolatedData[0],
                      additionalData[0],
                      ...historyData,
                      additionalData[1],
                      extrapolatedData[1],
                  ];

        const afterDrawPlugin = (chart) => {
            const {
                ctx,
                chartArea: { bottom, width, height },
            } = chart;
            const xc = width / 2;
            const y = bottom - 50;

            setCompHeight(height);

            ctx.save();

            ctx.fillStyle = customTheme.palette.secondary.main;
            ctx.textBaseline = "middle";

            // Draw the text at bottom of chart
            ctx.textAlign = "right";
            ctx.font = `${customTheme.typography.body1Bold.fontWeight} ${customTheme.typography.body1.fontSize} ${customTheme.typography.fontFamily}`;
            ctx.fillText(`${scoreDiff >= 0 ? "+" : "-"}${Math.abs(scoreDiff)} `, xc - 80, y);

            ctx.textAlign = "center";
            ctx.font = `${customTheme.typography.body1.fontWeight} ${customTheme.typography.body1.fontSize} ${customTheme.typography.fontFamily}`;
            ctx.fillText("than the previous month", xc + 20, y);
        };

        const options = {
            aspectRatio: screenWidth < 400 ? 1.15 : null,
            plugins: {
                datalabels: {
                    color: (context) => {
                        const index = context.dataIndex;
                        return index === Math.max(context.dataset.data.length - 2, 0)
                            ? customTheme.palette.brandedBlue.main
                            : customTheme.palette.text.primary;
                    },
                },
                scales: {
                    y: {
                        max: Math.max(...data.map((d) => d.dataPoint)) + 150,
                    },
                },
            },
        };

        const plugins = [
            {
                afterDraw: afterDrawPlugin,
            },
        ];

        setChartDetails({
            data: data,
            options: options,
            plugins: plugins,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ width: "100%", maxHeight: "50vh" }}>
            {!chartDetails.data ? (
                <>
                    <Typography component="h2" variant="pageTitle2">
                        Drawing Chart Details
                    </Typography>
                    <CircularProgress size="large" />
                </>
            ) : (
                <AreaChartComponent chartConfig={chartDetails} includeDataLabels hideEndPoints />
            )}
        </Box>
    );
};

export default CreditScoreAnalytics;
