import { Box, Typography } from "@mui/material";
import GalaxyImg from "../../../assets/images/landing_page_1.jpeg";
import FriendsImg from "../../../assets/images/landing_page_2.jpeg";
import PhoneImg from "../../../assets/images/phone_landingpage.png";
import LogoFullTextDarkImg from "../../../assets/logos/logo_full_trimmed.png";
import ChequeIcon from "../../../assets/icons/money-check.png";
import ProfileIcon from "../../../assets/icons/profile.png";

const AboutSection = ({ theme, ...props }) => {
    return (
        <section
            id="about"
            style={{ backgroundColor: "white", color: theme.palette.background.default }}
        >
            {/* Intro content */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "space-between",
                }}
            >
                {/* Section images */}
                <Box
                    sx={{
                        width: { xs: "100%", md: "50vw" },
                        position: "relative",
                    }}
                >
                    {/* Clipped galaxy image */}
                    <Box
                        sx={{
                            borderRadius: "0px 0px 90% 0px",
                            clipPath: "border-box",
                        }}
                    >
                        <img
                            src={GalaxyImg}
                            alt="galaxy"
                            style={{
                                width: "150%", // Increase width to compensate for translation
                                aspectRatio: 3 / 2, // Adjust aspect ratio to keep img from stretching
                                transform: "translateX(-25%) scaleX(-1)", // Mirror img and move it left to orientate man silhouette
                            }}
                        />
                    </Box>

                    {/* Titled phone image */}
                    <Box
                        sx={{
                            width: "60%",
                            position: "absolute",
                            bottom: 0,
                            right: "10%",
                        }}
                    >
                        <img
                            src={PhoneImg}
                            alt="iphone"
                            style={{
                                width: "100%",
                            }}
                        />
                    </Box>
                </Box>

                {/* Section text */}
                <Box
                    sx={{
                        width: { xs: "100%", md: "50vw" },
                        padding: { xs: "1rem" },
                        paddingBottom: { xs: "1rem", lg: "8vh", xl: "15vh", vl: "30vh" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: { xs: "flex-start", md: "flex-end" },
                    }}
                >
                    <Typography component="h2" variant="pageTitleLarge">
                        Welcome to
                    </Typography>
                    <Box display="block">
                        <img
                            src={LogoFullTextDarkImg}
                            alt="logo text dark"
                            style={{
                                marginTop: "0.5rem",
                                height: "5vw",
                                minHeight: "40px",
                                maxHeight: "60px",
                            }}
                        />
                    </Box>
                    <Typography component="h2" variant="pageTitleLarge">
                        Elevating Your Credit,
                    </Typography>
                    <Typography component="h2" variant="pageTitleLarge">
                        Elevating Your Life.
                    </Typography>
                    <Typography
                        component="p"
                        variant="body1"
                        textAlign="justify"
                        mt={{ xs: 2, sm: 4 }}
                    >
                        At ThreeSixty.me, we believe that credit is a powerful tool to help you
                        navigate through life. Your credit record and score are vital components of
                        your financial well-being. We are here to help empower you to carefully
                        manage your credit profile and usage.
                    </Typography>
                </Box>
            </Box>

            {/* Details content */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "space-between",
                }}
            >
                {/* Section text */}
                <Box
                    sx={{
                        width: { xs: "100%", md: "50vw", lg: "40vw" },
                        padding: "1rem",
                        marginX: { xs: "unset", lg: "auto" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "2vh",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={ChequeIcon}
                            alt="cheque icon"
                            style={{
                                height: "25px",
                                marginRight: "5px",
                            }}
                        />
                        <Typography component="h3" variant="pageTitle2Resp">
                            Enhance Your Financial Future
                        </Typography>
                    </Box>
                    <Typography component="p" variant="body1Resp" textAlign="justify" my={2}>
                        A strong credit profile can open doors to various financial opportunities,
                        from favourable loan terms to higher credit limits and reduced insurance
                        premiums. And by maintaining a healthy credit score, you position yourself
                        to take advantage of several benefits, which can lead to substantial savings
                        and a more secure financial future.
                    </Typography>

                    <Box
                        mt={1}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={ProfileIcon}
                            alt="profile icon"
                            style={{
                                height: "25px",
                                marginRight: "5px",
                            }}
                        />
                        <Typography component="h3" variant="pageTitle2Resp">
                            Your 360° Credit Profile View
                        </Typography>
                    </Box>
                    <Typography component="p" variant="body1Resp" textAlign="justify" mt={2}>
                        Gaining a holistic picture of your credit profile is crucial in today’s
                        financial landscape. With a comprehensive understanding, you can pinpoint
                        areas needing improvement, voice any inaccuracies, and efficiently manage
                        your credit. A complete view of your credit profile not only helps you to
                        reflect a good financial status but also prepares you to continue to
                        effectively manage your debts and investments.
                    </Typography>
                </Box>

                {/* Section images */}
                <Box
                    sx={{
                        width: { xs: "100%", md: "50vw" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                    }}
                >
                    {/* Clipped friends image */}
                    <Box
                        sx={{
                            borderRadius: " 90% 0px 0px 0px",
                            clipPath: "border-box",
                        }}
                    >
                        <img
                            src={FriendsImg}
                            alt="friends"
                            style={{
                                width: "150%", // Increase width to compensate for translation
                                aspectRatio: 3 / 2, // Adjust aspect ratio to keep img from stretching
                                transform: "translateX(-25%)", // Mirror img and move it left to orientate man silhouette
                                marginBottom: "-1%",
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </section>
    );
};

export default AboutSection;
