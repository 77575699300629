import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const CheckboxComp = (props) => {
    return (
        <FormGroup>
            <FormControlLabel
                {...props}
                control={<Checkbox color="secondary" id={props.id} />}
                variant="body2"
            />
        </FormGroup>
    );
};

export default CheckboxComp;
