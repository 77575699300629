import { Box, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import customTheme from "../../../../constants/Theme";
import { normaliseString } from "../../../../utils/generalHelper";
import ButtonLinkComp from "../../../../components/base_components/ButtonLinkComp";
import { getModuleStatusIcon } from "../../../../utils/smartCoachHelper";

const ModuleButton = ({ title, duration, status, isSelected, ...props }) => {
    return (
        <ButtonLinkComp
            startIcon={getModuleStatusIcon(status)}
            sx={{
                fontSize: { sm: "0.875rem !important", md: "unset" },
                height: "unset !important",
                backgroundColor: isSelected
                    ? `${customTheme.palette.brandedTeal.main}99 !important`
                    : "unset",
            }}
            {...props}
        >
            <Box
                sx={{
                    width: "100%",
                    marginLeft: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    textAlign: "left",
                }}
            >
                {normaliseString(title)}
                <Box sx={{ minWidth: "50px", display: "flex", alignItems: "center" }}>
                    <AccessTimeIcon fontSize="small" />
                    <Typography
                        component="p"
                        variant="pageBodySmall"
                        textTransform="lowercase"
                        fontWeight={300}
                        ml={1}
                    >
                        {duration} min
                    </Typography>
                </Box>
            </Box>
        </ButtonLinkComp>
    );
};

export default ModuleButton;
