import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

import ButtonComp from "../../components/base_components/ButtonComp";
import CustomAccordion from "./components/CustomAccordion";
import Footer from "../../components/footer/Footer";
import logoFAQ from "../../assets/logos/logoFAQ.png";
import CustomAppBar from "../../components/custom_app_bar/CustomAppBar";

const accessInfo = [
    {
        title: "Creditors",
        description:
            "Creditors can view your credit report when you apply for credit, like loans or credit cards, but only with your consent. It helps them assess your creditworthiness",
    },
    {
        title: "Insurance Companies",
        description:
            "Some insurers may request a credit check with your permission, typically for products like vehicle or home insurance to evaluate risk.",
    },
    {
        title: "Employers",
        description:
            "Potential employers may seek your consent to access your credit report for background checks, especially for roles involving financial responsibilities.",
    },
    {
        title: "Landlords",
        description:
            "Property owners can request access to your credit report when you apply to rent a property, mainly to gauge your rent payment reliability.",
    },
    {
        title: "Government Entities",
        description:
            "Certain government departments may access your credit report for specific purposes, such as fraud investigations or financial compliance.",
    },
    {
        title: "Personal Access",
        description:
            " As a consumer, you have the right to access your own credit report, available annually through major credit bureaus, including our ThreeSixty.me platform in partnership with VeriCred registered Credit Bureau (VCCB). Regularly checking it helps ensure accuracy and keeps you informed about your financial status.",
    },
];

const data = [
    {
        id: "panel1",
        title: (
            <Typography variant="h6" sx={{ fontSize: "16px" }}>
                What is a Credit Report?
            </Typography>
        ),
        content: (
            <>
                <Typography variant="body1">
                    Your credit report is a detailed record of your credit history and payment
                    behaviour. The <b>ThreeSixty.me</b> platform (in partnership with one of our
                    primary South African registered credit bureaus, VeriCred Credit Bureau also
                    known as VCCB) provides your credit record for free. Your credit report includes
                    vital personal information like your name, address, employer, and ID number –
                    details typically provided on credit applications.{" "}
                </Typography>
                <Typography sx={{ marginTop: "15px" }}>
                    {" "}
                    Your credit history, such as account activity and payment habits, is also
                    recorded, showing whether you pay your accounts timely each month. Importantly,
                    a credit report does not contain sensitive data like your medical history or
                    race, religious beliefs, and political affiliations. Beyond tracking your credit
                    behaviour or reputation (making timely payments and account management), it’s a
                    valuable tool to help you with budgeting and to pinpoint any discrepancies or
                    questionable transactions, for example related to identity theft or similar.
                </Typography>
            </>
        ),
        bottom: (
            <Typography variant="body2">
                Sign up for <b>FREE</b> via <b>ThreeSixty.me</b> & keep tabs on your credit
                information.{" "}
                <Typography variant="p" sx={{ color: "#C6D647", fontWeight: "bold" }}>
                    No hassles, one platform and credit education at its best.
                </Typography>
            </Typography>
        ),
        buttonText: "Sign up for free",
        logo: (
            <Typography
                variant="body2"
                sx={{
                    fontSize: "24px",
                    lineHeight: "29.26px",
                    fontWeight: 400,
                    marginTop: "25px",
                    marginBottom: "10px",
                }}
            >
                Credit confidence starts here at{" "}
                <img src={logoFAQ} alt="Logo" height="26px" style={{ marginBottom: "-5px" }} />
            </Typography>
        ),
    },
    {
        id: "panel2",
        title: <Typography variant="h6">What is a Credit Score?</Typography>,
        content: (
            <Typography variant="body1">
                A credit score is also known as your credit risk rating. Credit scores play a role
                in the decision-making process of credit providers. When granting you credit,
                creditors rely on your credit score to assess your ability to make payments and meet
                your obligations. Several factors, such as your income level, payment history,
                credit background, and the type of credit you apply for typically influence your
                credit score. The specific scoring range may vary depending on the platform and
                formula (calculation method) used for determining credit scores. For instance, a
                high score is considered a very low risk (an excellent score indication) while a
                score of 600 and lower will usually fall within the high-risk (or a poor score)
                category.
            </Typography>
        ),
        bottom: (
            <>
                <Typography variant="p" sx={{ color: "#C6D647", fontWeight: "bold" }}>
                    Knowing your credit status empowers you.{" "}
                </Typography>
                <Typography variant="body2">Credit Confidence Starts Here: </Typography>
            </>
        ),
        buttonText: "ThreeSixty.me",
        logo: "",
    },
    {
        id: "panel3",
        title: (
            <Typography variant="h6">
                Credit Record and Credit Score – What’s the Difference?
            </Typography>
        ),
        content: (
            <>
                <Typography variant="body1">
                    Your{" "}
                    <Typography variant="p" sx={{ color: "#27D6D2BF", fontWeight: "bold" }}>
                        credit record
                    </Typography>{" "}
                    is a comprehensive document that holds a list of information about your
                    financial history and how you’ve managed your debts. It includes details about
                    your borrowing history, such as the amount borrowed, the creditor, and your
                    repayment history. It all boils down to your{" "}
                    <Typography variant="p" sx={{ color: "#27D6D2BF", fontWeight: "bold" }}>
                        credit score
                    </Typography>{" "}
                    (specified on your record), a three-digit number that reflects your payment
                    behaviour and credit history over the past 12 months. Keep in mind that
                    inquiries you make into your{" "}
                    <Typography variant="p" sx={{ color: "#27D6D2BF", fontWeight: "bold" }}>
                        credit record
                    </Typography>{" "}
                    stick around for two years, and an excessive number of inquiries might signal
                    risk to lenders.
                </Typography>
                <Typography variant="body2" sx={{ marginTop: "16px" }}>
                    Credit bureaus or credit record providers use various scoring models to assess
                    your creditworthiness, but the principle remains the same. The{" "}
                    <Typography variant="p" sx={{ color: "#27D6D2BF", fontWeight: "bold" }}>
                        credit score
                    </Typography>{" "}
                    generally falls in the range of 0 to 999, with a higher score indicating better
                    credit behaviour.
                </Typography>
            </>
        ),
        bottom: (
            <>
                <Typography variant="body2">
                    In essence, your{" "}
                    <Typography variant="p" sx={{ color: "#27D6D2BF", fontWeight: "bold" }}>
                        credit record/report
                    </Typography>{" "}
                    summarises your financial actions, while your{" "}
                    <Typography variant="p" sx={{ color: "#27D6D2BF", fontWeight: "bold" }}>
                        credit score
                    </Typography>{" "}
                    represents an evaluation of those actions.
                </Typography>
                <Typography variant="p" sx={{ color: "#C6D647", fontWeight: "bold" }}>
                    {" "}
                    Credit Check Empowerment Unlocked
                </Typography>
            </>
        ),
        logo: "",
        buttonText: "ThreeSixty.me",
    },
    {
        id: "panel4",
        title: <Typography variant="h6">Why Should I Review My Credit Profile?</Typography>,
        content: (
            <Typography variant="body1">
                Regularly checking your credit profile is essential for several reasons. It helps
                you understand your current financial situation, identify inaccuracies that can
                impact your credit applications, detect identity theft, help prioritise debt
                repayments, and ensure your credit score remains strong. Plus, according to the law,
                you can obtain a free copy of your credit profile annually, allowing you to stay on
                top of your financial well-being.
            </Typography>
        ),
        bottom: (
            <Typography
                variant="p"
                sx={{
                    color: "#C6D647",
                    fontWeight: "bold",
                    textAlign: { xs: "center", md: "end" },
                }}
            >
                Not signed up yet?
            </Typography>
        ),
        buttonText: "Sign up for free",
        centerButton: true,
        logo: (
            <Typography
                variant="body2"
                sx={{
                    fontSize: "24px",
                    lineHeight: "29.26px",
                    fontWeight: 400,
                    marginTop: "25px",
                    marginBottom: "10px",
                }}
            >
                <img
                    src={logoFAQ}
                    alt="Logo"
                    height="26px"
                    style={{ marginBottom: "-7px", marginLeft: "-3px" }}
                />
                , your path to financial wellness
            </Typography>
        ),
    },
    {
        id: "panel5",
        title: <Typography variant="h6">How Can I Ensure a Strong Credit Profile?</Typography>,
        content: (
            <Typography variant="body1">
                Ensuring a strong credit profile involves making timely payments, managing credit
                card balances, avoiding excessive new accounts, reducing debt, maintaining open
                communication with creditors, practicing good financial habits, regularly reviewing
                your credit report for errors, and addressing any past issues. Using the{" "}
                <b>ThreeSixty.me</b> platform for monitoring and accuracy checks can help you stay
                on track with your credit profile’s strength.
            </Typography>
        ),
        bottom: (
            <Typography
                variant="p"
                sx={{
                    color: "#C6D647",
                    fontWeight: "bold",
                    textAlign: { xs: "center", md: "end" },
                }}
            >
                Not signed up yet?
            </Typography>
        ),
        buttonText: "Sign up for free",
        centerButton: true,
        logo: (
            <Typography
                variant="body2"
                sx={{
                    fontSize: "24px",
                    lineHeight: "29.26px",
                    fontWeight: 400,
                    marginTop: "25px",
                    marginBottom: "10px",
                }}
            >
                Elevate your credit, elevate your life with{" "}
                <img
                    src={logoFAQ}
                    alt="Logo"
                    height="26px"
                    style={{ marginBottom: "-5px", marginLeft: "-1px" }}
                />
            </Typography>
        ),
    },
    {
        id: "panel6",
        title: <Typography variant="h6">How Can I Improve My Credit Rating?</Typography>,
        content: (
            <Typography variant="body1">
                To boost your credit score, start by checking your credit report with{" "}
                <b>ThreeSixty.me</b> Look for errors and address past issues like any late payments.
                Pay your instalments on time, limit new credit accounts, and maintain older ones for
                a longer credit history. Remember, practice positive credit habits like paying off
                debt and using less of your available credit. Monitoring your progress through{" "}
                <b>ThreeSixty.me</b> is key to achieving better financial opportunities and reaching
                your financial goals.
            </Typography>
        ),
        bottom: (
            <Typography
                variant="p"
                sx={{
                    color: "#C6D647",
                    fontWeight: "bold",
                    textAlign: { xs: "center", md: "end" },
                }}
            >
                Not signed up yet?
            </Typography>
        ),
        buttonText: "Sign up for free",
        centerButton: true,
        logo: (
            <Typography
                variant="body2"
                sx={{
                    fontSize: "24px",
                    lineHeight: "29.26px",
                    fontWeight: 400,
                    marginTop: "25px",
                    marginBottom: "10px",
                }}
            >
                Empower your financial future with{" "}
                <img
                    src={logoFAQ}
                    alt="Logo"
                    height="26px"
                    style={{ marginBottom: "-5px", marginLeft: "-1px" }}
                />
            </Typography>
        ),
    },
    {
        id: "panel7",
        title: <Typography variant="h6">What is a Credit Bureau?</Typography>,
        content: (
            <Typography variant="body1">
                A credit bureau, also known as a credit record provider, collects and maintains your
                credit data from various credit providers and lenders. This data includes your
                accounts, loans, outstanding balances, and your repayment history. Credit bureaus
                compile this information into a credit report, which is a critical tool for lenders
                when assessing your credit applications. Our <b>ThreeSixty.me</b> platform is
                powered by VeriCred – VCCB (a primary South African registered Credit Bureau) and
                affiliated with CreditSmart Financial Services (a registered Financial Services
                Provider – FSP 52295 and registered Debt Management company – NCRDC3143).
            </Typography>
        ),
        bottom: (
            <Typography
                variant="p"
                sx={{
                    color: "#C6D647",
                    fontWeight: "bold",
                    textAlign: { xs: "center", md: "end" },
                }}
            >
                Not signed up yet?
            </Typography>
        ),
        buttonText: "Sign up for free",
        centerButton: true,
        logo: (
            <Typography
                variant="body2"
                sx={{
                    fontSize: "24px",
                    lineHeight: "29.26px",
                    fontWeight: 400,
                    marginTop: "25px",
                    marginBottom: "10px",
                }}
            >
                Credit confidence starts here at{" "}
                <img
                    src={logoFAQ}
                    alt="Logo"
                    height="26px"
                    style={{ marginBottom: "-5px", marginLeft: "-1px" }}
                />
            </Typography>
        ),
    },
    {
        id: "panel8",
        title: (
            <Typography variant="h6">
                How can I sign up to access my credit profile on the ThreeSixty.me platform?
            </Typography>
        ),
        content: (
            <Typography variant="body1">
                Signing up for the best credit educational experience has never been this easy.
                Click on the{" "}
                <Typography variant="p" sx={{ color: "#27D6D2BF", fontWeight: "bold" }}>
                    sign-up
                </Typography>{" "}
                button below and get your profile registered to start your credit confidence journey
                TODAY.
            </Typography>
        ),
        logo: (
            <Box
                sx={{
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginBottom: "10px",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ButtonComp
                        color="primary"
                        // onClick={goToLogin}
                        sx={{ maxWidth: "250px", height: "44px" }}
                    >
                        ThreeSixty.me
                    </ButtonComp>
                </Box>
            </Box>
        ),
    },
    {
        id: " 1ew",
        title: <Typography variant="h6">Is there a fee to access my credit profile?</Typography>,
        content: (
            <Typography variant="body1">
                Not only is your credit profile freely available when you sign up via{" "}
                <b>ThreeSixty.me</b> but you will have access to a world full of credit and
                financial tips and perks to improve your way towards financial independence.
            </Typography>
        ),
        logo: "",
        bottom: (
            <Typography
                variant="p"
                sx={{
                    color: "#C6D647",
                    fontWeight: "bold",
                    textAlign: { xs: "center", md: "end" },
                }}
            >
                Not signed up yet?
            </Typography>
        ),
        buttonText: "Sign up for free",
        centerButton: true,
    },
    {
        id: "panel10",
        title: (
            <Typography variant="h6">
                What information do I need to provide to access my credit profile?
            </Typography>
        ),
        content: (
            <Typography variant="body1">
                All you need to provide to access your credit profile is your South African ID
                number, correct mobile number, and correct email address. We use these contact
                methods to verify that you are in fact the correct person. If we are unable to
                verify your contact details against what we find via the credit bureaux, we’ll guide
                you through a validation process.
            </Typography>
        ),
        bottom: (
            <>
                <Typography variant="body2">
                    Make sure you sign up with <b>ThreeSixty.me</b> to get access to your credit
                    profile and MORE!
                    <Typography variant="p" sx={{ color: "#C6D647", fontWeight: "bold" }}>
                        {" "}
                        One easy click
                    </Typography>
                </Typography>
            </>
        ),
        logo: "",
        buttonText: "Sign up for free",
    },
    {
        id: "panel11",
        title: <Typography variant="h6">How often is my credit profile updated?</Typography>,
        content: (
            <Typography variant="body1">
                Your credit profile is updated once a month on <b>ThreeSixty.me</b> You’ll be
                notified when your new report is available each month.
            </Typography>
        ),
        bottom: (
            <Typography
                variant="p"
                sx={{
                    color: "#C6D647",
                    fontWeight: "bold",
                    textAlign: { xs: "center", md: "end" },
                }}
            >
                Not signed up yet?
            </Typography>
        ),
        buttonText: "Sign up for free",
        centerButton: true,
        logo: "",
    },
    {
        id: "panel12",
        title: <Typography variant="h6">Who has access to my credit profile?</Typography>,
        content: (
            <>
                <Typography variant="body1" paragraph>
                    Access to your credit report is strictly regulated to protect your privacy.
                    Here's a brief overview of who can access it:
                </Typography>
                <List>
                    {accessInfo.map((item) => (
                        <ListItem key={item.title} sx={{ color: "white" }}>
                            <ListItemText
                                secondaryTypographyProps={{
                                    color: "white",
                                    fontWeight: 300,
                                    fontSize: "16px",
                                    opacity: 1,
                                    paddingLeft: "20px",
                                }}
                                primaryTypographyProps={{
                                    color: "white !important",
                                    fontWeight: 600,
                                    fontSize: "16px !important",
                                    paddingBottom: "16px",
                                }}
                                primary={
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            color: "white",
                                            fontWeight: 700,
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <Typography sx={{ marginRight: "15px" }}>•</Typography>
                                        {item.title}
                                    </Typography>
                                }
                                secondary={item.description}
                            />
                        </ListItem>
                    ))}
                </List>
            </>
        ),
        bottom: (
            <Typography variant="body2">
                Sign up for FREE & gain all your credit report insights here on <b>ThreeSixty.me</b>
            </Typography>
        ),
        logo: (
            <Typography variant="body1" paragraph sx={{ marginTop: "15px", marginBottom: "0px" }}>
                It's essential to recognise that access to your credit report requires your consent.
                Your credit report is a valuable tool for credit providers to assess your
                creditworthiness and make informed lending decisions, and your permission is
                integral to this process.
            </Typography>
        ),
        buttonText: "Sign up for free",
    },
    {
        id: "panel13",
        title: (
            <Typography variant="h6">
                Can I monitor changes to my credit profile through <b>ThreeSixty.me</b>?
            </Typography>
        ),
        content: (
            <>
                <Typography variant="body1">
                    Yes, our user-friendly platform guides you every step of your credit health
                    journey. You can login to see any changes on your report and you will also
                    receive regular notifications and communication from our team related to any
                    changes within your credit report.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: "15px" }}>
                    Not yet signed up to use the <b>ThreeSixty.me</b> platform?
                </Typography>
            </>
        ),
        bottom: (
            <Typography
                variant="p"
                sx={{
                    color: "#C6D647",
                    fontWeight: "bold",
                    textAlign: { xs: "center", md: "end" },
                }}
            >
                Not signed up yet?
            </Typography>
        ),
        buttonText: "Sign up for free",
        centerButton: true,
        logo: (
            <Typography
                variant="body2"
                sx={{
                    fontSize: "24px",
                    lineHeight: "29.26px",
                    fontWeight: 400,
                    marginTop: "25px",
                    marginBottom: "10px",
                }}
            >
                Chart your course to financial success with{" "}
                <img
                    src={logoFAQ}
                    alt="Logo"
                    height="26px"
                    style={{ marginBottom: "-5px", marginLeft: "-1px" }}
                />
            </Typography>
        ),
    },
    {
        id: "panel14",
        title: (
            <Typography variant="h6">
                Does <b>ThreeSixty.me</b> offer credit improvement tips or services?
            </Typography>
        ),
        content: (
            <Typography variant="body1">
                Without a doubt, yes. <b>ThreeSixty.me</b> not only offers a wide range of helpful
                and educational insights to guide you along your financial journey, but also
                tailor-made solutions to consider. <b>ThreeSixty.me</b>’s SmartHelp function offers
                educational tips and services to empower you on your route to financial
                independence. So make sure you sign up to explore all there is to offer.
            </Typography>
        ),
        logo: (
            <Box
                sx={{
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "10px",
                    }}
                >
                    <ButtonComp
                        color="primary"
                        // onClick={goToLogin}
                        sx={{ maxWidth: "250px", height: "44px" }}
                    >
                        ThreeSixty.me: Sign-Up
                    </ButtonComp>
                </Box>
            </Box>
        ),
    },
];

const FAQPage = () => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    overflowX: "clip",
                    background: "linear-gradient( #011528,#083846,#136F77,#27D6D2)",
                }}
            >
                <CustomAppBar />
                <Box sx={{ maxWidth: "720px", marginBottom: "20px" }}>
                    <Typography
                        variant="h1"
                        sx={{
                            fontFamily: "Montserrat",
                            fontSize: "40px",
                            fontWeight: 700,
                            textAlign: "center",
                            paddingTop: { xs: "66px", md: "115px" },
                        }}
                    >
                        FAQ
                    </Typography>
                    <Box sx={{ position: "relative", padding: "10px" }}>
                        <CustomAccordion data={data} />
                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    );
};

export default FAQPage;
