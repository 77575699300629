import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Link, Typography, Grid } from "@mui/material";
import XIcon from "@mui/icons-material/X";

import customTheme from "../../constants/Theme";
import LogoFooter from "../../assets/logos/logo_vertical.png";
import FacebookIcon from "../../assets/logos/facebook.png";
import InstagramIcon from "../../assets/logos/instagram-logo.png";
import PaiaManual from "../../assets/documents/paia_manual_202405.pdf";

const Footer = ({ gradientEffect, isMobile }) => {
    const navigate = useNavigate();

    const StyledFacebookIcon = () => {
        return (
            <img
                src={FacebookIcon}
                alt="Logo"
                height="20px"
                style={{ margin: "2px 6px 2px 0px" }}
            />
        );
    };

    const StyledInstagramIcon = () => {
        return <img src={InstagramIcon} alt="Logo" height="20px" style={{ margin: "2px" }} />;
    };

    return (
        <footer>
            <Box
                sx={{
                    width: "100%",
                    padding: { xs: "2rem 1rem", sm: "2rem", lg: "4rem 10rem" },
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: gradientEffect ? "none" : "#011528",
                    background: gradientEffect
                        ? `linear-gradient(${isMobile ? "to top" : "to left"} , #011528, #27D6D2)`
                        : "none",
                }}
            >
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{
                            paddingY: "1rem",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <img src={LogoFooter} alt="logo bottom" />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{
                            paddingY: "1rem",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography component="h6" variant="pageTitle2Small" marginBottom={2}>
                            About
                        </Typography>
                        <Link href="/faq" className="whiteText">
                            <Typography component="p" variant="body1">
                                FAQ
                            </Typography>
                        </Link>
                        <Link
                            href="/legal/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="whiteText"
                        >
                            <Typography component="p" variant="body1">
                                Privacy Policy
                            </Typography>
                        </Link>
                        <Link href={PaiaManual} download className="whiteText">
                            <Typography component="p" variant="body1">
                                PAIA Manual
                            </Typography>
                        </Link>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{
                            paddingY: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Typography component="h6" variant="pageTitle2Small" marginBottom={2}>
                            Contact Us
                        </Typography>
                        {/* <Typography
                            component="p"
                            variant="body1"
                            onClick={() => {
                                window.location.href = "tel:+27120545755";
                            }}
                            sx={{ cursor: "pointer" }}
                        >
                            Phone: <strong>012 054 5755</strong>
                        </Typography> */}
                        <Link href="/contact-us" className="whiteText">
                            <Typography component="p" variant="body1">
                                Ask for help
                            </Typography>
                        </Link>
                        <Link href="mailto:info@threesixtyme.co.za" className="whiteText">
                            <Typography component="p" variant="body1">
                                info@threesixty.me
                            </Typography>
                        </Link>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{
                            paddingY: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: { xs: "nowrap", md: "wrap" },
                            alignItems: { xs: "flex-start", md: "flex-end" },
                        }}
                    >
                        <Typography
                            component="h6"
                            variant="pageTitle2Small"
                            textAlign="left"
                            marginBottom={2}
                            marginRight={"52px"}
                        >
                            Social
                        </Typography>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <Link
                                href="https://www.facebook.com/profile.php?id=61559275956125"
                                className="whiteText"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ margin: "5px 10px 5px 0px" }}
                            >
                                <StyledFacebookIcon />
                            </Link>
                            <Link
                                href="https://www.instagram.com/threesixty.me/"
                                className="whiteText"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ margin: "5px 10px 5px 10px" }}
                            >
                                <StyledInstagramIcon />
                            </Link>
                            <Link
                                href="https://twitter.com/threesixty_me"
                                className="whiteText"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ margin: "5px 10px 5px 10px" }}
                            >
                                <XIcon />
                            </Link>
                            {/* <YouTubeIcon /> */}
                        </Box>
                    </Grid>
                </Grid>

                <Divider
                    sx={{ marginY: "2rem", backgroundColor: customTheme.palette.secondary.main }}
                />

                <Typography component="p" variant="pageBodySmall" textAlign="center">
                    © {new Date().getFullYear()} threesixtyme | All Rights Reserved
                </Typography>
            </Box>
        </footer>
    );
};

export default Footer;
