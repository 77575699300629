import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as Schemas from "../../../utils/validationHelper";
import { getErrorMessage } from "../../../utils/errorHelper";
import UserProfileController from "../../../controllers/UserProfileController";
import BasePage from "../../BasePage";
import InputComp from "../../../components/base_components/InputComp";
import ButtonComp from "../../../components/base_components/ButtonComp";
import ErrorMsgComp from "../../../components/base_components/ErrorMsgComp";
import PageBackgroundContainer from "../../../components/templates/PageBackgroundContainer";
import BackgroundImg from "../../../assets/images/background_3_grouped.png";

const emptyFormValues = {
    address: {
        line_1: "",
        line_2: "",
        suburb: "",
        city: "",
        postal_code: "",
    },
};

const PersonalAddressForm = ({ ...props }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(true);
    const [initValues, setInitValues] = useState(emptyFormValues);
    const [errorMsg, setErrorMsg] = useState("");

    const validations = Yup.object({
        address: Schemas.getSchemaForAddress(),
    });

    useEffect(() => {
        let mounted = true;

        Promise.allSettled([getUserDetails(mounted)]).then(() => {
            setIsLoading(false);
        });

        return () => {
            mounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserDetails = (mounted) => {
        return new Promise(async (res, rej) => {
            try {
                const profileResponse = await UserProfileController.getProfile();

                if (!profileResponse || Object.keys(profileResponse).length <= 0) {
                    setErrorMsg(
                        "No user details were received from the server. Please try again later"
                    );
                    rej();
                    return;
                }

                if (mounted) {
                    setInitValues(profileResponse);
                }

                res(profileResponse);
            } catch (error) {
                const errMsg = getErrorMessage(error);

                // Check if request was aborted
                if (errMsg === null) return;

                enqueueSnackbar(errMsg, { variant: "error" });
                rej(errMsg);
            }
        });
    };

    const handleSubmit = async (values, actions) => {
        try {
            if (!values.income) values.income = 0;

            await UserProfileController.updateProfile(values);

            setInitValues(values);
            actions.resetForm();
            actions.setSubmitting(false);
        } catch (error) {
            const errMsg = getErrorMessage(error);

            // Check if request was aborted
            if (errMsg === null) return;

            enqueueSnackbar(errMsg, { variant: "error" });
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const getDisabledState = (formIsSubmmitting) => {
        return formIsSubmmitting || isLoading || errorMsg?.length > 0;
    };

    return (
        <BasePage>
            <PageBackgroundContainer handleBack={handleBack} backgroundImg={BackgroundImg}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: { xs: "5rem", sm: 0 },
                    }}
                >
                    {isLoading && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Typography component="h1" variant="pageTitle1" marginBottom={5}>
                                Loading Your Information
                            </Typography>
                            <CircularProgress size="5rem" />
                        </Box>
                    )}

                    {!isLoading && (
                        <Box
                            sx={{
                                width: "100%",
                                maxWidth: "800px",
                            }}
                        >
                            {/* Page title */}
                            <Typography component="h1" variant="pageTitle2" marginBottom={5}>
                                Address Details
                            </Typography>

                            {/* Form contents */}
                            <Formik
                                initialValues={initValues}
                                enableReinitialize
                                validationSchema={validations}
                                onSubmit={handleSubmit}
                                validateOnBlur={false}
                            >
                                {({
                                    values,
                                    isSubmitting,
                                    dirty,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                }) => (
                                    <Form noValidate>
                                        <Grid container spacing={2}>
                                            {/* Line 1 input */}
                                            <Grid item xs={12}>
                                                <InputComp
                                                    labelText="Line 1"
                                                    type="text"
                                                    id="address.line_1"
                                                    required
                                                    autoComplete="address line 1"
                                                    value={values.address.line_1}
                                                    disabled={getDisabledState(isSubmitting)}
                                                    touched={touched}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>

                                            {/* Line 2 input */}
                                            <Grid item xs={12}>
                                                <InputComp
                                                    labelText="Line 2"
                                                    type="text"
                                                    id="address.line_2"
                                                    autoComplete="address line 2"
                                                    value={values.address.line_2}
                                                    disabled={getDisabledState(isSubmitting)}
                                                    touched={touched}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>

                                            {/* Suburb input */}
                                            <Grid item xs={12}>
                                                <InputComp
                                                    labelText="Suburb"
                                                    type="text"
                                                    id="address.suburb"
                                                    required
                                                    autoComplete="suburb"
                                                    value={values.address.suburb}
                                                    disabled={getDisabledState(isSubmitting)}
                                                    touched={touched}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>

                                            {/* City input */}
                                            <Grid item xs={12} sm={8}>
                                                <InputComp
                                                    labelText="City"
                                                    type="text"
                                                    id="address.city"
                                                    name="address.city"
                                                    required
                                                    autoComplete="city"
                                                    value={values.address.city}
                                                    disabled={getDisabledState(isSubmitting)}
                                                    touched={touched}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>

                                            {/* Postal code input */}
                                            <Grid item xs={12} sm={4}>
                                                <InputComp
                                                    labelText="Postal/Zip Code"
                                                    type="text"
                                                    id="address.postal_code"
                                                    required
                                                    autoComplete="postal code"
                                                    value={values.address.postal_code}
                                                    disabled={getDisabledState(isSubmitting)}
                                                    touched={touched}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>

                                            {/* Submit button */}
                                            {dirty && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    container
                                                    spacing={2}
                                                    marginY={4}
                                                >
                                                    <Grid item xs={6}>
                                                        <ButtonComp
                                                            type="reset"
                                                            color="secondary"
                                                            isLoading={getDisabledState(
                                                                isSubmitting
                                                            )}
                                                        >
                                                            Cancel
                                                        </ButtonComp>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <ButtonComp
                                                            type="submit"
                                                            isLoading={getDisabledState(
                                                                isSubmitting
                                                            )}
                                                        >
                                                            Save
                                                        </ButtonComp>
                                                    </Grid>
                                                </Grid>
                                            )}

                                            {/* Error message */}
                                            {errorMsg && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    container
                                                    justifyContent="center"
                                                >
                                                    <ErrorMsgComp errorMessage={errorMsg} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    )}
                </Box>
            </PageBackgroundContainer>
        </BasePage>
    );
};

export default PersonalAddressForm;
