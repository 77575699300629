import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails, Box } from "@mui/material";

import ButtonComp from "../../../components/base_components/ButtonComp";
import ExpandCircleDownSharpIcon from "@mui/icons-material/ExpandCircleDownSharp";

const StyledAccordion = styled(Accordion)(({ theme, isExpanded }) => ({
    "&.MuiAccordion-root": {
        backgroundColor: isExpanded ? "#011528BF" : "#01152840",
        margin: 10,
        border: "none !important",
        borderRadius: "32px",
    },
    "&.Mui-expanded": {
        margin: 0,
        border: "none !important",
        borderRadius: "32px",
    },
    "& .MuiAccordionSummary-root, & .MuiAccordionDetails-root": {
        color: isExpanded ? "white" : "inherit",
        transition: "background-color 0.3s, color 0.3s",
        border: "none !important",
        borderRadius: "32px",
    },
    "& .MuiAccordion-root:before": { border: "none !important", borderRadius: "32px" },
    "& .MuiAccordionSummary-root, & .Mui-expanded": {
        border: "none !important",
        borderRadius: "32px",
    },
}));

const CustomAccordion = ({ data }) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const goToSignup = () => {
        navigate("../sign-up");
    };

    return (
        <>
            {data.map((item) => (
                <StyledAccordion
                    key={item.id}
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id)}
                    isExpanded={expanded === item.id ? 1 : 0}
                >
                    <AccordionSummary
                        expandIcon={
                            <ExpandCircleDownSharpIcon
                                key={item.id}
                                sx={{ opacity: expanded === item.id ? "0.75" : "0.5" }}
                            />
                        }
                    >
                        {item.title}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ backgroundColor: "transparent", paddingRight: "10px" }}>
                            {item.content}
                            {item.bottom && (
                                <Box
                                    sx={{
                                        backgroundColor: "#128A871A",
                                        padding: "24px",
                                        borderRadius: "32px",
                                        display: "flex",
                                        flexDirection: { xs: "column", md: "row" },
                                        justifyContent: "center",
                                        marginTop: item.id === "panel12" ? "-5px" : "20px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: { xs: "column", md: "row" },
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: "100%",
                                                marginBottom: { xs: "12px", md: "0px" },
                                                marginRight: { xs: "0px", md: "10px" },
                                                width: {
                                                    xs: "100%",
                                                    md: item?.centerButton ? "160px" : "75%",
                                                },
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {item.bottom}
                                        </Box>
                                        <Box
                                            sx={{
                                                width: "100%",
                                                maxWidth: {
                                                    xs: "250px",
                                                    md: item?.centerButton
                                                        ? "157px"
                                                        : item?.centerButton
                                                        ? "160px"
                                                        : "25%",
                                                },
                                                marginLeft: "10px",
                                            }}
                                        >
                                            <ButtonComp
                                                color="primary"
                                                onClick={goToSignup}
                                                sx={{
                                                    maxWidth: "250px",
                                                    height: "44px",
                                                    padding: { xs: "0.2rem", md: "unset" },
                                                }}
                                            >
                                                {item.buttonText}
                                            </ButtonComp>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        {item.logo && (
                            <Box sx={{ width: "100%", marginTop: item.bottom ? "10px" : "24px" }}>
                                {item.logo}
                            </Box>
                        )}
                    </AccordionDetails>
                </StyledAccordion>
            ))}
        </>
    );
};

export default CustomAccordion;
