import { Box } from "@mui/material";
import CarouselContainer from "../../../components/base_components/CarouselContainer";
import FeatureCard from "./FeatureCard";
import GraphIcon from "../../../assets/icons/graph.png";
import MagicHat from "../../../assets/icons/magic_hat.png";
import FlowerCorrect from "../../../assets/icons/flower_correct.png";

const items = [
    {
        icon: GraphIcon,
        title: "Credit profile analytics",
        content:
            "Explore the intricacies of your credit history, understand the factors shaping your score, and discover actionable insights to improve your financial standing. Explore, understand, and improve your journey with threesixty.me.",
    },
    {
        icon: MagicHat,
        title: "SmartCoach",
        content:
            "Gain access to handy videos, content, and tips while completing topic modules to boost your financial knowledge.",
    },
    {
        icon: FlowerCorrect,
        title: "SmartSolutions",
        content:
            "Discover the ideal financial solution for you. From a registered debt management programme to affordable insurance offerings. Empower yourself along your financial journey.",
    },
];

const FeatureCards = ({ isMobile }) => {
    return (
        <Box
            id="features"
            sx={{
                width: "100%",
                backgroundColor: "transparent",
                display: { xs: "block", md: "flex" },
                alignItems: "stretch",
                justifyContent: "center",
                gap: { md: "1vw", lg: "2vw" },
                position: "absolute",
                zIndex: 100,
                top: { xs: "70vh", sm: "70vh", md: "80vh" },
            }}
        >
            {isMobile ? (
                <CarouselContainer
                    simplifiedResponsive
                    items={items.map((item) => (
                        <FeatureCard title={item.title} content={item.content} icon={item.icon} />
                    ))}
                />
            ) : (
                items.map((item) => (
                    <FeatureCard title={item.title} content={item.content} icon={item.icon} />
                ))
            )}
        </Box>
    );
};

export default FeatureCards;
