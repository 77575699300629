import { Box, Grid, Rating, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { creditScoreRatingData } from "../../../constants/appData";
import BadgeComp from "../../../components/base_components/BadgeComp";

const ScoreDescriptions = ({ handleBack }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box>
            {/* Back button */}
            <Box
                onClick={handleBack}
                sx={{
                    cursor: "pointer",
                    display: "flex",
                    width: "fit-content",
                    marginBottom: "2rem",
                }}
            >
                <ExpandCircleDownIcon sx={{ transform: "rotate(90deg)", marginRight: "10px" }} />
                <Typography variant="body1" component="span">
                    back
                </Typography>
            </Box>

            <Typography component="h1" variant="pageTitleLarge" mb={{ xs: 4, md: 5 }}>
                What different scores mean
            </Typography>

            <Grid container mb={10}>
                {creditScoreRatingData
                    .filter((d) => d.ratingVal > 0)
                    .map((item, i) => (
                        <Grid key={i} item xs={12} md={6} lg={4} xl={3} padding={2}>
                            {/* Description title */}
                            <Typography component="h6" variant="pageTitle2SmallResp" gutterBottom>
                                {item.title}
                            </Typography>

                            <Box
                                my={2}
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                {/* Rating score */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: "column", md: "row" },
                                    }}
                                >
                                    <Typography component="span" variant="body1Resp" mr={1}>
                                        {item.ratingLowerVal} - {item.ratingUpperVal}
                                    </Typography>
                                    <Rating
                                        readOnly
                                        value={item.ratingVal}
                                        size={isMobile ? "small" : "medium"}
                                    />
                                </Box>

                                {/* Rating description */}
                                <BadgeComp
                                    icon={item.icon}
                                    description={item.descriptionShort}
                                    backgroundColor={item.backgroundColor}
                                />
                            </Box>

                            {/* Score full description */}
                            <Typography
                                component="p"
                                variant="body1Resp"
                                textAlign="justify"
                                mt={1}
                            >
                                {item.descriptionFull}
                            </Typography>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default ScoreDescriptions;
