import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    AppBar,
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Tooltip,
    Typography,
    Divider,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpIcon from "@mui/icons-material/Help";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CancelIcon from "@mui/icons-material/Cancel";
import Collapse from "@mui/material/Collapse";

import { useAuthContext } from "../../contexts/AuthContext";
import HideOnScroll from "../wrappers/HideOnScrollWrapper";
import LogoFullImg from "../../assets/logos/logo_full_transparent.png";
import LogoSmallImg from "../../assets/logos/logo_small_transparent.png";
import MagicHand from "../../assets/icons/magic_hand.png";
import MagicHandSelected from "../../assets/icons/magic_hand_selected.png";
import MagicBook from "../../assets/icons/magic_book.png";
import MagicBookSelected from "../../assets/icons/magic_book_selected.png";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

function NavigationBar(props) {
    const navigate = useNavigate();
    const { logout } = useAuthContext();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isSmallMobile = useMediaQuery(theme.breakpoints.down("vs"));

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openDropdown, setOpenDrawdown] = useState(false);

    useEffect(() => {
        const internalLink = [
            "/profile/change-password",
            "/profile/address-details",
            "/profile/pi-details",
        ];

        if (internalLink.includes(location.pathname)) {
            setOpenDrawdown(true);
        }
    }, [location.pathname]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const Logout = () => logout(false, false, "/");

    const links = [
        {
            text: "Overview",
            icon: <HomeIcon />,
            path: "/overview",
            includeDivider: false,
            hideForMobile: false,
            expandable: false,
        },
        {
            text: "Accounts",
            icon: <CreditCardIcon />,
            path: "/accounts",
            includeDivider: false,
            hideForMobile: false,
            expandable: false,
        },
        {
            text: "SmartCoach",
            icon: <img src={MagicHand} alt="laptop" height="24px" style={{ aspectRatio: "1" }} />,
            path: "/smart-coach",
            includeDivider: false,
            hideForMobile: false,
            selected: (
                <img
                    src={MagicHandSelected}
                    alt="laptop"
                    height="24px"
                    style={{ aspectRatio: "1" }}
                />
            ),
            expandable: false,
        },
        {
            text: "SmartSolutions",
            icon: <img src={MagicBook} alt="laptop" height="24px" style={{ aspectRatio: "1" }} />,
            path: "/smart-solutions",
            includeDivider: false,
            hideForMobile: false,
            selected: (
                <img
                    src={MagicBookSelected}
                    alt="laptop"
                    height="24px"
                    style={{ aspectRatio: "1" }}
                />
            ),
            expandable: false,
        },
        {
            text: "My Profile",
            icon: <AccountCircleRoundedIcon />,
            path: "/profile",
            includeDivider: false,
            hideForMobile: true,
            expandable: true,
            items: [
                {
                    text: "My Information",
                    icon: null,
                    path: "/profile/pi-details",
                    includeDivider: false,
                    hideForMobile: true,
                    belongTo: "My Profile",
                },
                {
                    text: "My Address",
                    icon: null,
                    path: "/profile/address-details",
                    includeDivider: false,
                    hideForMobile: true,
                    belongTo: "My Profile",
                },
                {
                    text: "Change Password",
                    icon: null,
                    path: "/profile/change-password",
                    includeDivider: false,
                    hideForMobile: true,
                },
            ],
        },
        {
            text: "Contact Us",
            icon: <ContactSupportIcon />,
            path: "/contact-us",
            includeDivider: true,
            hideForMobile: true,
            expandable: false,
        },
        {
            text: "Logout",
            icon: <LogoutIcon />,
            path: "/",
            includeDivider: false,
            hideForMobile: true,
            expandable: false,
        },
    ];

    const drawer = (
        <Box>
            {!isMobile && (
                <Toolbar>
                    <Box display="flex" justifyContent="center" width="100%">
                        <img
                            src={LogoFullImg}
                            alt="Logo"
                            style={{ width: "80%", maxWidth: "200px" }}
                        />
                    </Box>
                </Toolbar>
            )}
            {
                <Box>
                    <List>
                        {links.map(
                            (link, i) =>
                                (!link.hideForMobile || !isMobile) && (
                                    <React.Fragment key={i}>
                                        {link.includeDivider && !isMobile && <ListItem divider />}
                                        {/* <Link to={link.path}> */}
                                        <Tooltip
                                            disableHoverListener
                                            disableTouchListener={!isSmallMobile}
                                            title={link.text}
                                        >
                                            <ListItemButton
                                                onClick={() =>
                                                    link.text === "Logout"
                                                        ? Logout()
                                                        : link.expandable
                                                        ? setOpenDrawdown(!openDropdown)
                                                        : navigate(link.path)
                                                }
                                                selected={link.path === location.pathname}
                                                sx={
                                                    link.icon === null
                                                        ? { paddingY: "0 !important" }
                                                        : {}
                                                }
                                            >
                                                <ListItemIcon>
                                                    {link.selected
                                                        ? link.path === location.pathname
                                                            ? link.selected
                                                            : link.icon
                                                        : link.icon}
                                                </ListItemIcon>
                                                {!isSmallMobile && (
                                                    <ListItemText
                                                        primary={link.text}
                                                        primaryTypographyProps={
                                                            link.icon === null
                                                                ? { fontSize: "0.8rem" }
                                                                : {}
                                                        }
                                                    />
                                                )}
                                                {link?.expandable ? (
                                                    openDropdown ? (
                                                        <ExpandCircleDownIcon
                                                            sx={{
                                                                transform: "rotate(180deg)",
                                                                color: "#D9D9D980",
                                                            }}
                                                        />
                                                    ) : (
                                                        <ExpandCircleDownIcon
                                                            sx={{ color: "#D9D9D980" }}
                                                        />
                                                    )
                                                ) : (
                                                    <></>
                                                )}
                                            </ListItemButton>
                                            {link.items && (
                                                <Collapse in={openDropdown} timeout="auto">
                                                    <List component="div" disablePadding>
                                                        {link.items.map((item) => {
                                                            return (
                                                                <ListItemButton
                                                                    onClick={() => {
                                                                        // setSelectedLink(item.path);
                                                                        navigate(item.path);
                                                                    }}
                                                                    selected={
                                                                        item.path ===
                                                                        location.pathname
                                                                    }
                                                                    sx={
                                                                        item.icon === null
                                                                            ? {
                                                                                  paddingY:
                                                                                      "0 !important",
                                                                              }
                                                                            : {}
                                                                    }
                                                                >
                                                                    <ListItemIcon>
                                                                        {item.selected
                                                                            ? item.path ===
                                                                              location.pathname
                                                                                ? item.selected
                                                                                : item.icon
                                                                            : item.icon}
                                                                    </ListItemIcon>
                                                                    {!isSmallMobile && (
                                                                        <ListItemText
                                                                            primary={item.text}
                                                                            primaryTypographyProps={
                                                                                item.icon === null
                                                                                    ? {
                                                                                          fontSize:
                                                                                              "0.8rem",
                                                                                      }
                                                                                    : {}
                                                                            }
                                                                        />
                                                                    )}
                                                                </ListItemButton>
                                                            );
                                                        })}
                                                    </List>
                                                </Collapse>
                                            )}
                                        </Tooltip>
                                        {/* </Link> */}
                                    </React.Fragment>
                                )
                        )}
                    </List>
                </Box>
            }
        </Box>
    );

    return (
        <Box sx={{ height: "100vh" }}>
            {isMobile && (
                <HideOnScroll>
                    <AppBar>
                        <Toolbar sx={{ justifyContent: "space-between" }}>
                            <Box display="flex" justifyContent="center" width="4rem">
                                <img
                                    src={LogoSmallImg}
                                    alt="Logo"
                                    style={{ width: "80%", maxWidth: "80px" }}
                                />
                            </Box>
                            <Box display="flex" justifyContent="center" width="4rem">
                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleClick}
                                        id="basic-button"
                                        aria-controls={open ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? "true" : undefined}
                                    >
                                        <AccountCircleRoundedIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>

                                <div>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            "aria-labelledby": "basic-button",
                                        }}
                                        sx={{
                                            backgroundColor: "transparent",
                                            top: "0px",
                                            "& .MuiMenu-paper": {
                                                backgroundColor: `transparent`,
                                                borderRadius: "1rem",
                                                backdropFilter: "blur(5px)",
                                            },
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                navigate("/profile");
                                                handleClose();
                                            }}
                                            sx={{
                                                color: "#D9D9D990",
                                                width: "100vw",
                                                maxWidth: "300px",
                                            }}
                                        >
                                            <IconButton
                                                sx={{ color: "#D9D9D990" }}
                                                xs={{ color: "#D9D9D990" }}
                                            >
                                                <AccountCircleRoundedIcon
                                                    fontSize="medium"
                                                    sx={{ color: "#D9D9D990" }}
                                                />
                                            </IconButton>
                                            <Typography variant="body" color="#D9D9D990">
                                                My Profile
                                            </Typography>
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                navigate("/contact-us");
                                                handleClose();
                                            }}
                                            sx={{
                                                color: "#D9D9D990",
                                                width: "100vw",
                                                maxWidth: "300px",
                                            }}
                                        >
                                            <IconButton
                                                sx={{ color: "#D9D9D990" }}
                                                xs={{ color: "#D9D9D990" }}
                                            >
                                                <HelpIcon
                                                    fontSize="medium"
                                                    sx={{ color: "#D9D9D990" }}
                                                />
                                            </IconButton>
                                            <Typography variant="body" color="#D9D9D990">
                                                Contact-us
                                            </Typography>
                                        </MenuItem>
                                        <Divider
                                            sx={{
                                                maxWidth: "250px",
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                                color: "#D9D9D990",
                                            }}
                                        />
                                        <MenuItem
                                            onClick={() => {
                                                navigate("/");
                                                Logout();
                                                handleClose();
                                            }}
                                            sx={{
                                                color: "#D9D9D990",
                                                width: "100vw",
                                                maxWidth: "300px",
                                            }}
                                        >
                                            <IconButton>
                                                <CancelIcon
                                                    fontSize="medium"
                                                    sx={{ color: "#D9D9D990" }}
                                                />
                                            </IconButton>
                                            <Typography variant="body" color="#D9D9D990">
                                                Logout
                                            </Typography>
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
            )}

            <Drawer
                variant="persistent"
                anchor={isMobile ? "bottom" : "left"}
                open={true}
                transitionDuration={1000}
            >
                {drawer}
            </Drawer>

            <Box sx={{ padding: isMobile ? "4rem 1rem" : "2rem 2rem 2rem 16.5rem" }}>
                {props.children}
            </Box>
        </Box>
    );
}

export default NavigationBar;
