import { Box, Typography } from "@mui/material";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import customTheme from "../constants/Theme";
import { creditScoreRatingData } from "../constants/appData";

export function safeParseJSON(jsonString) {
    if (typeof jsonString === "object") {
        // Variable is already an object, no need to parse
        return jsonString;
    }

    if (jsonString === undefined || jsonString === null || typeof jsonString !== "string") {
        // Not a valid variable type, return null
        return null;
    }

    try {
        // Try to parse the string
        const jsonObj = JSON.parse(jsonString);
        return jsonObj;
    } catch {
        // Not a valid JSON string, return null
        return null;
    }
}

export const currencyFormatter = new Intl.NumberFormat("af", {
    style: "currency",
    currency: "ZAR",
    minimumFractionDigits: 2,
});

export const roundNum = (num, decimals = 2) => {
    if (isNaN(num)) return num;

    return parseFloat(num.toFixed(decimals));
};

export const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
};

export function setColorOpacity(colorString, opacity) {
    // Function to convert hex to rgba
    function hexToRgba(hex, opacity) {
        hex = hex.replace("#", "");
        let r, g, b, a;
        if (hex.length === 3) {
            r = parseInt(hex[0] + hex[0], 16);
            g = parseInt(hex[1] + hex[1], 16);
            b = parseInt(hex[2] + hex[2], 16);
            a = opacity;
        } else if (hex.length === 6) {
            r = parseInt(hex.slice(0, 2), 16);
            g = parseInt(hex.slice(2, 4), 16);
            b = parseInt(hex.slice(4, 6), 16);
            a = opacity;
        } else if (hex.length === 8) {
            r = parseInt(hex.slice(0, 2), 16);
            g = parseInt(hex.slice(2, 4), 16);
            b = parseInt(hex.slice(4, 6), 16);
            a = parseInt(hex.slice(6, 8), 16) / 255;
        } else {
            return "Invalid color format";
        }
        return `rgba(${r}, ${g}, ${b}, ${a})`;
    }

    // Check if the colorString is in rgba format
    if (colorString.toLowerCase().startsWith("rgb")) {
        const rgbaValues = colorString
            .substring(colorString.indexOf("(") + 1, colorString.lastIndexOf(")"))
            .split(",");

        rgbaValues[3] = opacity;
        return `rgba(${rgbaValues.join(", ")})`;
    }

    // Check if the colorString is a hex color
    if (colorString.startsWith("#")) {
        return hexToRgba(colorString, opacity);
    }

    return "Invalid color format";
}

export const delay = async (ms) => {
    return new Promise((res) => {
        setTimeout(res, ms);
    });
};

export const normaliseString = (value) => {
    return (value || "")
        .toLowerCase()
        .replace("_", " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const getMonthName = (monthNum, fullName = true) => {
    if (monthNum >= 1 && monthNum <= 12) {
        const date = new Date(2000, monthNum - 1, 1);
        return date.toLocaleString("en-US", { month: fullName ? "long" : "short" });
    } else {
        return "Invalid month number";
    }
};

export const formatDate = (inputDateString) => {
    if (!inputDateString) return null;

    // Try to create a Date object from the input string
    const inputDate = new Date(inputDateString);

    // Check if the created Date object is a valid date
    if (isNaN(inputDate.getTime())) {
        console.error("Invalid date string. Please provide a valid date.");
        return null;
    }

    // Convert the date string to the desired format
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDateString = inputDate.toLocaleDateString("en-GB", options);

    return formattedDateString;
};

export const overrideTypographyStyle = (styleName) => {
    return {
        fontSize: `${customTheme.typography[styleName].fontSize} !important`,
        fontWeight: `${customTheme.typography[styleName].fontWeight} !important`,
    };
};

export const getEmptyFolderCard = (text) => (
    <Box
        sx={{
            width: "100%",
            paddingY: { xs: "1rem", sm: "2rem" },
            paddingX: { xs: "0.5rem", sm: "1rem" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "2rem",
            backgroundColor: `${customTheme.palette.brandedTeal.main}0d`,
        }}
    >
        <FolderOffIcon
            sx={{
                display: { xs: "none", sm: "inline-block" },
                color: customTheme.palette.brandedTeal.main,
                fontSize: { xs: "3rem", sm: "5rem" },
            }}
        />
        <Typography
            color={customTheme.palette.brandedTeal.main}
            component="p"
            variant="pageBodyBoldResp"
            marginTop={{ xs: 0, sm: 2 }}
            textAlign="center"
        >
            {text}
        </Typography>
    </Box>
);

export const getCreditScoreRatingBadgeDetails = (score) => {
    const badge = creditScoreRatingData.find(
        (d) => score >= d.ratingLowerVal && score <= d.ratingUpperVal
    );

    if (!badge) {
        return creditScoreRatingData[0];
    }

    return badge;
};

export const getEmbeddedYouTubeURL = (url) => {
    if (!url.toLowerCase().includes("youtube")) return url;

    // Get the YT video ID (credit: https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url)
    const splitURL = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    const ytID = splitURL[2] !== undefined ? splitURL[2].split(/[^0-9a-z_-]/i)[0] : splitURL[0];

    // Embed the video ID in the appropriate URL template
    return `https://www.youtube.com/embed/${ytID}`;
};

export const getEmbeddedVimeoURL = (url) => {
    if (!url.toLowerCase().includes("vimeo")) return url;

    const splitURL = url.split(/vimeo\.com\/([^/?]+)/);
    const vidID = splitURL[1];

    // Embed the video ID in the appropriate URL template
    return `https://player.vimeo.com/video/${vidID}`;
};
