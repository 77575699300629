import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, AppBar, Toolbar } from "@mui/material";

import ButtonComp from "../../components/base_components/ButtonComp";
import LogoFullImg from "../../assets/logos/logo_full_transparent.png";
import HideOnScroll from "../wrappers/HideOnScrollWrapper";

const CustomAppBar = () => {
    const navigate = useNavigate();

    const goToLogin = () => {
        navigate("../login");
    };

    const goToSignup = () => {
        navigate("../sign-up");
    };

    return (
        <HideOnScroll>
            <AppBar>
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                        padding: { xs: "1rem !important", md: "1.5rem 4rem !important" },
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => navigate("..")}
                        sx={{ cursor: "pointer" }}
                    >
                        <Box sx={{ height: { xs: "22px", md: "30px" }, paddingX: '8px' }}>
                            <img src={LogoFullImg} alt="Logo" height="100%" />
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="space-between"
                        sx={{ width: { xs: "100px", md: "350px" } }}
                    >
                        <ButtonComp
                            color="secondary"
                            onClick={goToLogin}
                            sx={{ maxWidth: "165px", padding: { vs: "0.2rem", md: "unset" } }}
                        >
                            Login
                        </ButtonComp>
                        <ButtonComp
                            onClick={goToSignup}
                            sx={{ maxWidth: "165px", display: { xs: "none", md: "inherit" } }}
                        >
                            Get Started
                        </ButtonComp>
                    </Box>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    );
};

export default CustomAppBar;
