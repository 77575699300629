import { Link } from "@mui/material";

const LinkComp = (props) => {
    return (
        <Link {...props} variant="body2" style={{ pointerEvents: props.disabled ? "none" : "" }}>
            {props.children}
        </Link>
    );
};

export default LinkComp;
