import { Box, Typography } from "@mui/material";
import ButtonComp from "../../../components/base_components/ButtonComp";

const OtpFailed = ({ submitCallback, ...props }) => {
    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                transform: "translateY(-40px)",
            }}
        >
            <Typography component="h1" variant="pageTitle1" textAlign="center" mb={4}>
                Whoops!
            </Typography>

            <Typography component="p" variant="body1" textAlign="center" mb={4}>
                Your account has been created successfully, but we could not verify your contact
                details.
            </Typography>

            <Typography component="p" variant="body1" textAlign="center" mb={4}>
                Please answer the following validation questions to complete the validation process
            </Typography>

            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <ButtonComp onClick={submitCallback}>Continue</ButtonComp>
            </Box>
        </Box>
    );
};

export default OtpFailed;
