import { useState, forwardRef, useImperativeHandle } from "react";
import {
    Box,
    TextField,
    InputLabel,
    Fade,
    FormHelperText,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HelpIcon from "@mui/icons-material/Help";
import { passwordRules } from "../../utils/validationHelper";

const InputComp = forwardRef(
    ({ labelText, helperText, toolTip, showPasswordHint, ...props }, ref) => {
        const [showPassword, setShowPassword] = useState(false);
        const [numChars, setNumChars] = useState(0);

        const resetNumChars = () => {
            /*
            Need to expose this function to the parent container by means of forwardRef & useImperativeHandle such that it can be called upon form reset. 
            Apparently there's no event triggered on the inputs when Formik's resetForm function is called
         */
            setNumChars(0);
        };

        const getError = () => {
            if (!props.touched || !props.errors) return null;

            // Cater for nested properties
            const propNames = props.id.split(".");
            let touched = props.touched;
            let error = props.errors;

            for (let propName of propNames) {
                touched = touched !== undefined ? touched[propName] : undefined;
                error = error !== undefined ? error[propName] : undefined;
            }

            return touched && error ? error : null;
        };

        const handleShowPassword = (event) => {
            setShowPassword(!showPassword);
        };

        const handleInputChanged = (event) => {
            setNumChars(event.target.value?.length || 0);

            if (props.onChange) props.onChange(event);
        };

        useImperativeHandle(ref, () => ({ resetNumChars }));

        return (
            <Box sx={{ width: "100%" }}>
                <InputLabel sx={{ marginBottom: "2px" }} required={props.required}>
                    {labelText}
                    {(showPasswordHint || toolTip?.show) && (
                        <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            arrow
                            placement="top-end"
                            title={
                                <>
                                    <Typography component="p" variant="tooltipTitle" gutterBottom>
                                        {toolTip?.title || "Password Requirements:"}
                                    </Typography>
                                    {showPasswordHint ? (
                                        <ul>
                                            {passwordRules.map((rule, i) => (
                                                <li key={i}>
                                                    <Typography component="p" variant="tooltipBody">
                                                        {rule}
                                                    </Typography>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <Typography component="p" variant="tooltipBody">
                                            {toolTip.message}
                                        </Typography>
                                    )}
                                </>
                            }
                        >
                            <HelpIcon
                                //color="brandedTeal"
                                sx={{
                                    float: "right",
                                    marginRight: "10px",
                                    "&: hover": {
                                        cursor: "pointer",
                                    },
                                }}
                            />
                        </Tooltip>
                    )}
                </InputLabel>

                {helperText && <FormHelperText>{helperText}</FormHelperText>}

                <TextField
                    {...props}
                    error={Boolean(getError())}
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : props.type}
                    InputProps={{
                        endAdornment: props.type === "password" && (
                            <IconButton onClick={handleShowPassword} edge="end" tabIndex={-1}>
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        ),
                        ...props.InputProps,
                    }}
                    sx={{
                        ...(props.multiline && {
                            borderRadius: "1rem",
                            "& .Mui-focused": {
                                borderRadius: "1rem",
                            },
                            "& .Mui-error": {
                                borderRadius: "1rem",
                            },
                        }),
                        ...props.sx,
                    }}
                    onChange={props.multiline ? handleInputChanged : props.onChange}
                >
                    {props.children}
                </TextField>

                {props.multiline && props.inputProps?.maxLength > 0 && (
                    <FormHelperText sx={{ textAlign: "end" }}>
                        {numChars}/{props.inputProps.maxLength}
                    </FormHelperText>
                )}

                {Boolean(getError()) && (
                    <FormHelperText error sx={{ textAlign: "end", fontWeight: 400 }}>
                        {getError()}
                    </FormHelperText>
                )}
            </Box>
        );
    }
);

export default InputComp;
