import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselItemComp from "./CarouselItemComp";

const CarouselContainer = ({ items, simplifiedResponsive, ...props }) => {
    // Use this responsive config for pages that do not have the navbar drawer
    const linearResp = {
        large_desktop: {
            breakpoint: { max: 3000, min: 1400 },
            items: 4,
            slidesToSlide: 3,
            partialVisibilityGutter: 30,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1201 },
            items: 3,
            slidesToSlide: 3,
            partialVisibilityGutter: 30,
        },
        small_desktop: {
            breakpoint: { max: 1200, min: 1025 },
            items: 3,
            partialVisibilityGutter: 50,
        },
        smalller_desktop: {
            breakpoint: { max: 1024, min: 901 },
            items: 3,
            partialVisibilityGutter: 15,
        },
        larger_tablet: {
            breakpoint: { max: 900, min: 801 },
            items: 2,
            partialVisibilityGutter: 75,
        },
        large_tablet: {
            breakpoint: { max: 800, min: 701 },
            items: 2,
            partialVisibilityGutter: 50,
        },
        tablet: {
            breakpoint: { max: 700, min: 601 },
            items: 2,
            partialVisibilityGutter: 10,
        },
        small_tablet: {
            breakpoint: { max: 600, min: 501 },
            items: 1,
            partialVisibilityGutter: 150,
        },
        large_mobile: {
            breakpoint: { max: 500, min: 401 },
            items: 1,
            partialVisibilityGutter: 100,
        },
        mobile: {
            breakpoint: { max: 400, min: 0 },
            items: 1,
            partialVisibilityGutter: 30,
        },
    };

    // Use this responsive config for pages that have the navbar drawer
    const navbarResp = {
        large_desktop: {
            breakpoint: { max: 3000, min: 1400 },
            items: 4,
            slidesToSlide: 3,
            partialVisibilityGutter: 30,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1201 },
            items: 3,
            slidesToSlide: 3,
            partialVisibilityGutter: 30,
        },
        small_desktop: {
            breakpoint: { max: 1200, min: 1025 },
            items: 2,
            partialVisibilityGutter: 120,
        },
        smalller_desktop: {
            breakpoint: { max: 1024, min: 851 },
            items: 2,
            partialVisibilityGutter: 30,
        },
        larger_tablet: {
            breakpoint: { max: 850, min: 801 },
            items: 1,
            partialVisibilityGutter: 175,
        },
        large_tablet: {
            breakpoint: { max: 800, min: 701 },
            items: 1,
            partialVisibilityGutter: 150,
        },
        tablet: {
            breakpoint: { max: 700, min: 601 },
            items: 1,
            partialVisibilityGutter: 30,
        },
        small_tablet: {
            breakpoint: { max: 600, min: 501 },
            items: 1,
            partialVisibilityGutter: 150,
        },
        large_mobile: {
            breakpoint: { max: 500, min: 401 },
            items: 1,
            partialVisibilityGutter: 75,
        },
        mobile: {
            breakpoint: { max: 400, min: 0 },
            items: 1,
            partialVisibilityGutter: 30,
        },
    };

    const config = {
        swipeable: true,
        draggable: true,
        showDots: false,
        arrows: false,
        infinite: true,
        autoPlay: false,
        partialVisible: true,
        responsive: simplifiedResponsive ? linearResp : navbarResp,
    };

    return (
        <Carousel {...config}>
            {items.map((item, index) => (
                <CarouselItemComp key={index} item={item} />
            ))}
        </Carousel>
    );
};

export default CarouselContainer;
