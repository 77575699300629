import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { formatDate, normaliseString } from "../../../utils/generalHelper";
import AccordionGroupComp from "../../../components/base_components/AccordionGroupComp";

const OtherListingsContainer = ({ listingGroups, ...props }) => {
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                paddingBottom: { xs: "5rem", sm: 0 },
            }}
        >
            {listingGroups?.length > 0 ? (
                <Grid container spacing={1}>
                    {/* Listing accordions */}
                    <Grid item xs={12} md={7}>
                        {listingGroups
                            .filter((l) => l.groupName !== "inquiries")
                            .map((listingGroup, i) => (
                                <Box
                                    key={i}
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        marginY: 2,
                                    }}
                                >
                                    {/* Group header & icon */}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: 1,
                                        }}
                                    >
                                        {listingGroup.groupIcon}
                                        <Typography component="span" variant="body1BoldResp" ml={1}>
                                            {normaliseString(listingGroup.groupName)}
                                        </Typography>
                                    </Box>

                                    {/* Accordion group for listings */}
                                    {!(listingGroup.listings?.length > 0) ? (
                                        // Default empty state
                                        listingGroup.emptyComp
                                    ) : (
                                        // Grouped listings accordions
                                        <AccordionGroupComp
                                            accordions={listingGroup.listings.map((listing, l) => {
                                                return {
                                                    headerOverride: listing.headerComp,
                                                    contentItems: listing.contentFields,
                                                };
                                            })}
                                        />
                                    )}
                                </Box>
                            ))}
                    </Grid>

                    {/* Inquiries */}
                    <Grid item xs={12} md={5}>
                        {listingGroups
                            .filter((l) => l.groupName === "inquiries")
                            .map((listingGroup, i) => (
                                <Box
                                    key={i}
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        marginY: 2,
                                    }}
                                >
                                    {/* Group header & icon */}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: 1,
                                        }}
                                    >
                                        {listingGroup.groupIcon}
                                        <Typography component="span" variant="body1BoldResp" ml={1}>
                                            {normaliseString(listingGroup.groupName)}
                                        </Typography>
                                    </Box>

                                    {/* Table listings for inquiries */}
                                    {!(listingGroup.listings?.length > 0) ? (
                                        // Default empty state
                                        listingGroup.emptyComp
                                    ) : (
                                        <TableContainer
                                            style={{
                                                maxHeight: 500,
                                                overflowY: "auto",
                                            }}
                                        >
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography variant="body1Resp">
                                                                Company Name
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="body1Resp">
                                                                Search Date
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {listingGroup.listings.map((listing, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <Typography variant="body1BoldResp">
                                                                    {normaliseString(
                                                                        listing.company_name
                                                                    ).trim()}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography variant="body1BoldResp">
                                                                    {formatDate(
                                                                        listing.search_date
                                                                    ) || "No date set"}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                </Box>
                            ))}
                    </Grid>
                </Grid>
            ) : (
                <Typography component="p" variant="body1" textAlign="center">
                    No listings to display
                </Typography>
            )}
        </Box>
    );
};

export default OtherListingsContainer;
