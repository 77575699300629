import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ButtonComp from "../../../components/base_components/ButtonComp";
import BackgroundCtaImg from "../../../assets/images/background_1_full.jpg";
import PhonesImg from "../../../assets/images/phone-landing.png";
import LogoCreditSmart from "../../../assets/logos/logo_credit_smart.png";
import LogoVCCB from "../../../assets/logos/logo_vccb.png";

const StyledBox = styled(Box)(({ theme }) => ({
    position: "relative",
    overflow: "hidden",
    "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${BackgroundCtaImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        opacity: 0.2,
        zIndex: 1,
    },
    "& > *": {
        position: "relative",
        zIndex: 2,
    },
}));

const CallToActionSection = ({ signup, theme, isMobile, ...props }) => {
    return (
        <section id="cta">
            <StyledBox
                pt={5}
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column-reverse", md: "row" },
                    justifyContent: "space-between",
                }}
            >
                {/* Section image */}
                <Box
                    pt={2}
                    sx={{
                        width: { xs: "100%", md: "50vw" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={PhonesImg}
                        alt="iphones"
                        style={{
                            width: "100%",
                        }}
                    />
                </Box>

                {/* Section text */}
                <Box
                    sx={{
                        width: { xs: "100%", md: "50vw" },
                        padding: { xs: "1rem" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h2" variant="pageTitleLarge">
                        Sign up today
                    </Typography>

                    <Typography component="p" variant="body1Resp" textAlign="center" mt={2}>
                        and start expanding your credit management landscape with the support you
                        deserve.
                    </Typography>

                    <ButtonComp onClick={signup} sx={{ marginY: "2rem", width: "70%" }}>
                        Get started for free
                    </ButtonComp>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-evenly",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            component="span"
                            variant="body1Bold"
                            marginX={{ xs: 0, md: 1 }}
                            marginY={{ xs: 1, md: 0 }}
                        >
                            by
                        </Typography>
                        <img
                            src={LogoCreditSmart}
                            alt="logo credit smart"
                            style={{ height: "30%", minHeight: "45px" }}
                        />
                        <Typography
                            component="span"
                            variant="body1Bold"
                            marginX={{ xs: 0, md: 1 }}
                            marginY={{ xs: 2, md: 0 }}
                        >
                            powered by
                        </Typography>
                        <img
                            src={LogoVCCB}
                            alt="logo vccb"
                            style={{ height: "30%", minHeight: "45px" }}
                        />
                    </Box>
                </Box>
            </StyledBox>
        </section>
    );
};

export default CallToActionSection;
