import BaseController from "./BaseController";
import { delay } from "../utils/generalHelper";

class Controller extends BaseController {
    constructor() {
        super();
        this.SignUpURL = "/sign-up";
        this.ResendOtpURL = "/otp/resend";
        this.ValidateMobileURL = "/sign-up/validate-mobile";
        this.ValidateOtpURL = "/sign-up/validate-mobile/otp";
        this.ValidateStatusURL = "/sign-up/status";
        this.SetPasswordURL = "/sign-up/set-password";
        this.SecurityQuestionsMobile = "/sign-up/security-question/mobile";
        this.SecurityQuestionsAddress = "/sign-up/security-question/address";
        this.DeleteAccounts = "/clear-user";
    }

    signUp = (body) => {
        return this.sendRequest("POST", this.SignUpURL, null, body);
    };

    resendOtp = (body) => {
        return this.sendRequest("POST", this.ResendOtpURL, null, body);
    };

    validateMobile = () => {
        return this.sendRequest("POST", this.ValidateMobileURL);
    };

    validateOtp = (body) => {
        return this.sendRequest("POST", this.ValidateOtpURL, null, body);
    };

    validateStatus = () => {
        return this.sendRequest("GET", this.ValidateStatusURL);
    };

    setPassword = (body) => {
        return this.sendRequest("POST", this.SetPasswordURL, null, body);
    };

    getSecurityQuestionsMobile = () => {
        return this.sendRequest("GET", this.SecurityQuestionsMobile);
    };

    postSecurityQuestionsMobile = (body) => {
        return this.sendRequest("POST", this.SecurityQuestionsMobile, null, body);
    };

    getSecurityQuestionsAddress = () => {
        return this.sendRequest("GET", this.SecurityQuestionsAddress);
    };

    postSecurityQuestionsAddress = (body) => {
        return this.sendRequest("POST", this.SecurityQuestionsAddress, null, body);
    };

    deleteAccounts = (body) => {
        return this.sendRequest("POST", this.DeleteAccounts, null, body);
    };

    checkStatus = async () => {
        let retries = 0;
        let result = {
            isReportPulled: false,
            isAccountVerified: false,
            isMobileVerified: false,
            errorMsg: "",
        };

        while (retries < 5) {
            const statusResponse = await this.validateStatus();

            if (!statusResponse) {
                // No response received, throw error
                throw Error("No response from server");
            }

            if (!statusResponse.credit_report_ready) {
                // Credit report couldn't be pulled, attempt to try again or cancel
                result.isReportPulled = false;

                if (retries > 3) {
                    // Too many retries, cancel and provide feedback to user
                    result.errorMsg =
                        "Your credit report couldn't be retrieved at this time, please try again later. We apologise for the inconvenience.";
                    break;
                } else {
                    // Try to pull credit report again
                    await delay(500);
                    retries++;
                    continue;
                }
            }

            result.isReportPulled = true;
            result.isAccountVerified = statusResponse.account_verified;
            result.isMobileVerified = statusResponse.mobile_verified;
            result.errorMsg = "";

            break;
        }

        return result;
    };
}

const SignUpController = new Controller();

export default SignUpController;
