import { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../utils/errorHelper";
import CreditProfileController from "../../controllers/CreditProfileController";
import UserProfileController from "../../controllers/UserProfileController";
import BasePage from "../BasePage";
import Overview from "./screens/Overview";
import OverviewDetailed from "./screens/OverviewDetailed";
import ScoreDescriptions from "./screens/ScoreDescriptions";

const OverviewPage = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(true);
    const [showDetailPage, setShowDetailPage] = useState(false);
    const [showDescriptionsPage, setShowDescriptionsPage] = useState(false);
    const [profileDetails, setProfileDetails] = useState({});

    useEffect(() => {
        let mounted = true;

        Promise.allSettled([getProfileDetails(), getUserDetails()])
            .then((responses) => {
                if (mounted) {
                    setProfileDetails({
                        ...responses[0].value,
                        ...responses[1].value,
                    });
                }
            })
            .finally(() => {
                if (mounted) {
                    setIsLoading(false);
                }
            });

        return () => {
            mounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProfileDetails = () => {
        return new Promise(async (res, rej) => {
            try {
                const profileResponse = await CreditProfileController.getProfile();

                if (!profileResponse || Object.keys(profileResponse).length <= 0) {
                    enqueueSnackbar(
                        "No profile details were received from the server. Please try again later",
                        { variant: "error" }
                    );
                    rej();
                    return;
                }

                res(profileResponse);
            } catch (error) {
                const errMsg = getErrorMessage(error);

                // Check if request was aborted
                if (errMsg === null) return;

                enqueueSnackbar(errMsg, { variant: "error" });
                rej(errMsg);
            }
        });
    };

    const getUserDetails = () => {
        return new Promise(async (res, rej) => {
            try {
                const profileResponse = await UserProfileController.getProfile();

                if (!profileResponse || Object.keys(profileResponse).length <= 0) {
                    enqueueSnackbar(
                        "No user details were received from the server. Please try again later",
                        { variant: "error" }
                    );
                    rej();
                    return;
                }

                res(profileResponse);
            } catch (error) {
                const errMsg = getErrorMessage(error);

                // Check if request was aborted
                if (errMsg === null) return;

                enqueueSnackbar(errMsg, { variant: "error" });
                rej(errMsg);
            }
        });
    };

    const handleBack = () => {
        setShowDetailPage(false);
        setShowDescriptionsPage(false);
    };

    const viewDetailPage = () => {
        setShowDetailPage(true);
    };

    const viewDescriptionsPage = () => {
        setShowDescriptionsPage(true);
    };

    return (
        <BasePage>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                }}
            >
                {isLoading === true && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80%",
                        }}
                    >
                        <Typography component="h1" variant="pageTitle1" gutterBottom>
                            Loading Your Credit Details
                        </Typography>
                        <CircularProgress size="5rem" />
                    </Box>
                )}

                {!isLoading &&
                    (showDetailPage === true ? (
                        <OverviewDetailed creditProfile={profileDetails} handleBack={handleBack} />
                    ) : showDescriptionsPage ? (
                        <ScoreDescriptions handleBack={handleBack} />
                    ) : (
                        <Overview
                            creditProfile={profileDetails}
                            viewDetailPage={viewDetailPage}
                            viewDescriptionsPage={viewDescriptionsPage}
                        />
                    ))}
            </Box>
        </BasePage>
    );
};

export default OverviewPage;
