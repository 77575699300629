import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ButtonComp from "../../../components/base_components/ButtonComp";
import HeroBackgroundImg from "../../../assets/images/background_1_new.jpg";
import MeDotsImg from "../../../assets/images/MeDotsLanding.png";
import LogoFullTextImg from "../../../assets/logos/logo_landing_small.png";

const StyledHeroSection = styled("section")(({ theme }) => ({
    height: "100vh",
    backgroundImage: `linear-gradient(
        to bottom, 
        ${theme.palette.background.default}0D 5%, 
        ${theme.palette.background.default}80 50%
      ), 
      url("${HeroBackgroundImg}")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.between("md", "lg")]: {
        height: "110vh",
    },
}));

const HeroSection = ({ signup, theme, isMobile, ...props }) => {
    return (
        <StyledHeroSection id="hero">
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    marginTop: { xs: "70.5px", vs: "64.88px", md: "96.5px" }, // App bar height
                    paddingTop: { xs: 0, sm: "15vh" },
                }}
            >
                <Box
                    sx={{
                        padding: "1rem",
                        color: "white",
                    }}
                >
                    <Typography
                        component="h1"
                        variant="pageTitleVeryLarge"
                        sx={{
                            fontSize: { xs: "47px !important", md: "55px !important" },
                        }}
                    >
                        Your Credit.
                    </Typography>
                    <Typography
                        component="h1"
                        variant="pageTitleVeryLarge"
                        sx={{
                            fontSize: { xs: "47px !important", md: "55px !important" },
                        }}
                    >
                        Your Story.
                    </Typography>
                    <Typography
                        component="h1"
                        variant="pageTitleVeryLarge"
                        sx={{
                            fontSize: { xs: "47px !important", md: "55px !important" },
                        }}
                    >
                        Your{" "}
                        <img
                            src={LogoFullTextImg}
                            alt="logo"
                            style={{
                                marginBottom: "-2.5%",
                                marginLeft: "4px",
                                width: "95%",
                                minWidth: "290px",
                                maxWidth: isMobile ? "340px" : "400px",
                            }}
                        />
                    </Typography>
                    <ButtonComp
                        onClick={signup}
                        sx={{
                            marginTop: { xs: "12px", md: "2rem" },
                            width: { xs: "160px", md: "70%" },
                            height: { xs: "44px", md: "100%" },
                        }}
                    >
                        Sign up for free
                    </ButtonComp>
                </Box>

                {!isMobile && (
                    <Box width="30vw">
                        <img src={MeDotsImg} alt="dots" width="100%" />
                    </Box>
                )}
            </Box>
        </StyledHeroSection>
    );
};

export default HeroSection;
