import React, { createContext, useContext, useState, useEffect } from "react";
import { isExpired } from "react-jwt";
import { SESSION_KEY, OTP_TOKEN_KEY } from "../constants/localStorageKeys";
import AuthController from "../controllers/AuthController";
import SignUpController from "../controllers/SignUpController";
import { clearAuthHeader, setAuthHeader, setUnauthorizedResponse } from "../utils/axiosHelper";
import { getAuthenticationFailedMsg } from "../utils/errorHelper";

const AuthContext = createContext();

// Custom hook to check whether it's called from within the provider
export function useAuthContext() {
    const contextValue = useContext(AuthContext);

    if (!contextValue) {
        throw new Error("useAuthContext must be called inside AuthContextProvider");
    }

    return contextValue;
}

export default function AuthContextProvider(props) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isFirstLogin, setIsFirstLogin] = useState(false);
    const [isChecking, setIsChecking] = useState(true);

    useEffect(() => {
        // Check if session is still active
        const session = JSON.parse(localStorage.getItem(SESSION_KEY));

        if (session?.token) {
            // Session is active, but token might be expired
            if (!isExpired(session.token)) {
                assignSession(session);
            } else {
                resetSession();
            }
        }

        // Configure the unauthenticated error response from axios
        setUnauthorizedResponse(() => logout(true, true));
        setIsChecking(false);

        return () => {
            abortApiRequests();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function abortApiRequests() {
        AuthController.abortAllRequests();
        SignUpController.abortAllRequests();
    }

    function assignSession(session, persistSession) {
        if (session?.token?.length > 0) {
            // Configure axios requests to use the session token
            setAuthHeader(session?.token);
            setIsAuthenticated(true);

            if (persistSession) localStorage.setItem(SESSION_KEY, JSON.stringify(session));

            return true;
        } else {
            // Session is invalid
            resetSession();
            return false;
        }
    }

    function resetSession() {
        clearAuthHeader();
        setIsAuthenticated(false);

        localStorage.removeItem(SESSION_KEY);
        localStorage.removeItem(OTP_TOKEN_KEY);
    }

    async function login(credentials, rememberMe) {
        try {
            const response = await AuthController.login(credentials);

            const session = {
                token: response?.session_token,
            };

            if (response?.first_login || response?.number_of_logins <= 1) {
                setIsFirstLogin(true);
            }

            if (assignSession(session, true)) {
                return response?.message;
            } else {
                return "Login failed. Invalid authorization token received from server";
            }
        } catch (error) {
            const authErrorMsg = getAuthenticationFailedMsg(error);

            if (!authErrorMsg) throw error;

            return authErrorMsg;
        }
    }

    function logout(navigateToLogin, isUnauthorized, path) {
        // Reset the auth context details and remove the tokens from the local storage
        resetSession();

        const navPath = navigateToLogin === true ? "/login" : path;

        if (navPath?.length > 0) {
            if (isUnauthorized === true) {
                window.history.pushState({ unauthorized: true }, "", navPath);
            }

            window.location.href = navPath;
        }
    }

    async function signup(userDetails) {
        const response = await SignUpController.signUp(userDetails);
        const signupToken = response?.session_token;
        const otpToken = response?.otp_token;

        if (signupToken?.length > 0) {
            setAuthHeader(signupToken);
            localStorage.setItem(OTP_TOKEN_KEY, JSON.stringify(otpToken));
        }
    }

    function cancelSignup() {
        logout(false);
    }

    async function resendOTP(verifyMobile) {
        try {
            if (verifyMobile === true) {
                const response = await SignUpController.validateMobile();
                const otpToken = response?.otp_token;

                if (otpToken?.length > 0) {
                    localStorage.setItem(OTP_TOKEN_KEY, JSON.stringify(otpToken));
                }
            } else {
                const otpToken = JSON.parse(localStorage.getItem(OTP_TOKEN_KEY));

                await SignUpController.resendOtp({
                    otp_token: otpToken,
                });
            }
        } catch (error) {
            const authErrorMsg = getAuthenticationFailedMsg(error);

            if (!authErrorMsg) throw error;

            return authErrorMsg;
        }
    }

    async function validateOTP(otp) {
        try {
            const otpToken = JSON.parse(localStorage.getItem(OTP_TOKEN_KEY));

            await SignUpController.validateOtp({
                otp: otp,
                otp_token: otpToken,
            });

            // OTP is successfully verified, remove its token from local storage
            localStorage.removeItem(OTP_TOKEN_KEY);
        } catch (error) {
            const authErrorMsg = getAuthenticationFailedMsg(error);

            if (!authErrorMsg) throw error;

            return authErrorMsg;
        }
    }

    async function requestPasswordReset(requestBody) {
        const response = await AuthController.requestResetPasswordToken(requestBody);
        const otpToken = response?.otp_token;

        if (otpToken?.length > 0) {
            localStorage.setItem(OTP_TOKEN_KEY, JSON.stringify(otpToken));
        }

        return response;
    }

    async function resetPassword(requestBody) {
        const otpToken = JSON.parse(localStorage.getItem(OTP_TOKEN_KEY));

        if (!(otpToken?.length > 0)) {
            return "No OTP token available";
        }

        requestBody = {
            ...requestBody,
            otp_token: otpToken,
        };

        const response = await AuthController.resetPassword(requestBody);

        localStorage.removeItem(OTP_TOKEN_KEY);

        return response;
    }

    async function sendPassword(password) {
        try {
            throw new Error("testing error thrown for password");
            // console.log("setting password = ", password);
            // const response = { session_token: "test session token" };
            // // const response = await SignUpController.signUp({
            // //     password: password,
            // // });

            // const session = {
            //     token: response.session_token,
            // };

            // setIsAuthenticated(true);
            // localStorage.setItem(SESSION_KEY, JSON.stringify(session));

            // return "";
        } catch (error) {
            const authErrorMsg = getAuthenticationFailedMsg(error);

            if (!authErrorMsg) throw error;

            return authErrorMsg;
        }
    }

    return isChecking ? null : (
        <AuthContext.Provider
            {...props}
            value={{
                isAuthenticated,
                isFirstLogin,
                login,
                logout,
                signup,
                cancelSignup,
                resendOTP,
                validateOTP,
                requestPasswordReset,
                resetPassword,
                sendPassword,
                abortApiRequests,
            }}
        />
    );
}
