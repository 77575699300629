import { Typography } from "@mui/material";
import LegalDocWrapper from "../../components/wrappers/LegalDocWrapper";

const PrivacyPolicy = ({ backToLanding, ...props }) => {
    return (
        <LegalDocWrapper backHandler={backToLanding}>
            {/* Title */}
            <Typography component="h3" variant="paperHeading" marginY={4}>
                Privacy Policy
            </Typography>

            {/* Who we are */}
            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                Who we are
            </Typography>
            <Typography component="p" variant="paperBody" textAlign="justify">
                ThreeSixty.me is a proprietary financial wellness tool developed and operated by
                CreditSmart Financial Services (Pty) Ltd (“CreditSmart” or “we”). ThreeSixty.me is
                not an independent entity but a platform that provides comprehensive financial
                services to consumers. Operating in South Africa, CreditSmart, depending on the
                circumstances, acts as either a “Responsible Party” or an “Operator” under the
                Protection of Personal Information Act, 4 of 2013 (POPIA) with respect to your
                Personal Information. We are committed to ensuring that you are fully aware of who
                we are, the types of Personal Information we process, how we process it, who we may
                need to share it with, and what your rights are in relation to our processing of
                your personal information under POPIA.
            </Typography>

            <br />

            {/* Personal information we process */}
            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                Personal information we process
            </Typography>
            <Typography component="p" variant="paperBody" textAlign="justify">
                We process Personal Information of our clients, employees, service providers,
                suppliers, and various other stakeholders who enable us to operate ThreeSixty.me
                (“Business Contacts”). This processing allows us to do business with them or provide
                our services to them as required. In this notice, “you” refers to you as an
                individual, with whom we are dealing, and whose Personal Information/Data we may
                need to collect and process in order to provide the requested services, or the
                entity you represent. We may need to collect and process your Personal
                Information/Data for the purposes described below.
            </Typography>

            <br />

            {/* Information collection and usage */}
            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                Information collection and usage
            </Typography>
            <ul>
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify" mb={2}>
                        <strong>Debt solutions:</strong>
                        <br />
                        <br />
                        When you use our debt solutions or complete the contact form on our site, we
                        collect personal information directly from you, including your name, contact
                        details such as email address and cell phone number, and ID number. We also
                        collect and store messages and conversations when you email, phone, or chat
                        with us.
                    </Typography>
                </li>
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify" mb={2}>
                        <strong>Debt counselling:</strong>
                        <br />
                        <br />
                        When you apply for our debt counselling services, we collect additional
                        personal information directly from you as required by the National Credit
                        Act (35 of 2005), including your marital status, number and age of
                        dependents, address, employment details, and financial information. We also
                        collect your credit score and credit report from a credit bureau.
                    </Typography>
                </li>
                <li>
                    <Typography component="p" variant="paperBody" textAlign="justify" mb={2}>
                        <strong>Cookies:</strong>
                        <br />
                        <br />
                        We use cookies to collect certain information automatically when you visit
                        our website, such as your device operating system, access time, location,
                        and other device data. This helps us improve the way our website functions
                        and our service. You can manage cookies through your web browser.
                    </Typography>
                </li>
            </ul>

            <br />

            {/* Information sharing */}
            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                Information sharing
            </Typography>
            <Typography component="p" variant="paperBody" textAlign="justify">
                We may share your personal information with our trusted suppliers. We may also share
                your information with other companies who advertise on our website. We share your
                information with service providers who help us deliver our services to you, and
                sometimes these providers are situated overseas.
            </Typography>

            <br />

            {/* Data retention */}
            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                Data retention
            </Typography>
            <Typography component="p" variant="paperBody" textAlign="justify">
                We keep your personal information for as long as you are our client or subscribed to
                our notifications, and we will not keep it longer than we need to for operational
                purposes or longer than the law requires.
            </Typography>

            <br />

            {/* Your rights */}
            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                Your rights
            </Typography>
            <Typography component="p" variant="paperBody" textAlign="justify">
                You have the right to ask what personal information we have about you, ask us to
                update, correct, or delete any out-of-date or incorrect personal information we have
                about you, unsubscribe from any direct marketing communications we may send you,
                object to the processing of your personal information, and lodge a complaint about
                our practices with the Information Regulator. It can take us up to 21 days to
                respond to your request because there are procedures that we need to follow. In some
                instances, we may need proof of your identity and additional requirements before we
                can update your information.
            </Typography>

            <br />

            {/* Security measures */}
            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                Security measures
            </Typography>
            <Typography component="p" variant="paperBody" textAlign="justify">
                We have reasonable security measures in place to protect your information from being
                disclosed, lost, misused, or accessed without authorization. If you suspect a
                security breach, please notify us immediately by sending an email to
                info@creditsmart.co.za and include as much information as you can.
            </Typography>

            <br />

            {/* Contact us */}
            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                Contact us
            </Typography>
            <Typography component="p" variant="paperBody" textAlign="justify">
                If you have any questions about our privacy policy, please contact us at
                info@creditsmart.co.za.
            </Typography>

            <br />

            {/* Updates to Privacy Policy */}
            <Typography component="h4" variant="paperSubHeading" gutterBottom>
                Updates to Privacy Policy
            </Typography>
            <Typography component="p" variant="paperBody" textAlign="justify">
                We may need to update our privacy policy periodically, but we will let you know when
                we do.
            </Typography>
        </LegalDocWrapper>
    );
};

export default PrivacyPolicy;
