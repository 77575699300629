import { useState } from "react";
import { Box, Card, CardContent, Grid, LinearProgress, Typography } from "@mui/material";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PaymentsIcon from "@mui/icons-material/Payments";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import GavelIcon from "@mui/icons-material/Gavel";
import customTheme from "../../../constants/Theme";
import { currencyFormatter } from "../../../utils/generalHelper";
import ButtonComp from "../../../components/base_components/ButtonComp";
import CreditScoreGauge from "../components/CreditScoreGauge";
import CreditScoreAnalytics from "../components/CreditScoreAnalytics";
import AccountAnalysisCard from "../components/AccountAnalysisCard";

const Overview = ({ creditProfile, viewDetailPage, viewDescriptionsPage }) => {
    const [areaChartHeight, setAreaChartHeight] = useState(100);
    const analysCards = [
        {
            icon: <FingerprintIcon color="secondary" fontSize="large" />,
            description: "Accounts up to date",
            value: creditProfile.number_of_accounts_up_to_date?.value,
            variant: `cardHeading${
                creditProfile.number_of_accounts_up_to_date?.value > 0 ? "Positive" : "Neutral"
            }`,
        },
        {
            icon: <PaymentsIcon color="secondary" fontSize="large" />,
            description: "Total monthly installments",
            value: currencyFormatter.format(creditProfile.total_monthly_installment?.value || 0),
            variant: "cardHeadingNeutral",
        },
        {
            icon: <ArrowDropDownCircleIcon color="secondary" fontSize="large" />,
            description: "Accounts in arrears",
            value: creditProfile.number_of_accounts_in_arrears?.value,
            variant: `cardHeading${
                creditProfile.number_of_accounts_in_arrears?.value > 0 ? "Negative" : "Positive"
            }`,
        },
        {
            icon: <ArrowDropDownCircleIcon color="secondary" fontSize="large" />,
            description: "Total amount in arrears",
            value: currencyFormatter.format(creditProfile.total_arrears?.value || 0),
            variant: `cardHeading${
                creditProfile.total_arrears?.value > 0 ? "Negative" : "Positive"
            }`,
        },
        {
            icon: <CreditCardOffIcon color="secondary" fontSize="large" />,
            description: "Missed payments in last three months",
            value: creditProfile.number_of_missed_payments_in_past_3_months?.value,
            variant: `cardHeading${
                creditProfile.number_of_missed_payments_in_past_3_months?.value > 0
                    ? "Negative"
                    : "Positive"
            }`,
        },
        {
            icon: <GavelIcon color="secondary" fontSize="large" />,
            description: "Judgements against your name",
            value: creditProfile.number_of_judgements?.value,
            variant: "cardHeadingNeutral",
        },
    ];

    return (
        <Box>
            <Typography
                component="h1"
                textAlign={{ xs: "center", md: "left" }}
                sx={{ typography: { xs: "pageTitle2Small", md: "pageTitle2" } }}
            >
                Hi {creditProfile.name} 👋
            </Typography>

            <Grid container spacing={{ xs: 0, sm: 2 }} alignItems="stretch">
                {/* Credit score gauge doughnut chart */}
                <Grid item xs={12} md={5} container justifyContent="center" alignItems="center">
                    <Box
                        sx={{
                            width: "100%",
                            minWidth: { xs: "275px", sm: "300px", md: "unset" },
                            maxWidth: { xs: "275px", sm: "300px", md: "unset" },
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CreditScoreGauge
                            userScore={creditProfile.credit_score}
                            ratingValue={creditProfile.credit_score_star_rating}
                            handleClick={viewDetailPage}
                            handleRatingBadgeClick={viewDescriptionsPage}
                        />
                    </Box>
                    <Grid item xs={12} container justifyContent="center" mt={2}>
                        <ButtonComp color="secondary" onClick={viewDescriptionsPage}>
                            View my scorecard
                        </ButtonComp>
                    </Grid>
                </Grid>

                {/* Credit score analytics area chart */}
                <Grid
                    item
                    xs={12}
                    md={7}
                    container
                    sx={{
                        paddingTop: { xs: 2, sm: 0 },
                        marginBottom: { xs: `${areaChartHeight + 20}px`, sm: 3, md: 0 },
                    }}
                >
                    <Typography
                        component="h2"
                        variant="pageTitle2Small"
                        textAlign="left"
                        marginTop={5}
                        sx={{
                            display: {
                                xs: "none",
                                sm: "unset",
                            },
                        }}
                    >
                        Credit Score Analytics
                    </Typography>
                    <Box
                        sx={{
                            height: { xs: "unset", sm: "100%" },
                            display: "flex",
                            alignItems: "center",
                            position: { xs: "absolute", sm: "relative" },
                            left: 0,
                            width: "100%",
                        }}
                    >
                        <CreditScoreAnalytics
                            history={creditProfile.credit_score_history}
                            setCompHeight={setAreaChartHeight}
                        />
                    </Box>
                </Grid>

                {/* Account analysis cards */}
                <Grid item xs={12} md={7}>
                    <Typography component="h2" variant="pageTitle2Small" marginBottom={2}>
                        Accounts Analysis
                    </Typography>

                    <Grid container alignItems="stretch">
                        {analysCards.map((card, i) => (
                            <Grid
                                key={i}
                                item
                                xs={12}
                                vs={6}
                                lg={4}
                                container
                                justifyContent="center"
                            >
                                <AccountAnalysisCard
                                    icon={card.icon}
                                    description={card.description}
                                    value={card.value}
                                    variant={card.variant}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* Credit utilization */}
                <Grid
                    item
                    xs={12}
                    md={5}
                    marginY={{ xs: 2, md: 0 }}
                    paddingBottom={{ xs: 10, md: 0 }}
                >
                    <Typography component="h2" variant="pageTitle2Small" marginBottom={2}>
                        Credit Utilisation
                    </Typography>

                    <Card
                        sx={{
                            borderRadius: "1rem",
                            backgroundColor: `${customTheme.palette.brandedTeal.main}0d`,
                            padding: "0.5rem 0",
                            width: "100%",
                        }}
                    >
                        <CardContent
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "2rem",
                                }}
                            >
                                {/* Percentage box */}
                                <Box
                                    sx={{
                                        width: "150px",
                                        minWidth: { xs: "80px", md: "100px" },
                                        aspectRatio: 1,
                                        borderRadius: "50%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: `${customTheme.palette.brandedTeal.main}1a`,
                                    }}
                                >
                                    <Typography
                                        component="span"
                                        variant="pageTitleLarge"
                                        textAlign="center"
                                        color={customTheme.palette.brandedBlue.main}
                                    >
                                        {(
                                            Math.min(
                                                creditProfile.debt_to_credit_limit_ratio?.value ||
                                                    0,
                                                1
                                            ) * 100
                                        ).toFixed()}
                                        %
                                    </Typography>
                                </Box>

                                {/* Credit util description */}
                                <Box
                                    sx={{
                                        height: "100%",
                                        paddingLeft: "1rem",
                                    }}
                                >
                                    <Typography component="span" variant="body1Resp">
                                        The percentage of your max credit limit used.
                                    </Typography>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    width: "100%",
                                    disply: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    component="h2"
                                    variant="pageTitle2SmallResp"
                                    gutterBottom
                                >
                                    Total Outstanding Debt
                                </Typography>

                                <LinearProgress
                                    variant="determinate"
                                    color="brandedBlue"
                                    value={
                                        Math.min(
                                            creditProfile.debt_to_credit_limit_ratio?.value || 0,
                                            1
                                        ) * 100
                                    }
                                    sx={{ height: "0.5625rem", mb: 2 }}
                                />

                                {/* Debt and limit amounts */}
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "start",
                                        }}
                                    >
                                        <Typography component="p" variant="body1Resp">
                                            Total owed
                                        </Typography>
                                        <Typography component="p" variant="pageBodyBoldResp">
                                            {currencyFormatter.format(
                                                creditProfile.total_outstanding_debt?.value || 0
                                            )}
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "end",
                                        }}
                                    >
                                        <Typography
                                            component="p"
                                            variant="body1Resp"
                                            textAlign="end"
                                        >
                                            Total available
                                        </Typography>
                                        <Typography
                                            component="p"
                                            variant="pageBodyBoldResp"
                                            textAlign="end"
                                        >
                                            {currencyFormatter.format(
                                                creditProfile.total_credit_limit?.value || 0
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Overview;
