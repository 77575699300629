import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import Footer from "../../components/footer/Footer";
import CustomAppBar from "../../components/custom_app_bar/CustomAppBar";
import HeroSection from "./components/HeroSection";
import AboutSection from "./components/AboutSection";
import CallToActionSection from "./components/CallToActionSection";
import FeatureCards from "./components/FeatureCards";

function LandingPage() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const goToSignup = () => {
        navigate("sign-up");
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                overflowX: "clip",
            }}
        >
            <CustomAppBar />
            <FeatureCards isMobile={isMobile} />
            <HeroSection signup={goToSignup} theme={theme} isMobile={isMobile} />
            <AboutSection theme={theme} isMobile={isMobile} />
            <CallToActionSection signup={goToSignup} theme={theme} isMobile={isMobile} />
            <Footer gradientEffect isMobile={isMobile} />
        </Box>
    );
}

export default LandingPage;
