import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import MonthSummaryAccordion from "../components/MonthSummaryAccordion";
import CreditScoreComparison from "../components/CreditScoreComparison";
import CreditScoreAnalytics from "../components/CreditScoreAnalytics";

const OverviewDetailed = ({ creditProfile, handleBack }) => {
    const [areaChartHeight, setAreaChartHeight] = useState(100);

    return (
        <Box>
            {/* Back button */}
            <Box
                onClick={handleBack}
                sx={{
                    cursor: "pointer",
                    display: "flex",
                    width: "fit-content",
                    marginBottom: "2rem",
                }}
            >
                <ExpandCircleDownIcon sx={{ transform: "rotate(90deg)", marginRight: "10px" }} />
                <Typography variant="body1" component="span">
                    back
                </Typography>
            </Box>

            <Typography component="h1" variant="pageTitle1" mt={2} mb={3} textAlign="center">
                Credit Profile
            </Typography>

            <Grid container spacing={{ xs: 0, sm: 2 }} alignItems="stretch">
                {/* Credit score comparison doughnut chart */}
                <Grid item xs={12} md={7} lg={6} container justifyContent="center">
                    <Grid item xs={12}>
                        <Typography component="h2" variant="pageTitle2Small" marginBottom={2}>
                            Credit Comparison
                        </Typography>
                    </Grid>

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", md: "80%" },
                                minWidth: { xs: "250px", sm: "unset", md: "400px" },
                                maxWidth: { xs: "250px", sm: "unset" },
                            }}
                        >
                            <CreditScoreComparison
                                userScore={creditProfile.credit_score}
                                avgScore={creditProfile.age_group_average_score?.average_score}
                            />
                        </Box>
                    </Box>
                </Grid>

                {/* Credit score analytics area chart */}
                <Grid
                    item
                    xs={12}
                    md={5}
                    lg={6}
                    sx={{
                        marginBottom: { xs: `${areaChartHeight + 20}px`, sm: 0 },
                        marginTop: { xs: "2rem", sm: 0 },
                    }}
                >
                    <Typography component="h2" variant="pageTitle2Small" textAlign="left">
                        Credit Score Analytics
                    </Typography>
                    <Box
                        sx={{
                            height: { xs: "unset", sm: "100%" },
                            display: "flex",
                            alignItems: "center",
                            position: { xs: "absolute", sm: "relative" },
                            left: 0,
                            width: "100%",
                        }}
                    >
                        <CreditScoreAnalytics
                            history={creditProfile.credit_score_history}
                            setCompHeight={setAreaChartHeight}
                        />
                    </Box>
                </Grid>
            </Grid>

            {/* Monthly score effects */}
            <MonthSummaryAccordion
                scoreEffects={creditProfile.what_affected_your_score}
                sx={{
                    marginBottom: { xs: "100px", md: 0 },
                    marginTop: { xs: 4, md: 0 },
                    paddingBottom: "1rem",
                }}
            />
        </Box>
    );
};

export default OverviewDetailed;
