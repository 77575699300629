import BaseController from "./BaseController";

class Controller extends BaseController {
    constructor() {
        super();
        this.ProfileURL = "/credit-profile";
        this.OtherAccountsURL = "/credit-profile/accounts";
        this.OtherListingsURL = "/credit-profile/other-listings";
    }

    getProfile = () => {
        return this.sendRequest("GET", this.ProfileURL);
    };

    getAccounts = () => {
        return this.sendRequest("GET", this.OtherAccountsURL);
    };

    getOtherListings = () => {
        return this.sendRequest("GET", this.OtherListingsURL);
    };
}

const CreditProfileController = new Controller();

export default CreditProfileController;
