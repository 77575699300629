import { Card, CardContent, Typography, Box } from "@mui/material";

const FeatureCard = ({ icon, title, content, backgroundColor, ...props }) => {
    return (
        <Card
            {...props}
            sx={{
                borderRadius: "2rem",
                padding: "0.5rem 0",
                minWidth: { xs: "275px" },
                maxWidth: { xs: "350px", md: "290px", lg: "380px" },
                height: { xs: "275px", md: "unset", lg: "350px" },
                minHeight: { xs: "290px", sm: "380px", md: "unset" },
                backgroundColor: "#011528BF",
                backdropFilter: "blur(5px)",
                display: "flex",
                flex: 1,
                ...props?.sx,
            }}
        >
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        alignItems: "center",
                    }}
                >
                    <img src={icon} alt="laptop" height="34px" width="34px" />
                    <Typography
                        component="h3"
                        variant="pageTitle2Resp"
                        textAlign={{ xs: "center", md: "left" }}
                        ml={{ xs: 0, md: 2 }}
                        mt={{ xs: 2, md: 0 }}
                    >
                        {title}
                    </Typography>
                </Box>
                <Typography component="p" variant="body1Resp" mt={2}>
                    {content}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default FeatureCard;
