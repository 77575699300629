import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import Chart from "chart.js/auto";
import customTheme from "../../../constants/Theme";
import { degreesToRadians } from "../../../utils/generalHelper";
import { getDoughnutDefaults } from "../../../utils/chartjsHelper";
import DoughnutChart from "../../../components/charts/DoughnutChart";

const { startAngle, circumference } = getDoughnutDefaults();
const chartMaxValue = 1000;
const chartTextColor = customTheme.palette.text.primary;
const chartRemainderColor = customTheme.palette.chartColorEmpty.main;
const chartAvgScoreColor = customTheme.palette.chartColorNeutral.light;
const chartUserScoreColor = customTheme.palette.chartColorGood.main;

const CreditScoreComparison = ({ userScore, avgScore }) => {
    const [chartDetails, setChartDetails] = useState({});
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const theme = useTheme();
    const mobileThreshold = theme.breakpoints.values.sm;

    useEffect(() => {
        const resizeHandler = () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener("resize", resizeHandler);

        return () => {
            window.removeEventListener("resize", resizeHandler);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!userScore && userScore !== 0) return;

        const isMobile = getIsMobile();

        // Calculate the chart segment details based on user's score
        const dataSets = [
            {
                backgroundColor: [chartUserScoreColor, chartRemainderColor],
                data: [userScore, chartMaxValue - userScore],
            },
            {
                backgroundColor: [chartAvgScoreColor, chartRemainderColor],
                data: [avgScore, chartMaxValue - avgScore],
            },
        ];

        const beforeRenderPlugin = (chart) => {
            const datasets = chart.data.datasets;
            const datasetArcs = chart._metasets[0].data;
            const { x: xc, y: yc } = datasetArcs[0];

            datasets.forEach((dataset, d) => {
                const startingAngle = 360 - startAngle;
                const segmentAnglePerc = dataset.data[0] / chartMaxValue;
                const color = dataSets[d].backgroundColor[0];

                if (typeof color !== "string") return;

                const gradient = chart.ctx.createConicGradient(
                    degreesToRadians(startingAngle),
                    xc,
                    yc
                );

                gradient.addColorStop(0, color + "00");
                gradient.addColorStop((segmentAnglePerc * circumference) / 360, color);
                gradient.addColorStop(1, "transparent");

                dataset.backgroundColor[0] = gradient;
            });
        };

        // Implement the logic for rendering the chart text
        const afterDrawPlugin = (chart) => {
            const { ctx } = chart;
            const { x: xc, y: yc } = chart._metasets[0].data[0];

            ctx.save();

            // Draw the text for the center circle
            ctx.fillStyle = customTheme.palette.secondary.main;
            ctx.font = `${customTheme.typography.body1.fontWeight} ${customTheme.typography.body1.fontSize} ${customTheme.typography.fontFamily}`;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText("Your Score", xc, yc - 35);

            ctx.fillStyle = chartUserScoreColor;
            ctx.font = `700 ${isMobile ? "2rem" : "2.5rem"} ${customTheme.typography.fontFamily}`;
            ctx.fillText(userScore, xc, yc);

            ctx.fillStyle = chartTextColor;
            ctx.font = `${customTheme.typography.body1.fontWeight} ${customTheme.typography.body1.fontSize} ${customTheme.typography.fontFamily}`;
            ctx.fillText(`of a ${avgScore} average`, xc, yc + 35);
        };

        setChartDetails({
            labels: ["Your score", "Remainder", "Average for your age group", "Remainder"],
            data: dataSets,
            plugins: [
                {
                    beforeRender: beforeRenderPlugin,
                    afterDraw: afterDrawPlugin,
                },
            ],
            legend: {
                display: true,
                position: isMobile ? "bottom" : "right",
                labels: {
                    usePointStyle: true,
                    padding: 20,
                    // Customizing legend labels
                    generateLabels: function (chart) {
                        const labels =
                            Chart.overrides.doughnut.plugins.legend.labels.generateLabels(chart);

                        let newLabels = [];
                        labels.forEach((label) => {
                            // Filter out the remainder legend labels
                            const labelText = label.text.toLowerCase().trim();

                            if (labelText === "remainder") return;
                            if (labelText.toLowerCase().trim() === "your score") {
                                label.fillStyle = chartUserScoreColor;
                            } else if (labelText === "average for your age group") {
                                label.fillStyle = chartAvgScoreColor;
                            }

                            newLabels.push(label);
                        });

                        return newLabels;
                    },
                },
                title: {
                    display: true,
                    text: "How you compare in your age group",
                    padding: isMobile
                        ? {
                              top: 25,
                          }
                        : 0,
                },
            },
            optionsOverride: {
                aspectRatio: isMobile ? 0.75 : 1.5,
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowSize]);

    const getIsMobile = () => {
        return window.innerWidth <= mobileThreshold;
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                minHeight: "250px",
                maxHeight: { xs: "350px", md: "400px" },
            }}
        >
            {!chartDetails.data ? (
                <>
                    <Typography component="h2" variant="pageTitle2">
                        Drawing Chart Details
                    </Typography>
                    <CircularProgress size="large" />
                </>
            ) : (
                <DoughnutChart
                    labels={chartDetails.labels}
                    datasets={chartDetails.data}
                    plugins={chartDetails.plugins}
                    legend={chartDetails.legend}
                    optionsOverride={chartDetails.optionsOverride}
                    ignoreRefresh
                />
            )}
        </Box>
    );
};

export default CreditScoreComparison;

/*
When refactoring, incorporate the encapsulation of the text drawing method like so

var canvas = document.getElementById('myCanvas');
var ctx = canvas.getContext('2d');
var score = 750;
var averageScore = 500;

function drawTextWithStyle(text, x, y, fontStyle) {
    ctx.font = fontStyle;
    ctx.fillText(text, x, y);
}

// Set the base font style
ctx.font = '20px Arial';

// Draw the first part of the text
drawTextWithStyle('Your score is ', 10, 30, '20px Arial');

// Draw the score in bold
drawTextWithStyle(score, 10 + ctx.measureText('Your score is ').width, 30, 'bold 20px Arial');

// Draw the middle part of the text
drawTextWithStyle(' out of an average of ', 10 + ctx.measureText('Your score is ' + score).width, 30, '20px Arial');

// Draw the average score in bold
drawTextWithStyle(averageScore, 10 + ctx.measureText('Your score is ' + score + ' out of an average of ').width, 30, 'bold 20px Arial');

*/
