import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import customTheme from "../../../../constants/Theme";
import ModuleButton from "./ModuleButton";

const Topic = ({
    topic,
    isLoading,
    selectedModuleId,
    selectModule,
    isModuleDisabled,
    ...props
}) => {
    return (
        <>
            {/* Loading indicator */}
            {isLoading && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80%",
                    }}
                >
                    <Typography
                        component="h1"
                        variant="pageTitle1Resp"
                        textAlign="center"
                        gutterBottom
                    >
                        Loading The Topic Details
                    </Typography>
                    <CircularProgress size="5rem" />
                </Box>
            )}

            {/* No content message */}
            {!isLoading && !topic && (
                <Typography
                    component="p"
                    variant="body1BoldResp"
                    color={customTheme.palette.warning.main}
                    textAlign="center"
                    marginY={4}
                >
                    The coaching topic and related modules were not received from the server. Please
                    contact support if this issue persists.
                </Typography>
            )}

            {/* Topic content */}
            {!isLoading && Object.keys(topic ?? {}).length > 0 && (
                <>
                    {/* Title */}
                    <Typography component="h1" variant="pageTitle1Resp">
                        {topic.title}
                    </Typography>

                    {/* Duration */}
                    <Box sx={{ display: "flex", alignItems: "center", marginY: "2rem" }}>
                        <AccessTimeIcon
                            sx={{ fontSize: { xs: "1.2rem", sm: "1.75rem" }, opacity: 0.5 }}
                        />
                        <Typography component="p" variant="body1Resp" ml={1}>
                            {topic.duration} min
                        </Typography>
                    </Box>

                    {/* Overview */}
                    <Typography component="p" variant="body1BoldResp">
                        Overview
                    </Typography>
                    <Typography component="p" variant="body1Resp" mt={1}>
                        {topic.overview}
                    </Typography>

                    {/* Modules */}
                    <Grid container spacing={2} marginY={4}>
                        {topic.modules?.map((module, i) => (
                            <Grid key={i} item xs={12}>
                                <ModuleButton
                                    title={module?.title}
                                    duration={module?.duration}
                                    status={module?.status}
                                    isSelected={module?.module_id === selectedModuleId}
                                    disabled={isModuleDisabled(i)}
                                    onClick={() => {
                                        selectModule(module?.module_id);
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </>
    );
};

export default Topic;
