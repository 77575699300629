import { useNavigate } from "react-router-dom";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import BackgroundImgFull from "../../assets/images/background_1_full.jpg";

const LoginSignupContainer = ({
    pageTitle,
    pageHeading,
    progressBarPerc,
    overwriteHandleBack,
    ...props
}) => {
    const navigate = useNavigate();

    const handleBack = () => {
        if (overwriteHandleBack) {
            overwriteHandleBack();
            return;
        }

        navigate(-1);
    };

    return (
        <Grid container direction="row-reverse">
            {/* Background image */}
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        height: "100vh",
                        opacity: 0.2,
                        background: `url(${BackgroundImgFull})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                />
            </Grid>

            {/* Page components */}
            <Grid
                item
                xs={12}
                md={6}
                container
                alignContent="flex-start"
                sx={{
                    paddingX: { xs: "1rem", md: "4rem" },
                    top: 0,
                    position: { xs: "absolute", md: "relative" },
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    height="100vh"
                    alignContent="flex-start"
                >
                    {/* Back button */}
                    <Box
                        onClick={handleBack}
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            width: "fit-content",
                            marginY: 5,
                        }}
                    >
                        <ExpandCircleDownIcon
                            sx={{ transform: "rotate(90deg)", marginRight: "10px" }}
                        />
                        <Typography variant="body1" component="span">
                            back
                        </Typography>
                    </Box>

                    {/* Progress bar */}
                    {progressBarPerc >= 0 && (
                        <LinearProgress
                            variant="determinate"
                            value={progressBarPerc}
                            sx={{ minHeight: "4px" }}
                        />
                    )}

                    {/* Page title */}
                    {pageTitle && (
                        <Typography component="h1" variant="pageTitle1" marginY={4}>
                            {pageTitle}
                        </Typography>
                    )}

                    {/* Page heading */}
                    {pageHeading && (
                        <Typography component="h2" variant="pageTitle2" marginY={4}>
                            {pageHeading}
                        </Typography>
                    )}

                    {/* Page contents */}
                    <Box flex={1}>{props.children}</Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default LoginSignupContainer;
