import React, { createContext, useContext, useState } from "react";
import { useAuthContext } from "./AuthContext";

const allNudges = [
    {
        id: "nudge-overview-1",
        text: "Tap here to see more details about your credit profile.",
        callBackText: "Got it",
        isSeen: false,
    },
];

const NudgeContext = createContext();

export function useNudgeContext() {
    const contextValue = useContext(NudgeContext);

    if (!contextValue) {
        throw new Error("useNudgeContext must be called inside NudgeContextProvider");
    }

    return contextValue;
}

export default function NudgeContextProvider(props) {
    const { isFirstLogin } = useAuthContext();
    const [sessionNudges, setSessionNudges] = useState(isFirstLogin ? [...allNudges] : []);
    const [pageNudges, setPageNudges] = useState(null);
    const [showingNudgeId, setShowingNudgeId] = useState(null);

    const getNudgeDetails = (id) => {
        if (!id) return null;

        const pageNudges = getAllPageNudges(id);
        const nudgeIndex = pageNudges.findIndex((n) => n.id === id);
        const nudge = pageNudges[nudgeIndex];
        const domElement = document.getElementById(id);

        if (!nudge || !domElement) return null;

        if (nudgeIndex === 0) setShowingNudgeId(id);

        return {
            domElement: domElement,
            text: nudge.text,
            callbackText: nudge.callBackText,
            callback: () => {
                hasSeenNudge(id);
                showNextNudge(nudgeIndex + 1);
            },
        };
    };

    const getAllPageNudges = (id) => {
        const nudgeName = id.replace(/-\d+$/, "");

        // Check if there are any nudges for this page that has not been seen
        const nudges =
            pageNudges && pageNudges.some((n) => n.id === id)
                ? pageNudges
                : sessionNudges
                      .filter((n) => !n.isSeen && n.id.includes(nudgeName))
                      .sort((a, b) => (a.id > b.id ? 1 : -1));

        setPageNudges(nudges);

        return nudges;
    };

    const hasSeenNudge = (id) => {
        let tmpSessionNudges = [...sessionNudges];
        let nudge = tmpSessionNudges.find((n) => n.id === id);

        nudge.isSeen = true;

        setSessionNudges(tmpSessionNudges);
    };

    const showNextNudge = (nudgeIndex) => {
        if (nudgeIndex < 0 || !pageNudges || nudgeIndex >= pageNudges?.length) {
            setShowingNudgeId(null);
            setPageNudges(null);
        } else {
            const nextNudge = pageNudges[nudgeIndex];
            setShowingNudgeId(nextNudge.id);
        }
    };

    return <NudgeContext.Provider {...props} value={{ getNudgeDetails, showingNudgeId }} />;
}
